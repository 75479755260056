<template>
  <div v-if="isLogin">
    <b-navbar class="is-fixed-top">
      <template slot="brand">
        <b-navbar-item class="no-pointer">
          <img src="../assets/img/io_logo.png" :alt="$websiteDescription" />
        </b-navbar-item>
      </template>
      
      <!-- <template slot="test">
        <div style="font-size: 4rem;">
          <b-icon icon="clock" class="border rounded p-2"></b-icon>
          <b-icon icon="bell-fill" class="border border-info rounded p-2" variant="info"></b-icon>
          <b-icon icon="bell-fill" class="rounded-circle bg-danger p-2" variant="light"></b-icon>
          <b-icon icon="unlock-fill" class="rounded bg-primary p-1" variant="light"></b-icon>
        </div>
      </template> -->
      <!-- <template slot="end">
        <div class="top-right-container">
          <div v-if="email !== undefined && email.length > 0">
            <div>
                <b-icon icon="person"></b-icon>
            </div>
            <div>{{email}}</div>
            <div class="icon-hover">
                <b-icon icon="bell"></b-icon>
            </div>
            <div class="icon-hover" @click="_logout()">
              <b-icon icon="logout" ></b-icon>
            </div>
          </div>
          <div v-else @click="_login()"> Login </div>
        </div>
      </template> -->


      <!-- <template slot="end">
        <b-navbar-dropdown :label="email">
          <b-navbar-item
            v-if="email !== undefined && email.length > 0"
            @click="_logout()"
            ><template
              ><b-icon icon="logout"></b-icon><span>Logout</span></template
            ></b-navbar-item
          >
          <b-navbar-item v-else @click="_login()"> Login </b-navbar-item>
        </b-navbar-dropdown>
      </template> -->

      <template slot="end">
          <div class="top-right-container">
            <div v-if="email !== undefined && email.length > 0" class="top-item">
              <div class="top-item">
                  <b-icon icon="person"></b-icon>
              </div>
              <div  class="top-item">{{email}}</div>
              <!-- <div class="bell-notification">
                <span class="bell-notification-child">
                  <Notification></Notification>
                  </span>
              </div> -->
              <div class="top-item icon-hover" @click="_logout()">
                <b-icon icon="logout" ></b-icon>
              </div>
            </div>
            <div v-else @click="_login()"> Login </div>
          </div>
      </template> 

    </b-navbar>
    <b-loading
      :is-full-page="true"
      :active.sync="isLoading"
      :can-cancel="true"
    ></b-loading>
    <div class="_wrapper">
      <div v-if="isLoading == false">
        <div>
          <div
            slot="trigger"
            class="card-header"
            role="button"
            aria-controls="__content"
          >
            <p class="card-header-title" style="margin-left: -15px">
              Choose Customer
            </p>
          </div>
          <div class="card">
            <div class="card-content">
              <div class="content canClickRow">
                <nav class="level">
                  <!-- Left side -->
                  <div class="level-left"></div>

                  <!-- Right side -->
                  <div class="level-right">
                    <b-field>
                      <b-input
                        placeholder="Search..."
                        type="search"
                        icon="magnify"
                        v-model="query"
                      >
                      </b-input>
                    </b-field>
                  </div>
                </nav>

                <b-table
                  id="table-choose-customer"
                  :striped="true"
                  :data="results == undefined ? [] : results"
                  :paginated="paginated"
                  backend-pagination
                  :total="total"
                  :per-page="perPage"
                  :current-page.sync="currentPage"
                  @page-change="onPageChange"
                  aria-next-label="Next page"
                  aria-previous-label="Previous page"
                  aria-page-label="Page"
                  aria-current-label="Current page"
                  aria-controls="__content"
                  pagination-position="both"
                >
                  <template>
                    <b-table-column
                      field="ProfileName"
                      label="Profile Name"
                      v-slot="props"
                      ><div class="has-text-left">
                        {{ props.row.ProfileName }}
                      </div></b-table-column
                    >

                    <b-table-column centered v-slot="props">
                      <b-button
                        type="is-primary"
                        @click.prevent="_gotoSummary(props, '_blank')"
                        custom-class="viewReport"
                      >
                        <template>
                          <span>View Report</span>
                        </template>
                      </b-button>
                    </b-table-column>

                    <b-table-column centered v-slot="props">
                      <b-button
                        type="is-primary"
                        @click.prevent="_gotoCleansingIndex(props, '_blank')"
                        custom-class="reviewData"
                      >
                        <template>
                          <span>Review Data</span>
                        </template>
                      </b-button>
                    </b-table-column>
                  </template>
                  <template slot="empty" v-if="isLoading == false">
                    <section class="section">
                      <div class="content has-text-grey">
                        <p class="has-text-centered">
                          <b-icon icon="emoticon-sad" size="is-large"></b-icon>
                        </p>
                        <p class="has-text-centered">No record found here.</p>
                      </div>
                    </section>
                  </template>
                </b-table>
                <div align="right" class="m-t-10">
                  <b-button
                    type="is-text"
                    @click="toTop()"
                    icon-left="arrow-up-bold-circle-outline"
                    >Go to top</b-button
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Preparing
        :waitingText="this.waitingText"
        v-if="this.isLoading == true"
      ></Preparing>
      <Footer
        v-if="isLoading == false"
        :eulaurl="eulaurl"
        :contactusurl="contactusurl"
        :companyurl="companyurl"
      ></Footer>
    </div>
  </div>
</template>

<script>
import Footer from "../layout/Footer.vue";
import Preparing from "../contents/preparing";
import aes from "crypto-js/aes";
import encHex from "crypto-js/enc-hex";
import padZeroPadding from "crypto-js/pad-zeropadding";

export default {
  name: "customersPage",
  components: {
    Preparing,
    Footer,
  },
  props: {},
  data() {
    return {
      eulaurl: "",
      contactusurl: "",
      companyurl: "",
      query: null,
      data: [],
      selected: {},
      isLoading: true,
      total: 0,
      isLogin: false,
      email: "",
      page: 1,
      currentPage: 1,
      perPage: 5000,
      hexkey: "",
      hexiv: "",
      paginated: false,
      waitingText: "Preparing data, please wait...",
      export_history: [],
    };
  },

  computed: {
    results() {
      if (this.query) {
        return this.data.filter((item) => {
          return this.query
            .toLowerCase()
            .split(" ")
            .every((v) => item.ProfileName.toLowerCase().includes(v));
        });
      } else {
        return this.data;
      }
    },
  },

  methods: {
    async _isLogin() {
      this.isLogin = false;
      var token = this.readLocalStorage("iqgoauth2", "");

      if (token !== null) {
        var result = await this.validateTokenGoAuth(token);

        if (result["continue"]) {
          this.email = result["email"];
          this.writeLog("customer", [
            { key: "email", value: this.email },
            { key: "originalURL", value: location.href },
            { key: "remark", value: "Login passed, load the customer page." },
          ]);
        } else {
          this.email = "";
        }
      } else {
        this.email = "";
      }

      if (this.email !== undefined) {
        if (this.email.length == 0) {
          this.gotoGOAuth();
        } else {
          this.isLogin = true;
          this.isLoading = true;
          await this.getServerConfig().then((config) => {
            this.$store.commit("setServerConfig", config);
            this.hexkey = config.$HexKey;
            this.hexiv = config.$HexIV;
            this.eulaurl = config.EULAURL;
            this.companyurl = config.CompanyURL;
            this.contactusurl = config.ContactUsURL;
          });
          await this.loadAsyncData();
          this.gaPage();
        }
      } else {
        this.gotoGOAuth();
        this.isLogin = false;
      }
    },
    _gotoSummary(props, target) {
      this.gotoSummary(props, target);
    },
    _login() {
      this.gotoVerifyEmail();
    },
    _logout() {
      const params = this.getURLParams();
      this.deleteLocalStorage("iquuid2", params.dbName);
      this.deleteLocalStorage("iqgoauth2");
      this.$store.commit("setPageBack", params);
      this.writeLog("verifyemail", [
        {
          key: "originalURL",
          value: location.href,
        },
        { key: "remark", value: "logout" },
        { key: "email", value: this.email },
      ]);
      this.email = "";
      this.gotoGOAuth();
    },
    _gotoCleansingIndex(item, target) {
      this.gotoCleansingIndex(item, target);
    },

    /*
     * Load async data
     */
    async loadAsyncData() {
      let key = encHex.parse(this.hexkey);
      let iv = encHex.parse(this.hexiv);

      var encrypted = aes
        .encrypt(this.$prefixAuthKey + "-" + Date.now(), key, {
          iv: iv,
          padding: padZeroPadding,
        })
        .toString();
      var params = { AuthKey: encrypted };      
      await this.httpRequest("Dashboard/getDatabaseList", params)
        .then((res) => {
         
          res = this.$result.data;
          this.data = [];
          let currentTotal = res.databases.length;
          if (res.total_results / this.perPage > 1000) {
            currentTotal = this.perPage * 1000;
          }
          this.total = currentTotal;

          this.paginated = true;

          let db = [];
          res.databases.map((v) => {
            db.push(v);
          });

          this.data = db;

          this.isLoading = false;
        })
        .catch((error) => {
          this.data = [];
          this.total = 0;
          this.isLoading = false;
          throw error;
        });
    },


    onSelectRow(a) {
      var e = {};
      e.row = a;
      this._gotoSummary(e, "_blank");
    },
    /*
     * Handle page-change event
     */
    onPageChange(page) {
      this.page = page;
      this.currentPage = page;
      this.loadAsyncData();
    },
    /*
     * Handle sort event
     */
    onSort(field, order) {
      this.sortField = field;
      this.sortOrder = order;
      this.loadAsyncData();
    },
    /*
     * Type style in relation to the value
     */
    type(value) {
      const number = parseFloat(value);
      if (number < 6) {
        return "is-danger";
      } else if (number >= 6 && number < 8) {
        return "is-warning";
      } else if (number >= 8) {
        return "is-success";
      }
    },
  },

  async created() {
    await this._isLogin();
  },
};
</script>
