<template>
  <div style="margin-top: -10px">
    <div class="card">
      <div slot="trigger" class="card-header" role="button">
        <p class="card-header-title">
          <span class="waiting-text">{{ this.waitingText }}</span>
        </p>
      </div>
      <div class="card-content">
        <div class="content"></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "PreparingContent",
  components: {},
  props: {
    waitingText: {
      type: String,
      default: "Preparing data, please wait...",
    },
  },
  methods: {},
};
</script>
<style scoped>
.card-header-title {
  font-size: 1.5em !important;
}

.card-content {
  min-height: 75vh;
}
</style>
