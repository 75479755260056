let config = {
  $websiteName: "InfoQuest 360",
  $websiteDescription: "A Dashboard website for InfoQuest 360",
  $dev_base_url: window.location.origin, //use in development - ignore by engineer
  $demo_base_url: window.location.origin, //use in development - ignore by engineer

  // //$dev_api_url: "iqio-webapi/index.php",
  // $dev_api_url: "IQIO_WebAPI/branches/20220531_v.2.2.31.23/IQIO/index.php", //use in development - ignore by engineer
  // //$api_url: "public/index.php",
  // $api_url: "IQIO/index.php",


    $dev_api_url: "iqio-webapi/index.php",
    $api_url: "IQIO/index.php",



  $is_production: true, //true = use production env; false = use dev env
  $is_demo: 0,
  //0 = R1 - without choose customer,
  //1 = Demo - with choose customer, summary, report and cleansing,
  //2 = C1 - with choose customer

  $colorScheme: ["#FFE049", "#D2AE00", "#FF9B49", "#D25F00"],
  $colorScheme1: ["#051F5A", "#8AA4E2"],
  $speedVerifyCleansing: 500,

  $latest_update: "2023-02-08",
  $version: "2.ุ8.1"
};

export default {
  config
};