<template>
  <div>
    <div class="card">
      <div slot="trigger" class="card-header" role="button">
        <div class="card-header-title">
          <div class="text-center w-100">
            {{ title }}
          </div>
        </div>
      </div>
      <div class="card-content">
        <div class="content">
          <Barchart
            :chartData="this.oChartData"
            :options="this.oOptions"
          ></Barchart>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Barchart from "../../controls/ChartJS/Barchart";

export default {
  components: {
    Barchart,
  },
  data() {
    return {
      oChartData: {},
      oOptions: {},
      title: "",
    };
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    chartdata: {
      type: Object,
      default: null,
    },
    options: {
      type: Object,
      default: null,
    },
  },

  watch: {
    chartdata() {
      this.loadAsyncData();
    },
  },
  mounted() {
    this.loadAsyncData();
  },

  methods: {
    loadAsyncData() {
      if (this.chartdata !== null && this.chartdata !== undefined) {
        this.oChartData = {};
        this.oOptions = {};
        this.oChartData.labels = this.chartdata.labels;
        this.title = this.chartdata.title;

        var barPercentage = 0.8;
        if (this.oChartData.labels) {
          if (this.oChartData.labels.length < 3) {
            barPercentage = 0.5;
          } else {
            barPercentage = 0.8;
          }
        } else {
          barPercentage = 0.8;
        }
        var maxBarThickness = 80;
        this.oChartData.datasets = [
          {
            label: "Data One",
            backgroundColor: this.$colorScheme1[0],
            barPercentage: barPercentage,
            maxBarThickness: maxBarThickness,
            data: this.chartdata.datasets,
          },
        ];
        this.oOptions = this.options;
      }
    },
  },
};
</script>