<template>
  <div>
    <div class="card">
      <div slot="trigger" class="card-header" role="button">
        <div class="card-header-title">
          <div class="text-center w-100">
            {{ title }}
          </div>
        </div>
      </div>
      <div class="card-content">
        <div class="content">
          <Linechart
            :chartData="this.oChartData"
            :options="this.oOptions"
            :borderWidth="this.borderWidth"
          ></Linechart>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Linechart from "../../controls/ChartJS/Linechart";
export default {
  components: {
    Linechart,
  },
  data() {
    return {
      oChartData: {},
      oOptions: {},
      title: "",
    };
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    borderWidth: {},
    chartdata: {
      type: Object,
      default: null,
    },
    options: {
      type: Object,
      default: null,
    },
    start: String,
    end: String,
  },

  watch: {
    chartdata() {
      this.loadAsyncData();
    },
  },
  mounted() {
    this.loadAsyncData();
  },

  methods: {
    loadAsyncData() {
      if (this.chartdata !== null && this.chartdata !== undefined) {
        this.oChartData = {};
        this.oOptions = {};
        this.oChartData.labels = this.chartdata.labels;
        this.title =
          this.chartdata.title +
          " (" +
          this.getStartEnd(this.start, this.end) +
          ")";
        this.oChartData.datasets = [
          {
            label: "Mentions",
            borderColor: this.$colorScheme1[0],
            backgroundColor: this.$colorScheme1[0],
            data: this.chartdata.datasets.count,
            fill: false,
            borderWidth: this.borderWidth,
            yAxisID: "y-axis-1",
          },
          {
            label: "PR Value (Baht)",
            borderColor: this.$colorScheme1[1],
            backgroundColor: this.$colorScheme1[1],
            data: this.chartdata.datasets.pr,
            fill: false,
            borderWidth: this.borderWidth,
            yAxisID: "y-axis-2",
          },
        ];

        this.oOptions = this.options;
      }
    },
  },
};
</script>
