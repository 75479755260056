<template>
  <div>
    <b-select
      :icon="this.icon"
      :icon-pack="this.iconPack"
      placeholder="Select at least one item"
      expanded
      required
      v-model="selectedOption"
    >
      >
      <option
        v-for="item in items"
        :value="item[valueName]"
        :key="item[valueName]"
      >
        <span>{{ item[labelName] }}</span>
      </option>
    </b-select>
  </div>
</template>
<script>
export default {
  name: "SelectBox",
  props: {
    items: {},
    icon: String,
    iconPack: String,
    labelName: String,
    valueName: String,
    selected: String,
  },
  data() {
    return {
      selectedOption: [],
    };
  },
  computed: {},
  mounted: function () {
    this.selectedOption = this.selected;
  },
  watch: {
    selected() {
      this.selectedOption = this.selected;
    },
    selectedOption() {
      this.$emit("input", this.selectedOption);
    },
  },
  methods: {},
};
</script>
<style>
select {
  font-family: "Bai Jamjuree" !important;
}
select option {
  padding: 20px;
}
</style>