<template>
  <div>
    <b-navbar class="is-fixed-top">
      <template slot="brand">
        <b-navbar-item class="no-pointer">
          <img src="../assets/img/io_logo.png" :alt="$websiteDescription" />
        </b-navbar-item>
      </template>
      <template slot="start"> </template>

      <template slot="end"> </template>
    </b-navbar>
    <PreparingUnsuccessContent></PreparingUnsuccessContent>
    <Footer
      v-if="isLoading == false"
      :eulaurl="eulaurl"
      :contactusurl="contactusurl"
      :companyurl="companyurl"
    ></Footer>
  </div>
</template>

<script>
import Footer from "../layout/Footer.vue";
import PreparingUnsuccessContent from "../contents/preparingUnsuccess";

export default {
  name: "preparingUnSuccessPage",
  components: {
    Footer,
    PreparingUnsuccessContent,
  },
  props: {},
  data() {
    return {
      wt: "",
      eulaurl: "",
      contactusurl: "",
      companyurl: "",
    };
  },
  methods: {},

  async created() {
    await this.getServerConfig().then((config) => {
      this.eulaurl = config.EULAURL;
      this.companyurl = config.CompanyURL;
      this.contactusurl = config.ContactUsURL;
    });
  },
};
</script>
