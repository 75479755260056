<template>
  <div>
    <b-navbar class="is-fixed-top">
      <template slot="brand">
        <b-navbar-item tag="router-link" :to="{ path: '' }">
          <a class="navbar-item" @click.prevent="_gotoSummaryPage()">
            <img src="../assets/img/io_logo.png" :alt="$websiteDescription" />
          </a>
        </b-navbar-item>
      </template>
      <!-- <template slot="end">
        <div v-if="email !== undefined && email.length > 0" @click="_logout()">
          <template><b-icon icon="logout"></b-icon><span>Logout</span></template>
        </div>
        <div v-else @click="_login()"> Login </div>
      </template> -->
      <!-- <template slot="end">
        <b-navbar-dropdown :label="email">
          <b-navbar-item
            v-if="email !== undefined && email.length > 0"
            @click="_logout()"
            ><template
              ><b-icon icon="logout"></b-icon><span>Logout</span></template
            ></b-navbar-item
          >
          <b-navbar-item v-else @click="_login()"> Login </b-navbar-item>
        </b-navbar-dropdown>
      </template> -->
    </b-navbar>
  </div>
</template>
<script>
export default {
  name: "navbarLayout",

  data() {
    return {
      email: "",
    };
  },

  methods: {
    _login() {
      this.gotoVerifyEmail();
    },
    _logout() {
      const params = this.getURLParams();
      this.deleteLocalStorage("iquuid2", params.dbName);
      this.deleteLocalStorage("iqgoauth2");

      this.writeLog("verifyemail", [
        {
          key: "originalURL",
          value: location.href,
        },
        { key: "remark", value: "logout" },
        { key: "email", value: this.email },
      ]);
      this.email = "";
      this._gotoSummaryPage();
    },
    _gotoSummaryPage() {
      var b = this.getURLParams();
      var item = {
        row: { DatabaseName: b.dbName, ProfileID: b.TopicID },
      };

      this.gotoSummary(item);
    },
  },

  async created() {
    this.email = await this.getUserEmail();
  },
};
</script>
