<script>
import { Doughnut, mixins } from "vue-chartjs";
import Chart from "chart.js";

const { reactiveProp } = mixins;
export default {
  name: "Doughnut",
  extends: Doughnut,
  props: ["chartData", "options", "legendId"],
  mixins: [reactiveProp],

  data: () => ({}),
  watch: {
    chartData() {
      this._legendCallback();
    },
  },
  mounted() {
     this.addPlugin({
            id: "my-plugin",
            afterDraw: function(chart) {
                var mt = chart.data.datasets[0]._meta;
                //console.log(mt);
                var ctx = chart.ctx;
                //ctx.font = "bold 20px FiraGO";
                //console.log("chart", chart.height);

               
              
                //let digit = 1;
                //let total = mt[Object.keys(mt)[0]].total;
                //let sum = total;
                //console.log("total",total);
                //while(sum > 0)
                //{
                //   sum = total / Math.pow(10, digit);
                //   console.log("sum",sum);
                //   digit ++;
                //   console.log("digit",digit);
                //  // console.log("sum",sum);
                //sum = Math.round(total / Math.pow(10, digit));
                //console.log("result", Math.round(sum));
                //console.log("sum > 0", Math.round(sum) > 0);
                
                //  digit ++;
                  // if(digit == 6) 
                  // sum = 0;
                //}

                let fontSize  = ((chart.height/400) ).toFixed(2);
                //console.log(2);

                ctx.font = (fontSize ? fontSize : 1) + "rem Bai Jamjuree";
                //ctx.fontSize = "";
                //ctx.fillStyle = "black";
                ctx.textAlign = "center";
                ///r.style.fontSize = (height / 200).toFixed(2).toString() + "rem";

                let value = mt[Object.keys(mt)[0]].total;
                //let strTotal =  total < 100000 ? total : (total / 1000000).toFixed(2) + "M"; 
                let strValue = "";
                

                //let s = value
                //let m = Math.abs(Number(s)) >= 1e9 ? (Math.abs(Number(s)) / 1e9).toFixed(2) + "B" : Math.abs(Number(s)) >= 1e6 ? (Math.abs(Number(s)) / 1e6).toFixed(2) + "M" : Math.abs(Number(s)) >= 1e3 ? (Math.abs(Number(s)) / 1e3).toFixed(2) + "K" : Math.abs(Number(s));
                
                //1000000000
                if (value > 1e9) {
                    if (value % 1e9 == 0) {
                        value = (value / 1e9).toString();
                    } else {
                        value = (value / 1e9).toFixed(2);
                    }
                    strValue = value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") + "B";
                }
                //1000000                
                else if (value > 1e6) {
                    if (value % 1e6 == 0) {
                        value = (value / 1e6).toString();
                    } else {
                        value = (value / 1e6).toFixed(2);
                    }
                    strValue = value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") + "M";
                //1000
                } else if (value > 1e3) {
                    if (value % 1e3 == 0) {
                        value = value / 1e3;
                    } else {
                        value = (value / 1e3).toFixed(2);
                    }
                    strValue = value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") + "K";

                } else if (value == 0) {
                    strValue = "";

                } else {
                    strValue = value
                                .toString()
                                .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
                }

                //strValue = m
                          // .toString()
                          // .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");

                ctx.fillText(strValue, chart.width / 2, chart.height /2);

                //ctx.font = "bold 20px FiraGO";
                //ctx.fillStyle = "red";
                //ctx.textAlign = "center";
                //ctx.fillText("D", chart.width / 2, 25);
                // ctx.font = "bold 20px FiraGO";
                // ctx.fillStyle = "red";
                // ctx.textAlign = "center";
                // ctx.rotate(Math.PI / 2);
                // ctx.fillText("B", chart.width / -2, 25);
                // // ctx.save();
                // ctx.fillText("C", chart.width / -2, chart.height - 25);
                // ctx.save();
                // ctx.rotate(Math.PI/(-2))
                // ctx.save();
            }
        });

    

      //  for (var i in this.chartData.datasets[0].data) {
      //     console.log("this.chartData.datasets[0].data[i]", this.chartData.datasets[0].data[i]);
      //     //this.chartData.datasets[0].data[i] =  < 100000 ? this.chartData.datasets[0].data[i] : (this.chartData.datasets[0].data[i] / 1000000).toFixed(2) + "M";
      //  }

    //console.log("this.chartData", this.chartData);
        

    // this.chartData is created in the mixin.
    // If you want to pass options please create a local options object
    this.renderChart(this.chartData, this.options);
    this._legendCallback();
    //this._textCenter(0)
  },
  methods: {
    _legendCallback() {
      var divName = this.legendId;
      var div = document.getElementById(divName);
      if (div) {
        const colors = this.$data._chart.data.datasets[0].backgroundColor;
        var ds = this.$data._chart.data.datasets[0].data;
        var sum = ds.reduce(function add(a, b) {
          return a + b;
        }, 0);

        var c, r, t, colorBox;
        t = document.createElement("table");
        t.className = "custom_" + divName;
        t.id = "table_" + divName;
        if (div.firstChild) {
          while (div.firstChild) {
            div.removeChild(div.lastChild);
          }
        }
        for (let i = ds.length - 1; i >= 0; i -= 1) {
          r = t.insertRow(0);
          r.style.fontSize = "0.9rem";
          c = r.insertCell(0);
          c.style.width = "50px";
          c.style.verticalAlign = "middle";
          colorBox = document.createElement("div");
          colorBox.style.width = "50px";
          colorBox.style.height = "14px";
          colorBox.style.backgroundColor = colors[i];
          colorBox.style.verticalAlign = "bottom";
          c.appendChild(colorBox);
          c = r.insertCell(1);
          c.innerHTML = this.chartData.labels[i];
          c.style.textAlign = "left";
          c = r.insertCell(2);
          c.innerHTML = this.number2Commas(ds[i]);
          c.style.textAlign = "right";
          c.className = "is-hidden-mobile";
          c = r.insertCell(3);
          var percent = (ds[i] * 100) / sum;
          c.innerHTML = percent.toFixed(2) + "%";
          c.style.textAlign = "right";
          div.appendChild(t);

            
          if (sum > 0) {
            let self = this;
            r.addEventListener("click", function () {            
 
              var dt = self.$data._chart.data.datasets[0].data;
              var rows = document.querySelectorAll(
                ".custom_" + divName + " tr"
              );

              if(dt[i] > 0)
              {
                dt[i] *= -1; 
                rows[i].className = "strikethrough";
              }
              else 
              {
                dt[i] *= -1; 
                rows[i].className = "";
              }

              // sum = dt.reduce(function add(a, b) {
              //   if(a < 0) a = 0;
              //   if(b < 0) b = 0;
              //   return a + b;
              // }, 0);

               var mt = self.$data._chart.data.datasets[0]._meta;
               //console.log(mt);
               for (var ii in mt) {
                  //console.log(ii);
                  mt[ii].data[i].hidden =  !mt[ii].data[i].hidden;
                  mt[ii].controller.chart.update();
               }

              //console.log("sum",sum);

            }.bind(this));
          } else {
            c.innerHTML = "0.00%";
          }
        }
      }
    },

    _textCenter(val) {
      Chart.pluginService.register({
        beforeDraw: function(chart) {
          var width = chart.chart.width;
          var height = chart.chart.height;
          var ctx = chart.chart.ctx;

          ctx.restore();
          var fontSize = (height / 114).toFixed(2);
          ctx.font = fontSize + "em sans-serif";
          ctx.textBaseline = "middle";

          var text = val + "%";
          var textX = Math.round((width - ctx.measureText(text).width) / 2);
          var textY = height / 2;

          ctx.fillText(this.sum, textX, textY);
          ctx.save();
        }
      });
    },

 
  },
};
</script>
