<template>
    <div id="my-popper">
        <!-- <div class="my-3">
            <b-button id="popover-reactive-1" variant="primary" ref="button">
                <b-icon icon="bell"></b-icon>
            </b-button>
        </div> -->
        <popper
            trigger="clickToOpen"
            :force-show="show"
        >

        <!-- 
            :options="{
                placement: 'auto',
                offset: [0, 8]
            }"
        -->
            <div id="myPopper" class="popper custompoper">
                <div class="container d-flex h-100">
                    <div class="a  column">
                        <div class="c is-size-5 notificationBold">
                            Notifications
                        </div>
                        <div class="c d" @click="updateALL"  v-if="group.length > 0">
                            <a href="#">
                                Mark all as read
                            </a>
                        </div>
                    </div>
                    <div
                        class="container-fluid column"
                        v-if="group.length <= 0"
                    >
                        <div class="container h-100">
                            <div class="h-100">
                                <div class="col-6">
                                    <div class="notificationtext text-center"> 
                                            User does not have to export data.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="container-fluid column" v-if="group">
                        <div
                            v-for="group in group"
                            :key="group"
                            class="float-sm-left "
                        >
                            <span class="notification_upper">{{ group }}</span>
                            <div
                                class="row"
                                :class="
                                    task.status >= 1 ? 'row-downloaded' : ''
                                "
                                v-for="task in items.filter(
                                    x => x.timehuman == group
                                )"
                                :key="task"
                            >
                                <div class="col-12 col-md-12 ">
                                    <ul
                                        class="list-unstyled notificationitem"
                                        @click="downloadItem(task)"
                                    >
                                        <li
                                            type="button"
                                            class="shadow-lg m-1 rounded text-justify p-1"
                                        >
                                            <div class="">
                                                <div id="grid">
                                                    <div
                                                        id="item1"
                                                        data-toggle="tooltip"
                                                        :title="task.filename"
                                                    >
                                                        <span
                                                            class="width ellipse two-lines"
                                                        >
                                                            <a
                                                                :href="
                                                                    task.link
                                                                "
                                                                target="_blank"
                                                                class="float-sm-left"
                                                                >{{
                                                                    task.filename
                                                                }}</a
                                                            >
                                                        </span>
                                                    </div>
                                                    <div id="item3" class="itemdownload">
                                                         
                                                          <img src="../assets/img/Download-file.png" :alt="download" />
                                                       
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <p class="notificationtext">
                                                    Your requested file is ready
                                                    for download.
                                                </p>
                                            </div>
                                            <p class=" notificationTime">
                                                {{ dateshow(task.createtime) }}
                                            </p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <span
                slot="reference"
                id="popover-reactive"
                variant="primary"
                ref="button"
                @click="showNotification"
                :class="hasNotification"
                :data-badge="notificationCount"
            >
                <b-icon icon="bell" :class="onClickedNotification"></b-icon>
            </span>
            <!-- <b-button
                slot="reference"
                id="popover-reactive-1"
                variant="primary"
                ref="button"
            >
                <b-icon icon="bell" :class="hasNotification"></b-icon>
            </b-button> -->
        </popper>
    </div>
</template>

<script>
import Popper from "vue-popperjs";
import "vue-popperjs/dist/vue-popper.css";
export default {
    components: {
        popper: Popper
    },
    name: "Notification",
    data() {
        return {
            // Note `isActive` is left out and will not appear in the rendered table
            itemsnotification: [],
            items: [],
            group: [],
            //isNotificationExpand: false,
            show: false,
        };
    },
    watch: {
        input1(val) {
            if (val) {
                this.input1state = true;
            }
        },
        input2(val) {
            if (val) {
                this.input2state = true;
            }
        }
    },
    computed: {
        notificationCount() {
            var lst = this.items.filter(x => x.status == 0);
            var count = 0;
            if (lst) count = lst.length;
            return count;
        },
        hasNotification() {

            if(!this.items)
                return "";
            
            var lst = this.items.filter(x => x.status == 0);
            var count = 0;

            if (lst) count = lst.length;
            if (count == 0) {
                return "";
            }
            return "badge1";
        },
        onClickedNotification() {
            // try {
            //     if (this.itemsnotification != null) {
            //         // if (this.items.filter(x => x.isActive == true).length > 0) {
            //         //     return "notification_alert";
            //         // }
            //         if (this.itemsnotification.alert.length > 0) {
            //             return "notification_alert";
            //         }
            //     }
            // } catch (error) {
            //     console.log("");
            // }

            return this.show? "notification_alert" : "";
        }
    },
    mounted() {
        this.loadList();
        this.monitor();


        window.addEventListener('scroll', function(){

            if(this.show){
                setTimeout(async () => {
                    //document.getElementById("myPopper").style = 'position: fixed !important;';
                    document.getElementById("myPopper").style.display = 'none';
                    this.show = false;
                }, 0);
            }
        }.bind(this));
        

        window.addEventListener('mouseup', function(event){
            var box = document.querySelector('.mdi-bell');
            if (event.target != box ){
                var i = 0;
                var eldownload = document.getElementsByClassName("itemdownload")
                for (i = 0; i < eldownload.length; i++) {
                    if (eldownload[i] instanceof HTMLElement) {
                        if (eldownload[i] == event.target.parentNode || eldownload[i] == event.target){
                            return;
                        }
                    }
                }
                var elrowdownload = document.getElementsByClassName("row row-downloaded")
                for (i = 0; i < elrowdownload.length; i++) {
                    if (elrowdownload[i] instanceof HTMLElement) {
                        if (elrowdownload[i] == event.target.parentNode || elrowdownload[i] == event.target){
                            return;
                        }
                    }
                }


                var elLink = document.querySelectorAll('.width.ellipse.two-lines')
                for (i = 0; i < elLink.length; i++) {
                    if (elLink[i] instanceof HTMLElement) {
                        if (elLink[i] == event.target.parentNode || elLink[i] == event.target){
                            return;
                        }
                    }
                }
                var elmarkAll = document.querySelector('.c.d')
                if (elmarkAll == event.target.parentNode){
                    return;
                }
                if(this.show)
                    this.showNotification();
            }
        }.bind(this));

    },
    methods: {
        downloadItem(item) {
            this.updateItem(item).then(res => {
                if (res) {
                    //this.download(item.link, item.filename + ".xlsx");
                    //window.open(item.link, "_blank");
                    // var a = document.createElement('a');
                    // var linkText = document.createTextNode("download");
                    // a.appendChild(linkText);
                    // //a.title = "my title text";
                    // a.href = "https://localhost:8080/IQIO-webapi/index.php/Dashboard/Download/eyJwYXRoIjoiZG93bmxvYWRzXC8yMDIyMDgwM1wvMjAyMjA4MDMxNDIzMzkueGxzeCIsIm5hbWUiOiJLcnVuZ3NyaV92c19Db21wZXRpdG9yc18yMDIyMDMwMS0yMDIyMDMzMS54bHN4In0=";
                    // document.body.appendChild(a);

                    if (res) {
                        let a = document.createElement("a");
                        a.target = "_blank";
                        a.href = item.link
                        
                        //"http://localhost:8080/IQIO-webapi/index.php/Dashboard/Download/eyJwYXRoIjoiZG93bmxvYWRzXC8yMDIyMDgwM1wvMjAyMjA4MDMxNDIzMzkueGxzeCIsIm5hbWUiOiJLcnVuZ3NyaV92c19Db21wZXRpdG9yc18yMDIyMDMwMS0yMDIyMDMzMS54bHN4In0=";
                        a.setAttribute("rel", "noopener noreferrer");
                        a.click();
                    }


                }
            });
        },
        updateItem(item) {
            item.status = 2;
            var list = [{ ID: item.id, Status: item.status }];
            return this.updateReadNotification({ updates: list });
            // {"updates" : [{"ID" : 1 , "Status" : 2 },{"ID" : 2 , "Status" : 1},{"ID" : 3 , "Status" : 1}]}
        },
        updateALL() {
            var list = this.items.map(x => {
                x.status = 1;
                return { ID: x.id, Status: x.status };
            });
            this.updateReadNotification({ updates: list });
        },
        showNotification() {

            setTimeout(async () => {
                //document.getElementById("myPopper").style = 'position: fixed !important; will-change: transform; right: 1% !important; top: 5% !important;';
           


            if(!this.show){
                this.show = true;

                //document.getElementById("myPopper").style = 'position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(-277px, 25px, 0px);';
                //document.getElementById("myPopper").style = 'position: fixed !important; will-change: transform; right: 1% !important; top: 5% !important;';
                document.getElementById("myPopper").style = 'position: fixed !important;';
                document.getElementById("myPopper").style.display = '';
                
                //this.isNotificationExpand = true;
                // if(this.isNotificationExpand == undefined){
                //     this.isNotificationExpand = true;
                // }
                // else {
                //     this.isNotificationExpand = ! this.isNotificationExpand;
                // }
                // console.log("showNotification", this.isNotificationExpand);
                this.loadList();
            } else {
                this.show = false;
                document.getElementById("myPopper").style.display = 'none';
            }
             }, 0);
        },
        monitor() {
            setTimeout(() => {
                this.getNotification().then(res => {
                    this.items = res.data;
                    this.group = res.group;
                    this.itemsnotification = res;
                });

                this.monitor();
            }, 10000);
        },
        async loadList() {
            try {
                let res = await this.getNotification();
                this.items = res.data;
                this.group = res.group;
                this.itemsnotification = res;
            } catch (error) {
                this.items = [];
            }
        },
        dateshow(time) {
            return this.time_ago(Date.parse(time));
        },
        // onClose() {
        //     console.log("popper onClose");
        //     this.show = false;
        //     this.popoverShow = false;
        // }
    }
};
</script>
