<template>
  <div>
    <b-navbar class="is-fixed-top" v-if="astate !== 0">
      <template slot="brand">
        <b-navbar-item v-if="astate == 1" tag="router-link" :to="{ path: '' }">
          <a class="navbar-item" @click.prevent="_gotoSummaryPage()">
            <img src="../assets/img/io_logo.png" :alt="$websiteDescription" />
          </a>
        </b-navbar-item>
        <b-navbar-item v-if="astate == 2">
          <a class="navbar-item no-cursor">
            <img src="../assets/img/io_logo.png" :alt="$websiteDescription" />
          </a>
        </b-navbar-item>
      </template>
      <!-- <template slot="end">
        <b-navbar-dropdown :label="email" v-if="astate == 1">
          <b-navbar-item
            v-if="email !== undefined && email.length > 0"
            @click="_logout()"
            ><template
              ><b-icon icon="logout"></b-icon><span>Logout</span></template
            ></b-navbar-item
          >
          <b-navbar-item v-else @click="_login()"> Login </b-navbar-item>
        </b-navbar-dropdown>
      </template> -->
      <template slot="end">
          <div class="top-right-container">
            <div v-if="email !== undefined && email.length > 0" class="top-item">
              <div class="top-item">
                  <b-icon icon="person"></b-icon>
              </div>
              <div  class="top-item">{{email}}</div>
              <!-- <div class="bell-notification">
                <span class="bell-notification-child">
                  <Notification></Notification>
                  </span>
              </div> -->
              <div class="top-item icon-hover" @click="_logout()">
                <b-icon icon="logout" ></b-icon>
              </div>
            </div>
            <div v-else @click="_login()"> Login </div>
          </div>
      </template> 
    </b-navbar>

    <div v-if="astate == 1" class="_wrapper has-text-left">
      <div v-if="isLoading == false">
        <div class="" v-if="isFoundData == true">
          <div slot="trigger" class="card-header" role="button">
            <p class="card-header-title m-l-15">Manual Data Input</p>
            <b-button type="is-primary" class="" @click="_gotoCleansingIndex()">
              <span>Back</span>
            </b-button>
          </div>
          <div class="card">
            <div class="card-content">
              <div class="content">
                <div
                  class="has-text-weight-bold has-text-left is-horizontal"
                  v-if="this.state !== 2"
                >
                  Please fill out the following data below
                </div>

                <div
                  class="has-text-weight-bold has-text-left is-horizontal"
                  v-if="this.state == 2"
                >
                  Data have been saved. In order to cancel the previous saving
                  data, please press cancel button. Or, add more news, press add
                  more button.
                </div>

                <div v-if="this.state !== 2">
                  <div class="divider">Input Section</div>
                  <form @submit.prevent="onSubmit">
                    <div class="columns">
                      <div class="column is-7">
                        <b-field label="Select Topic*">
                          <b-select
                            placeholder="Select topic"
                            ref="topicid"
                            expanded
                            required
                            icon="cube"
                            iconPack="fas"
                            v-model="selectedTopicID"
                          >
                            <option
                              v-for="item in oTopics"
                              :value="item['TopicID']"
                              :key="item['QueryName']"
                            >
                              {{ item["QueryName"] }}
                            </option>
                          </b-select>
                        </b-field>
                        <b-field label="Post Time*">
                          <b-datetimepicker
                            required
                            :datetime-formatter="dtFormatter"
                            placeholder="Click to select..."
                            icon="calendar-today"
                            :datepicker="{ showWeekNumber }"
                            :timepicker="{
                              enableSeconds,
                              hourFormat: format,
                            }"
                            v-model="DisplayTime"
                            :value="new Date()"
                          >
                            <template slot="left">
                              <b-button
                                type="is-primary"
                                @click.prevent="DisplayTime = new Date()"
                              >
                                <b-icon icon="clock"></b-icon>
                                <span>Now</span>
                              </b-button>
                            </template>
                          </b-datetimepicker>
                        </b-field>
                        <b-field label="URL*">
                          <b-input
                            ref="url"
                            v-model="OriginalURL"
                            required
                            icon="web"
                            type="url"
                            minlength="10"
                            maxlength="2048"
                            placeholder="e.g. https://www.infoquest.co.th/2020/37242"
                          ></b-input>
                        </b-field>

                        <b-field label="Source Name">
                          <b-input
                            ref="location"
                            v-model="Location"
                            type="text"
                            minlength="0"
                            required
                            maxlength="256"
                            placeholder="Source name of the URL"
                          ></b-input> </b-field
                        >e.g. (Source Name)
                        <ul>
                          <li>
                            <u>Website:</u> Domain Name (e.g. URL:
                            https://www.infoquest.co.th =>
                            <b>infoquest.co.th</b>)
                          </li>
                          <li>
                            <u>Facebook:</u> PageID (e.g. URL:
                            https://www.facebook.com/infoquest =>
                            <b>infoquest</b>)
                          </li>
                          <li>
                            <u>Instagram:</u> Account (e.g. URL:
                            https://www.instagram.com/infoquestnews =>
                            <b>infoquestnews</b>)
                          </li>
                          <li>
                            <u>Twitter:</u> Account (e.g. URL:
                            https://twitter.com/infoquestnews =>
                            <b>infoquestnews</b>)
                          </li>
                          <li>
                            <u>Youtube:</u> Channel Name (e.g. URL:
                            https://www.youtube.com/channel/UCq7l7n7w7xniquChqsJKuVw
                            => <b>InfoquestNews</b>)
                          </li>
                        </ul>
                      </div>
                      <div class="column is-1"></div>
                      <div class="column">
                        <b-field label="Headline*">
                          <b-input
                            ref="headline"
                            type="textarea"
                            v-model="Headline"
                            required
                            rows="3"
                            minlength="10"
                            maxlength="256"
                            placeholder="Headline"
                          ></b-input>
                        </b-field>
                        <b-field label="Hit Sentence">
                          <b-input
                            v-model="HitSentence"
                            type="textarea"
                            rows="13"
                            minlength="0"
                            maxlength="65535"
                            placeholder="Hit Sentence"
                          ></b-input>
                        </b-field>
                      </div>
                    </div>
                    <hr />
                    <div class="columns">
                      <div class="column is-full">
                        <div class="buttons is-pulled-left">
                          <b-button
                            type="is-primary"
                            @click.prevent="gotoDetailReportPage()"
                          >
                            <span>View Report</span>
                          </b-button>
                        </div>
                        <div class="buttons is-pulled-right">
                          <b-button type="is-primary" native-type="submit">
                            <span>Submit</span>
                          </b-button>
                          <b-button
                            type="is-warning"
                            @click.prevent="clearInputData()"
                          >
                            <span>Clear</span>
                          </b-button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>

                <div v-else-if="this.state == 2">
                  <div v-if="this.insertResult" class="py-5 px-5">
                    <div class="columns">
                      <div class="column">Topic</div>
                      <div class="column">
                        {{ this.insertResult.Topic }}
                      </div>
                    </div>

                    <div class="columns">
                      <div class="column">Display Time</div>
                      <div class="column">
                        {{ this.insertResult.DisplayTime }}
                      </div>
                    </div>

                    <div class="columns">
                      <div class="column">News Type</div>
                      <div class="column">
                        {{ this.insertResult.NewsType }}
                      </div>
                    </div>

                    <div class="columns">
                      <div class="column">Location</div>
                      <div class="column">
                        {{ this.insertResult.Location }}
                      </div>
                    </div>

                    <div class="columns">
                      <div class="column">Headline</div>
                      <div class="column">
                        {{ this.insertResult.Headline }}
                      </div>
                    </div>

                    <div class="columns">
                      <div class="column">Hit Sentence</div>
                      <div class="column">
                        {{ this.insertResult.HitSentence }}
                      </div>
                    </div>

                    <div class="columns">
                      <div class="column">AD Value</div>
                      <div class="column">
                        {{ this.number2Commas(this.insertResult.AdValue) }}
                      </div>
                    </div>

                    <div class="columns">
                      <div class="column">PR Value</div>
                      <div class="column">
                        {{ this.number2Commas(this.insertResult.PRValue) }}
                      </div>
                    </div>

                    <div class="columns">
                      <div class="column">URL</div>
                      <div class="column">
                        {{ this.insertResult.OriginalURL }}
                      </div>
                    </div>
                    <div class="columns">
                      <div class="column">Page Like</div>
                      <div
                        class="column"
                        v-if="this.insertResult.PotentialReach == null"
                      >
                        N/A
                      </div>
                      <div class="column" v-else>
                        {{
                          this.number2Commas(this.insertResult.PotentialReach)
                        }}
                      </div>
                    </div>

                    <div class="columns">
                      <div class="column">Update User</div>
                      <div class="column">
                        {{ this.insertResult.UpdateUser }}
                      </div>
                    </div>

                    <div class="columns">
                      <div class="column">Update Time</div>
                      <div class="column">
                        {{ this.insertResult.UpdateTime }}
                      </div>
                    </div>

                    <div class="columns">
                      <div class="column is-full"><hr /></div>
                    </div>

                    <div class="columns">
                      <div class="column">
                        <b-button
                          type="is-primary"
                          @click.prevent="_gotoDetailReportPage()"
                        >
                          <span>View Report</span>
                        </b-button>
                      </div>

                      <div class="column">
                        <div class="buttons is-pulled-right">
                          <b-button
                            type="is-primary"
                            @click.prevent="gotoInputDataPage()"
                            >Add more</b-button
                          >

                          <b-button
                            type="is-warning"
                            @click.prevent="
                              cancelInputDataPage(
                                insertResult.RowID,
                                insertResult.DisplayTime,
                                insertResult.UpdateUser
                              )
                            "
                            >Cancel</b-button
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <NotFound v-if="isFoundData == false"></NotFound>
      </div>
      <div v-else>
        <Preparing
          :waitingText="this.waitingText"
          v-if="this.isLoading == true"
        ></Preparing>
        <b-loading
          :is-full-page="isFullPage"
          :active.sync="isLoading"
          :can-cancel="false"
        ></b-loading>
      </div>
    </div>
    <div class="_wrapper" v-else-if="astate == 2">
      <NotAuthorizeEditor></NotAuthorizeEditor>
    </div>
    <Footer
      v-if="isLoading == false"
      :eulaurl="eulaurl"
      :contactusurl="contactusurl"
      :companyurl="companyurl"
    ></Footer>
  </div>
</template>
<script>
import Footer from "../layout/Footer.vue";
import aes from "crypto-js/aes";
import encHex from "crypto-js/enc-hex";
import padZeroPadding from "crypto-js/pad-zeropadding";
import NotFound from "../contents/notfound";
import Preparing from "../contents/preparing";
import moment from "moment";
import NotAuthorizeEditor from "../pages/NotAuthorizeEditor";
export default {
  name: "inputDataPage",
  components: {
    Footer,
    NotFound,
    Preparing,
    NotAuthorizeEditor,
  },

  computed: {
    format() {
      return this.formatAmPm ? "12" : "24";
    },
  },
  data() {
    return {
      eulaurl: "",
      contactusurl: "",
      companyurl: "",

      astate: 0,
      email: "",
      bPRValue: false,

      labelTopicName: "QueryName",
      valueTopicName: "TopicID",
      selectedTopicID: [],
      selectedTopicName: "",

      insertResult: [],

      OriginalURL: null,
      Headline: null,
      HitSentence: null,
      DisplayTime: new Date(),
      Location: null,
      PRValue: "",
      AdValue: "",
      PageLike: "",

      oTopic: {},
      isLoading: true,
      isFoundData: true,
      isFullPage: true,

      showWeekNumber: false,
      formatAmPm: false,
      enableSeconds: true,
      dateoptions: { year: "numeric", month: "short", day: "numeric" },
      state: 1,
      hexkey: "",
      hexiv: "",
      config: {},
      waitingText: "Preparing data, please wait...",
    };
  },
  mounted() {
    this.isLoading = true;
  },
  async created() {
    try {
      this.astate = 1;
      this.params = this.getURLParams();
      await this.getServerConfig().then((res) => {
        this.config = res;
        this.eulaurl = this.config.EULAURL;
        this.contactusurl = this.config.ContactUsURL;
        this.companyurl = this.config.CompanyURL;
      });

      this.gaPage();

      var iquuid = this.readLocalStorage("iquuid2", this.params.dbName);
      var BrowserID = this.getBrowserID();
      this.$store.commit("setPageBack", this.params);
      if (iquuid == null) {
        this.gotoVerifyEmail();
      } else {
        if (await this.isValidDatabase()) {
          this.isLoading = true;
          await this.getInitialData();
          if (await this._isValidURL(this.params)) {
            this.params.pageBack = "inputdata";
            this.params.page = this.params.pageBack;
            var res = await this.checkPermission(
              iquuid,
              BrowserID,
              this.params.page
            );

            var _continue = res.data.continue;
            var _goto = res.data.goto;
            var _clearUUID = res.data.clearUUID;

            if (_continue) {
              if (_goto == 401) {
                this.astate = 2;
                this.isLoading = false;
                this.toast(
                  "danger",
                  "Restricted section. Only valid email can visit this page."
                );
              } else {
                this.email = await this.getUserEmail();
              }
              this.isLoading = false;
            } else {
              if (_goto == 401 && _clearUUID == true) {
                this.deleteLocalStorage("iquuid2", this.params.dbName);
              }

              if (_goto == "verifyemail") {
                this.gotoVerifyEmail();
              } else {
                this.astate = 2;
                this.isLoading = false;
                this.toast(
                  "danger",
                  "Restricted section. Only valid email can visit this page."
                );
              }
            }
          } else {
            this.gotoNotFound(this.params.dbName);
          }
        } else {
          this.redirectHost(this.params);
        }
      }
    } catch (err) {
      throw err;
    }
  },

  methods: {
    async _isValidURL(params) {
      var a =
        this.oTopics !== undefined && Object.keys(this.oTopics).length > 0
          ? true
          : false;

      var b = this.isValidPeriod(params);
      this.start = b.start;
      this.end = b.end;
      b = b.result;

      var c = false;

      var cutLength = params.TopicID.length;

      for (var i in this.oTopics) {
        if (this.oTopics[i].TopicID.substr(0, cutLength) == params.TopicID) {
          c = true;
          break;
        }
      }

      return a && b && c;
    },
    _gotoCleansingIndex() {
      this.params = this.getURLParams();
      var p = [];
      if (this.params.periodID !== null) {
        p = [
          "cleansingindex",
          this.params.dbName,
          this.params.TopicID,
          this.params.periodName,
          this.params.periodID,
        ];
      } else {
        p = [
          "cleansingindex",
          this.params.dbName,
          this.params.TopicID,
          this.params.periodName,
        ];
      }
      p = "/" + p.join("/");

      this.goto(p);
    },
    _login() {
      this.gotoVerifyEmail();
    },
    _logout() {
      const params = this.getURLParams();
      this.deleteLocalStorage("iquuid2", params.dbName);
      this.deleteLocalStorage("iqgoauth2");
      this.$store.commit("setPageBack", params);
      this.writeLog("verifyemail", [
        {
          key: "originalURL",
          value: location.href,
        },
        { key: "remark", value: "logout" },
        { key: "email", value: this.email },
      ]);
      this.email = "";
      this.gotoVerifyEmail();
    },
    _gotoSummaryPage() {
      this.params = this.getURLParams();
      var item = {
        row: {
          DatabaseName: this.params.dbName,
          ProfileID: this.params.TopicID.toLowerCase().substr(0, 6),
        },
      };

      this.gotoSummary(item);
    },

    onChangePRValue() {
      if (this.PRValue.length == 0) {
        this.bPRValue = false;
        this.AdValue = "";
      }
    },
    onChangeAdValue() {
      if (this.AdValue.length > 0) {
        this.PRValue = this.AdValue * 3;
      } else {
        this.AdValue = "";
        this.PRValue = "";
        this.bPRValue = false;
      }
    },

    _gotoDetailReportPage() {
      var a = this.insertResult.DisplayTime;
      this.start = moment(a).format("YYYY-MM");
      this.selectedTopicID = this.insertResult.TopicID;
      var item = {
        row: { TopicID: this.selectedTopicID, start: this.start },
      };
      this.gotoDetailReport(item);
    },

    gotoDetailReportPage() {
      var item = {
        row: { TopicID: this.selectedTopicID, start: this.start },
      };
      this.gotoDetailReport(item);
    },

    async onSubmit() {
      this.isLoading = true;
      var params = this.getURLParams();
      this.hexkey = this.config["$HexKey"];
      this.hexiv = this.config["$HexIV"];

      let key = encHex.parse(this.hexkey);
      let iv = encHex.parse(this.hexiv);
      var iquuid = this.readLocalStorage("iquuid2", params.dbName);
      var encrypted = aes
        .encrypt(this.$prefixAuthKey + "-" + Date.now(), key, {
          iv: iv,
          padding: padZeroPadding,
        })
        .toString();
      params["AuthKey"] = encrypted;
      params["OriginalURL"] = this.cleanText(this.OriginalURL);
      params["Headline"] = this.cleanText(this.Headline);

      params["HitSentence"] = this.cleanText(this.HitSentence);
      params["TopicID"] = this.selectedTopicID;
      params["DisplayTime"] = this.FormatDate(
        this.DisplayTime,
        "YYYY-MM-DD HH:mm:ss"
      );
      params["Location"] = this.cleanText(this.Location);
      params["SourceName"] = this.cleanText(this.Location);

      params["PRValue"] = this.PRValue;
      params["AdValue"] = this.AdValue;
      params["PageLike"] = this.PageLike;
      params["iquuid"] = iquuid;
      params["email"] = await this.getUserEmail();
      this.$store.commit("setPageBack", params);
      if (iquuid == null) {
        this.gotoVerifyEmail();
      } else {
        await this.httpRequest("Dashboard/saveDataItem", params).then(() => {
          var res = this.$result.data;
          if (res.result == 200) {
            this.state = 2;
            this.insertResult = res.row;

            this.toast("success", res.message);
          } else {
            this.toast("danger", res.message);
          }
          this.isLoading = false;
        });
      }
    },

    saveInputData() {
      this.state = 2;
      setTimeout(() => {
        this.state = 1;
      }, 2000);
    },

    clearInputData() {
      this.OriginalURL = "";
      this.Headline = "";
      this.HitSentence = "";
      this.DisplayTime = new Date();
      this.Location = "";
      this.PRValue = "";
      this.AdValue = "";
      this.PageLike = "";
    },

    async cancelInputDataPage(RowID, DisplayDate, email) {
      this.hexkey = this.config["$HexKey"];
      this.hexiv = this.config["$HexIV"];

      let key = encHex.parse(this.hexkey);
      let iv = encHex.parse(this.hexiv);

      var encrypted = aes
        .encrypt(this.$prefixAuthKey + "-" + Date.now(), key, {
          iv: iv,
          padding: padZeroPadding,
        })
        .toString();

      const params = this.getURLParams();
      var iquuid = this.readLocalStorage("iquuid2", params.dbName);
      params["AuthKey"] = encrypted;
      params["iquuid"] = iquuid;
      params["BrowserID"] = this.getBrowserID();
      params["RowID"] = RowID;
      params["MentionID"] = [1, 2];
      params["DisplayDate"] = DisplayDate;
      params["email"] = email;

      await this.httpRequest("Dashboard/deleteItem", params).then(() => {
        var res = this.$result.data;

        if (res.result == 200) {
          this.clearInputData();
          this.state = 1;
          this.toast("success", res.message);
        } else {
          this.toast("danger", res.message);
        }
      });
    },

    gotoInputDataPage() {
      this.clearInputData();
      this.state = 1;
    },

    onSelectTopic(v) {
      this.Topic = v;
    },

    async getInitialData() {
      const params = this.getURLParams();
      params["isCleansing"] = 0;
      params["iquuid"] = this.readLocalStorage("iquuid2", params.dbName);
      params["logType"] = "inputdata";

      await this.httpRequest("Dashboard/getInitialData", params).then((res) => {
        if (
          this.$result.data.periods !== null &&
          this.$result.data.periods.data !== undefined &&
          this.$result.data.periods.data.length > 0
        ) {
          res = this.$result.data;
          this.oTopics = res.topics.data[0].Query;
          var u = this.getURLParams();
          this.selectedTopicID = u.TopicID;
          this.isLoading = false;
          if (this.$refs.topicid !== undefined) {
            this.$nextTick(() => {
              this.$refs.topicid.checkHtml5Validity();
              this.$refs.url.checkHtml5Validity();
              this.$refs.headline.checkHtml5Validity();
              this.$refs.location.checkHtml5Validity();
            });
          }
        }
      });
    },
  },
};
</script>
<style>
input,
textarea {
  font-family: Bai Jamjuree;
}
</style>