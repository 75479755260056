<template>
  <div>
    <div class="card">
      <div slot="trigger" class="card-header" role="button">
        <div class="card-header-title">
          <div
            class="text-center w-100"
            v-if="chartData !== undefined && chartData !== null"
          >
            {{ chartData.title }}
          </div>
        </div>
      </div>
      <div class="card-content">
        <div class="content" v-if="chartData != null">
          <ChartDoughnut1
            :chartData="chartData"
            :options="options"
            :legendId="legendName"
          ></ChartDoughnut1>
          <div
            v-if="legendName.substr(legendName.length - 1, 1) !== 'b'"
            :ref="legendName"
            :id="legendName"
            class="chart-legend"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ChartDoughnut1 from "../../controls/ChartJS/ChartDoughnut1";

export default {
  components: {
    ChartDoughnut1,
  },
  watch: {
    isLoading() {},
  },
  data() {
    return {
      title: "",
    };
  },
  props: {
    chartData: {
      type: Object,
      default: null,
    },
    options: {
      type: Object,
      default: null,
    },
    legendName: {
      type: String,
      default: "",
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },

  mounted() {
    this.title = "";
  },
};
</script>
