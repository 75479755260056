<template>
  <div>
    <a name="toTop"></a>
    <b-modal
      :active.sync="isModalActive"
      :width="400"
      scroll="keep"
      custom-class="IQIO-Modal"
    >
      <div class="card">
        <div class="card-content">
          <div class="content has-text-left">
            <div class="columns">
              <div class="column is-3">
                <b-icon
                  icon="alert-circle"
                  size="is-large"
                  class="has-text-danger"
                ></b-icon>
              </div>
              <div class="column">
                "Someone is now editing data in this selected day. Would you
                like to continue editing?",
              </div>
            </div>
          </div>
        </div>
        <footer class="card-footer">
          <b-button type="is-primary" @click="_confirmModal" size=""
            >Confirm</b-button
          >
          <b-button type="is-primary" @click="_closeModal">Cancel</b-button>
        </footer>
      </div>
    </b-modal>

    <b-navbar class="is-fixed-top">
      <template slot="brand">
        <b-navbar-item v-if="astate == 1" tag="router-link" :to="{ path: '' }">
          <a class="navbar-item" @click.prevent="_gotoSummaryPage()">
            <img src="../assets/img/io_logo.png" :alt="$websiteDescription" />
          </a>
        </b-navbar-item>
        <b-navbar-item v-if="astate == 2">
          <a class="navbar-item no-cursor">
            <img src="../assets/img/io_logo.png" :alt="$websiteDescription" />
          </a>
        </b-navbar-item>
      </template>
      <template slot="start" v-if="astate == 1">
        <div class="select-width" v-if="Object.keys(this.oTopics).length > 0">
          <Selectbox
            :items="this.oTopics"
            :labelName="this.labelTopicName"
            :valueName="this.valueTopicName"
            :selected="this.selectedTopicID"
            :icon="this.iconTopic"
            :iconPack="this.iconPackTopic"
            @input="onChangeTopic"
          ></Selectbox>
        </div>
      </template>
      
      <!-- <template slot="end">
        <div class="top-right-container">
          <div v-if="email !== undefined && email.length > 0" @click="_logout()">
            <template><b-icon icon="logout"></b-icon><span>Logout</span></template>
          </div>
          <div v-else @click="_login()"> Login </div>
        </div>
      </template> -->
      <!-- <template slot="end">
        <b-navbar-dropdown :label="email" v-if="astate == 1">
          <b-navbar-item
            v-if="email !== undefined && email.length > 0"
            @click="_logout()"
            ><template
              ><b-icon icon="logout"></b-icon><span>Logout</span></template
            ></b-navbar-item
          >
          <b-navbar-item v-else @click="_login()"> Login </b-navbar-item>
        </b-navbar-dropdown>
      </template> -->

      <template slot="end">
          <div class="top-right-container">
            <div v-if="email !== undefined && email.length > 0" class="top-item">
              <div class="top-item">
                  <b-icon icon="person"></b-icon>
              </div>
              <div  class="top-item">{{email}}</div>
              <div class="bell-notification">
                <span class="bell-notification-child">
                  <Notification></Notification>
                  </span>
              </div>
              <div class="top-item icon-hover" @click="_logout()">
                <b-icon icon="logout" ></b-icon>
              </div>
            </div>
            <div v-else @click="_login()"> Login </div>
          </div>
      </template> 

    </b-navbar>

    <div class="_wrapper" v-if="astate == 1">
      <div class="columns is-multiline is-mobile">
        <div class="column text-big is-7-desktop is-full-mobile has-text-left">
          <span v-if="selectedTopicName">{{ selectedTopicName }}</span>
        </div>

        <div class="column is-5-desktop is-full-mobile has-text-right">
          <div class="">
            <button
              class="button is-primary mr-1"
              type="button"
              slot="trigger"
              @click.prevent="_gotoReportPage()"
            >
              <template>
                <span>Back</span>
              </template>
            </button>

            <button
              class="button is-primary mr-1"
              type="button"
              slot="trigger"
              @click.prevent="gotoInputDataPage()"
            >
              <template>
                <span>Manual Input</span>
              </template>
            </button>

            <button
              class="button is-primary"
              type="button"
              slot="trigger"
              @click.prevent="gotoUploadDataPage()"
            >
              <template>
                <span>Import</span>
              </template>
            </button>
          </div>
        </div>
      </div>

      <div v-if="isLoading == false">
        <div v-if="isFoundData == true">
          <div class="card">
            <div class="card-content">
              <div class="content">
                <b-table
                  id="table-cleansing-index"
                  :striped="true"
                  :data="
                    cleansingData.data == undefined ? [] : cleansingData.data
                  "
                  :loading="isLoading"
                  :per-page="perPage"
                  :current-page.sync="page"
                  :total="total"
                  :paginated="paginated"
                  :backend-pagination="true"
                  pagination-position="both"
                  @page-change="onPageChange"
                  aria-next-label="Next page"
                  aria-previous-label="Previous page"
                  aria-page-label="Page"
                  aria-current-label="Current page"
                  aria-controls="__content"
                >
                  <template>
                    <b-table-column
                      field="DisplayDate"
                      label="Date"
                      centered
                      v-slot="props"
                      :width="150"
                    >
                      {{ _fDate(props.row.DisplayDate) }}
                    </b-table-column>
                    <b-table-column
                      field="Count"
                      label="# Mentions"
                      centered
                      :width="120"
                      :custom-sort="_sortMention"
                      v-slot="props"
                      ><div class="has-text-right">
                        {{ number2Commas(props.row.Count) }}
                      </div></b-table-column
                    >

                    <b-table-column
                      field="Status"
                      label="Status"
                      centered
                      :width="250"
                      v-slot="props"
                    >
                      <div v-if="props.row.StatusID == 0">
                        <div class="status-tag">
                          [{{ props.row.Count - props.row.incomplete }}/{{
                            props.row.Count
                          }}]

                          <span>Unreviewed</span>
                          <b-icon
                            icon="checkbox-blank-circle"
                            type="is-warning"
                          ></b-icon>
                        </div>
                      </div>

                      <div v-if="props.row.StatusID == 1">
                        <div class="status-tag">Reviewing</div>
                        <b-icon
                          icon="checkbox-multiple-blank-circle"
                          type="is-info"
                        ></b-icon>
                      </div>

                      <div v-if="props.row.StatusID == 2">
                        <div class="status-tag">
                          [{{ props.row.Count - props.row.incomplete }}/{{
                            props.row.Count
                          }}] Done
                          <b-icon
                            icon="checkbox-marked-circle"
                            type="is-success"
                          ></b-icon>
                        </div>
                      </div>
                    </b-table-column>

                    <b-table-column
                      field="Email"
                      label="Email"
                      centered
                      v-slot="props"
                    >
                      <span v-if="props.row.Email.length > 0">
                        <b-button
                          v-if="props.row.StatusID == 1"
                          type="is-danger"
                          @click="_clearStatus(props.row)"
                          icon-left="close-box"
                          >{{ props.row.Email }}</b-button
                        >
                      </span>
                      <span v-if="props.row.StatusID == 2">{{
                        props.row.Email
                      }}</span>
                    </b-table-column>

                    <b-table-column centered v-slot="props" :width="100">
                      <b-button
                        type="is-primary"
                        @click="_gotoCleansingV2(props.row)"
                        custom-class="reviewData"
                      >
                        <template>
                          <span>Review Data</span>
                        </template>
                      </b-button>
                    </b-table-column>
                  </template>
                  <template
                    slot="empty"
                    v-if="
                      isLoading == false &&
                      cleansingData.data !== undefined &&
                      cleansingData.data !== null
                    "
                  >
                    <section class="section">
                      <div class="content has-text-grey">
                        <p class="has-text-centered">
                          <b-icon icon="emoticon-sad" size="is-large"></b-icon>
                        </p>
                        <p class="has-text-centered">No record found here.</p>
                      </div>
                    </section>
                  </template>
                </b-table>
                <div align="right" class="m-t-10">
                  <a href="#toTop" class="anchor"
                    ><div class="block">
                      <b-icon
                        icon="arrow-up-bold-circle-outline"
                        size="is-small"
                      >
                      </b-icon
                      >Go to Top
                    </div></a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <NotFound></NotFound>
        </div>
      </div>
      <div v-else>
        <b-loading
          :is-full-page="isFullPage"
          :active.sync="isLoading"
          :can-cancel="false"
        ></b-loading>
        <Preparing :waitingText="this.waitingText"></Preparing>
      </div>
    </div>
    <div class="_wrapper" v-else-if="astate == 2">
      <NotAuthorizeEditor></NotAuthorizeEditor>
    </div>
    <Footer
      v-if="isLoading == false"
      :eulaurl="eulaurl"
      :contactusurl="contactusurl"
      :companyurl="companyurl"
    ></Footer>
  </div>
</template>

<script>
import Footer from "../layout/Footer.vue";
import Preparing from "../contents/preparing";
import Selectbox from "../controls/Selectbox";
import NotFound from "../contents/notfound";
import moment from "moment";
import NotAuthorizeEditor from "../pages/NotAuthorizeEditor";
import Notification from "../controls/Notification";
export default {
  name: "cleansingindexPage",
  components: {
    NotFound,
    Footer,
    Preparing,
    Selectbox,
    NotAuthorizeEditor,
    Notification,
  },

  data() {
    return {
      eulaurl: "",
      contactusurl: "",
      companyurl: "",

      astate: 0,
      isModalActive: false,
      IdleTimeout: 0,
      cleansingIndexRefreshDuration: 0,

      isFoundData: true,
      paginated: true,
      email: "",
      isFullPage: true,
      isLoading: true,
      waitingText: "Preparing data, please wait...",
      isLogin: false,

      selectedTopicName: "",
      selectedTopicID: "",
      selectedTopic: {},

      oTopics: {},
      cleansingData: {},
      page: 1,
      perPage: 100,
      total: 0,

      polling: null,

      labelTopicName: "QueryName",
      valueTopicName: "TopicID",

      iconTopic: "cube",
      iconPackTopic: "fas",

      selectRow: {},
      start: "",
      end: "",
    };
  },
  beforeDestroy() {
    clearInterval(this.polling);
  },

  methods: {
    async _setSelectPeriod(params) {
      this.params = this.getURLParams();

      var se = this.getStartEndInterval(params.periodName, params.periodID);
      this.start = se.start;
      this.end = se.end;
      this.params.start = this.start;
      this.params.end = this.end;
      await this.getReport(this.start, this.end);
    },

    async _isValidURL(params) {
      var a =
        this.oTopics !== undefined && Object.keys(this.oTopics).length > 0
          ? true
          : false;

      var b = false;
      if (
        params.periodName.toLowerCase() == "monthly" &&
        params.periodID.toLowerCase() == "latest"
      ) {
        b = true;
      }

      var c = false;

      if (params.TopicID.length == 10 || params.TopicID.length == 6) c = false;
      var cutLength = params.TopicID.length;

      for (var i in this.oTopics) {
        if (this.oTopics[i].TopicID.substr(0, cutLength) == params.TopicID) {
          c = true;
          break;
        }
      }
      return a && b && c;
    },
    _fDate(d) {
      return moment(d).format("DD MMM YY");
    },
    _confirmModal() {
      var item = { row: { TopicID: "", start: "", end: "" } };

      item.row.TopicID = this.selectRow.TopicID;
      item.row.DisplayDate = this.selectRow.DisplayDate;

      this.gotoCleansingV2(item);
    },

    _closeModal() {
      this.isModalActive = false;
    },
    _canClear() {
      return true;
      // var rowEmail = item.Email;
      // var cleansingEmail = this.email;
      // if (item.StatusID == 1) {
      //   if (rowEmail.toLowerCase() == cleansingEmail.toLowerCase()) {
      //     return true;
      //   } else {
      //     return false;
      //   }
      // } else {
      //   return false;
      // }
    },
    async _clearStatus(item) {
      var cleansingEmail = this.email;
      if (this._canClear()) {
        item.StatusID = 0;
        item.Email = "";
        this.clearStatus(item);
      } else {
        this.toast(
          "warning",
          "You (" + cleansingEmail + ") cannot clear status of other."
        );
      }
    },

    async canClick() {
      return true;
      // this.params.iquuid = await this.readLocalStorage(
      //   "iquuid2",
      //   this.params.dbName
      // );
      // this.params.DisplayDate = item.DisplayDate;
      // this.params.topicID = this.params.TopicID;

      // await this.httpRequest(
      //   "Dashboard/checkEmailRowCleansing",
      //   this.params
      // ).then(() => {
      //   var data = this.$result.data.data;

      //   if (data.Email && data.Status && data.CreateTime) {
      //     var rowEmail = data.Email;
      //     var rowStatusID = data.StatusID;
      //     var rowCreateTime = data.CreateTime;
      //     var cleansingEmail = this.email;

      //     if (cleansingEmail.length == 0) {
      //       this.gotoVerifyEmail();
      //       return;
      //     } else {
      //       var start = moment(rowCreateTime);
      //       var now = moment().toDate().getTime();
      //       var end = moment(start)
      //         .add(this.IdleTimeout, "minutes")
      //         .toDate()
      //         .getTime();

      //       if (rowEmail.length == 0) {
      //         return true;
      //       } else {
      //         var result = false;
      //         console.log(rowStatusID);
      //         switch (parseInt(rowStatusID, 10)) {
      //           case 0: //imposible
      //             result = true;
      //             break;
      //           case 1:
      //             if (rowEmail.length > 0 && cleansingEmail.length > 0) {
      //               if (
      //                 rowEmail.toLowerCase() == cleansingEmail.toLowerCase()
      //               ) {
      //                 result = true;
      //               } else {
      //                 result = false;
      //               }
      //               if (now > end) result = true;
      //             } else {
      //               result = true;
      //             }
      //             break;
      //           case 2:
      //             result = true;
      //             break;
      //         }
      //         return result;
      //       }
      //     }
      //   }
      // });
    },
    async _gotoCleansingV2(row) {
      this.params = this.getURLParams();

      var iquuid = await this.readLocalStorage("iquuid2", this.params.dbName);
      this.$store.commit("setPageBack", this.params);
      if (iquuid == null) {
        this.gotoVerifyEmail();
      } else {
        if (await this.canClick(row)) {
          const params = this.getURLParams();
          var item = { row: { TopicID: "" } };

          item.row.TopicID = params.TopicID;
          item.row.DisplayDate = row.DisplayDate;

          this.gotoCleansingV2(item);
        } else {
          this.isModalActive = true;
          this.selectRow = row;
        }
      }
    },

    _gotoReportPage() {
      let p = this.getURLParams();
      var a = ["/report", p.dbName, p.TopicID, p.periodName, p.periodID];
      this.$router.push(a.join("/")).catch((err) => {
        throw err;
      });
    },

    gotoInputDataPage() {
      this.gotoInputData();
    },

    gotoUploadDataPage() {
      this.gotoUploadData();
    },

    onPageChange(page) {
      this.page = page;
      this.getReport();
    },

    _sortMention(a, b, c) {
      a = parseInt(a.Count.split(",").join(""), 10);
      b = parseInt(b.Count.split(",").join(""), 10);
      if (c) {
        return a > b ? 1 : a < b ? -1 : 0;
      } else {
        return a < b ? 1 : a > b ? -1 : 0;
      }
    },

    _login() {
      this.gotoVerifyEmail();
    },
    _logout() {
      const params = this.getURLParams();
      this.deleteLocalStorage("iquuid2", params.dbName);
      this.deleteLocalStorage("iqgoauth2");
      this.$store.commit("setPageBack", params);

      this.writeLog("verifyemail", [
        {
          key: "originalURL",
          value: location.href,
        },
        { key: "remark", value: "logout" },
        { key: "email", value: this.email },
      ]);
      this.email = "";
      this.gotoVerifyEmail();
    },

    _gotoSummaryPage() {
      var b = this.getURLParams();
      var item = {
        row: { DatabaseName: b.dbName, ProfileID: b.TopicID },
      };

      this.gotoSummary(item);
    },

    onChangeTopic(v) {
      try {
        if (this.isLoading == false) {
          this.isLoading = true;
          if (this.oTopics[v]) {
            this.selectedTopicName = this.oTopics[v].QueryName;

            this.selectedTopicID = v;

            var a = this.getURLParams();

            var p =
              a.periodID !== null
                ? [a.page, a.dbName, v, a.periodName, a.periodID]
                : [a.page, a.dbName, v, a.periodName];

            p = "/" + p.join("/");

            this.goto(p, true);
            this.getReport();
          }
        }
      } catch (err) {
        throw err;
      }
    },

    async getInitialData() {
      this.params = this.getURLParams();
      this.params["isCleansing"] = 0;

      this.params["iquuid"] = this.readLocalStorage(
        "iquuid2",
        this.params.dbName
      );
      this.params.start = this.start;
      this.params.end = this.end;
      this.params["logType"] = "cleansingindex";
      this.email = await this.getUserEmail();
      this.params["BrowserID"] = this.getBrowserID();
      await this.httpRequest("Dashboard/getInitialData", this.params).then(
        () => {
          if (
            this.$result.data.periods !== null &&
            this.$result.data.periods.data !== undefined &&
            this.$result.data.periods.data.length > 0
          ) {
            var res = this.$result.data;

            this.oTopics =
              Object.keys(res.topics).length > 0
                ? res.topics.data[0].Query
                : {};
            if (this.params.TopicID.length == 6) {
              //from summary page
              var firstTopicID = Object.keys(this.oTopics)[0];
              if (
                this.params.TopicID.toLowerCase() ==
                firstTopicID.substr(0, 6).toLowerCase()
              ) {
                this.selectedTopicID = firstTopicID;
              }

              var p = [
                "/cleansingindex",
                this.params.dbName,
                this.selectedTopicID,
                this.params.periodName,
                this.params.periodID,
              ];
              this.goto(p.join("/"), true);
            } else {
              if (this.oTopics[this.params.TopicID] !== undefined) {
                this.selectedTopicName =
                  this.oTopics[this.params.TopicID].QueryName;
                this.selectedTopicID = this.params.TopicID;
              }
            }
          }
        }
      );
    },

    async getReport(start = "", end = "") {
       this.isLoading = true;
      this.params = this.getURLParams();
      var iquuid = this.readLocalStorage("iquuid2", this.params.dbName);
      this.$store.commit("setPageBack", this.params);
      if (iquuid == null) {
        this.gotoVerifyEmail();
      } else {
        if (start.length > 0) this.params.start = start;
        if (end.length > 0) this.params.end = end;

        this.params["isCleansing"] = 0;
        this.params["reportID"] = this.params["reportID"].substr(0, 6);
        this.params["idleTimeout"] = this.IdleTimeout;
        this.params["topicID"] = this.params["TopicID"];
        this.params["iquuid"] = this.readLocalStorage(
          "iquuid2",
          this.params.dbName
        );
        this.params["perPage"] = this.perPage;
        await this.httpRequest(
          "Dashboard/getCleansingIndex/" + this.page,
          this.params
        ).then(() => {
          if (this.$result.data.result == 200) {
            this.cleansingData = this.$result.data;
            this.cleansingData.selectedTopicName =
              this.oTopics[this.params["TopicID"]];

            this.total = this.$result.data.totalRows;
          } else {
            this.waitingText = this.$result.data.message;
          }
          this.isFoundData = this.$result.data.totalRows > 0 ? true : false;
          this.isLoading = false;
        });
      }
    },
  },

  mounted() {
    this.params = this.getURLParams();
  },
  async created() {
    this.isLogin = false;
    this.isLoading = true;
    await this.getServerConfig().then((config) => {
      this.$store.commit("setServerConfig", config);
      this.IdleTimeout = config.$idleTimeout;
      this.cleansingIndexRefreshDuration =
        config.$cleansingIndexRefreshDuration;
      this.hexkey = config.$HexKey;
      this.hexiv = config.$HexIV;
      this.eulaurl = config.EULAURL;
      this.contactusurl = config.ContactUsURL;
      this.companyurl = config.CompanyURL;
    });

    this.params = this.getURLParams();
    var iquuid = this.readLocalStorage("iquuid2", this.params.dbName);
    var BrowserID = this.getBrowserID();
    this.$store.commit("setPageBack", this.params);
    if (iquuid == null) {
      this.gotoVerifyEmail();
    } else {
      if (await this.isValidDatabase()) {
        await this.getInitialData();

        if (await this._isValidURL(this.params)) {
          this.params["pageBack"] = "cleansingindex";
          this.params["page"] = this.params["pageBack"];

          let res = await this.checkPermission(
            iquuid,
            BrowserID,
            this.params.page
          );

          var _continue = res.data.continue;
          var _goto = res.data.goto;
          var _clearUUID = res.data.clearUUID;

          if (_continue) {
            if (_goto == 401) {
              this.astate = 2;
              this.toast(
                "danger",
                "Restricted section. Only valid email can visit this page."
              );
            } else {
              this.astate = 1;
              this.getReport();
              this.gaPage();
            }
          } else {
            if (_goto == 401 && _clearUUID == true) {
              this.deleteLocalStorage("iquuid2", this.params.dbName);
            }
            if (_goto == "verifyemail") {
              this.gotoVerifyEmail();
            } else {
              this.astate = 2;
              this.toast(
                "danger",
                "Restricted section. Only valid email can visit this page."
              );
            }
          }
        } else {
          this.gotoNotFound(this.params.dbName);
        }
      } else {
        this.redirectHost(this.params);
      }
    }
  },
};
</script>
<style>
span.is-info {
  color: white !important;
  font-weight: normal;
}
.span.is-success,
.span.is-warning,
.span.is-info {
  font-weight: normal;
}

.content ul {
  list-style: none;
}
.content li + li,
ul.pagination-list {
  margin-top: 0px;
}
.table.is-striped tbody tr:not(.is-selected):nth-child(even) {
  background-color: #fffcf5;
}
</style>