<template>
  <div class="_wrapper">
    <b-loading
      :is-full-page="isFullPage"
      :active.sync="isLoading"
      :can-cancel="true"
    ></b-loading>

    <div class="card">
      <div class="card-content">
        <div class="content" style="margin-top: 10vh">
          <h1 style="color: green">ACTIVATED!</h1>

          <div class="columns" style="margin-top: 50px">
            <div class="column"></div>
            <div class="column is-half has-text-left">
              The email has been activated and you may now access our Service.
              Please go back to the page you have requested for the access and
              continue.
              <br />
              <br />
              You may close this tab or window.
            </div>
            <div class="column"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "PreparingSuccessContent",
  components: {},
  props: {
    waitingText: {
      type: String,
    },
    email: {
      type: String,
    },
  },

  data() {
    return {
      isFullPage: true,
      isLoading: false,
    };
  },

  created() {},
  watch: {
    waitingText() {},
  },
};
</script>
<style scoped>
.card-header-title {
  font-size: 1.5em !important;
}

.card-content {
  min-height: 75vh;
}
</style>
