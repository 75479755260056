<template>
  <div id="app" class="container container-fluid">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "app"
};
</script>
