<template>
  <div>
    <div class="_wrapper">
      <div class="card">
        <div class="card-content">
          <div class="content" style="text-align: center">
            <div class="is-size-0"></div>
            <div class="is-size-1">Unauthorized</div>

            <div class="pt-5">
              Sorry, you are not authorized to access this application.
            </div>
            <div>
              Please contact Dataxet Limited. at Tel. +66 (0) 2651 4700 or +66
              (0) 2253 5000 ext. 444..
            </div>
            <div class="pt-5">
              <!-- <b-button
                type="is-primary"
                @click.prevent="_goBack()"
                v-if="isButtonBackVisible == true"
                >Back</b-button
              > -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "unAuthorize",
  components: {},
  data() {
    return {
      isLogin: false,
      email: "",
      isButtonBackVisible: true,
    };
  },

  props: {},
  mounted() {
    this.gaPage();
  },
  async created() {
    this.isLogin = true;
    const params = this.getURLParams();
    var p = this.$store.state.pageBack[params.dbName];

    if (p !== undefined && Object.keys(p).length > 0) {
      this.isButtonBackVisible = true;
    } else {
      this.isButtonBackVisible = false;
    }
  },
  methods: {
    _logout() {
      const params = this.getURLParams();
      this.deleteLocalStorage("iquuid2", params.dbName);
      this.deleteLocalStorage("iqgoauth2");
      this.writeLog("verifyemail", [
        {
          key: "originalURL",
          value: location.href,
        },
        { key: "remark", value: "logout" },
        { key: "email", value: this.email },
      ]);

      this.email = "";
    },
    _goBack() {
      const params = this.getURLParams();

      if (this.$store.state.pageBack[params.dbName]["pageBack"] !== undefined) {
        var p = this.$store.state.pageBack[params.dbName];

        var a = [
          "/" + p.pageBack,
          p.dbName,
          p.reportID.toLowerCase(),
          p.start,
          p.end,
        ];

        this.goto(a.join("/"));
      }
    },
  },
};
</script>
<style scoped>
.card-header-title {
  font-size: 1.5em !important;
}

.card-content {
  min-height: 75vh;
}
</style>
