<template>
  <div>
    <b-navbar class="is-fixed-top">
      <template slot="brand">
        <b-navbar-item class="no-pointer">
          <img src="../assets/img/io_logo.png" :alt="$websiteDescription" />
        </b-navbar-item>
      </template>
      <template slot="start"> </template>

      <template slot="end"> </template>
    </b-navbar>
    <PreparingSuccessContent :email="email"></PreparingSuccessContent>
    <Footer
      v-if="isLoading == false"
      :eulaurl="eulaurl"
      :contactusurl="contactusurl"
      :companyurl="companyurl"
    ></Footer>
  </div>
</template>

<script>
import Footer from "../layout/Footer.vue";
import PreparingSuccessContent from "../contents/preparingSuccess";

export default {
  name: "preparingSuccessPage",
  components: {
    Footer,
    PreparingSuccessContent,
  },
  props: {},
  data() {
    return {
      email: "",
      eulaurl: "",
      contactusurl: "",
      companyurl: "",
    };
  },
  methods: {
    async getInitialData() {
      const params = this.getURLParams();
      params["isCleansing"] = 0;
      params["iquuid"] = this.readLocalStorage("iquuid2", params.dbName);
      params["logType"] = "preparing";
      this.email = await this.getUserEmail();
    },
  },
  mounted() {
    this.getInitialData();
  },
  async created() {
    await this.getServerConfig().then((config) => {
      this.eulaurl = config.EULAURL;
      this.companyurl = config.CompanyURL;
      this.contactusurl = config.ContactUsURL;
    });
  },
};
</script>
