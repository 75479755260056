<template>
  <div>
    <div class="card">
      <div slot="trigger" class="card-header" role="button">
        <div class="card-header-title">
          <div class="text-center w-100">
            {{ chartdata.title }}
          </div>
        </div>
      </div>
      <div class="card-content">
        <div class="content IQIO-table">
          <Hbarchart
            :chartData="oChartData"
            :options="oOptions"
            :height="height"
          ></Hbarchart>

          <div class="py-3"></div>

          <b-table
            :striped="true"
            :data="data == undefined ? [] : data"
            :columns="DataSource"
            :loading="loading"
            :paginated="paginated"
            backend-pagination
            :total="total"
            :per-page="perPage"
            @page-change="onPageChange"
            aria-next-label="Next page"
            aria-previous-label="Previous page"
            aria-page-label="Page"
            aria-current-label="Current page"
            aria-controls="__content"
          >
            <template slot="empty" v-if="isLoading == false">
              <section class="section">
                <div class="content has-text-grey">
                  <p class="has-text-centered">
                    <b-icon icon="emoticon-sad" size="is-large"></b-icon>
                  </p>
                  <p class="has-text-centered">No record found here.</p>
                </div>
              </section>
            </template>
          </b-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Hbarchart from "../../controls/ChartJS/Hbarchart";

export default {
  components: {
    Hbarchart,
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "Chart of ..",
    },
    chartdata: {
      type: Object,
      default: function () {
        return {};
      },
    },
    options: {
      type: Object,
      default: function () {
        return {};
      },
    },
    datasource: {
      type: Object,
      default: null,
    },
    height: {
      type: Number,
      default: 240,
    },
    keyComponent: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      data: [],
      total: 0,
      loading: false,
      sortField: "",
      sortOrder: "desc",
      defaultSortOrder: "desc",
      page: 1,
      perPage: 50,
      DataSource: [],
      paginated: false,
      oChartData: {},
      oOptions: {},
    };
  },

  watch: {
    chartdata() {
      this.loadAsyncData();
    },
    datasource() {
      this.transformDataSource();
    },
  },
  async mounted() {
    await this.transformDataSource();
    await this.loadAsyncData();
  },
  methods: {
    transformDataSource() {
      this.DataSource = [];
      var e = {
        field: "QueryName",
        label: "Topic",
        centered: false,
      };
      this.DataSource.push(e);

      for (var key in this.datasource) {
        e = {
          field: key,
          label: this.datasource[key].name,

          numeric: true,
        };
        this.DataSource.push(e);
      }
    },
    /*
     * Load async data
     */
    loadAsyncData() {
      if (
        this.chartdata !== undefined &&
        Object.keys(this.chartdata).length > 0
      ) {
        var res = this.getItems(this.chartdata);

        this.$emit("_getTable", { table: res });
        if (res !== undefined) {
          this.data = [];
          let currentTotal = res.length;
          if (res.length / this.perPage > 1000) {
            currentTotal = this.perPage * 1000;
          }
          this.total = currentTotal;
          if (this.total > this.perPage) {
            this.paginated = true;
          } else {
            this.paginated = false;
          }
          this.data = res;
        }

        if (this.chartdata !== null && this.chartdata !== undefined) {
          this.oChartData = {};
          this.oOptions = {};
          this.oChartData.labels = this.chartdata.labels;

          this.oChartData.labels = this.chartdata.labels;
          this.chartdata = this.getChartAddonOption(this.chartdata);
          this.oChartData = this.chartdata;
          this.oOptions = this.options;
        }
      }
    },
    /*
     * Handle page-change event
     */
    onPageChange(page) {
      this.page = page;
      this.loadAsyncData();
    },
    /*
     * Handle sort event
     */
    onSort(field, order) {
      this.sortField = field;
      this.sortOrder = order;
      this.loadAsyncData();
    },
    /*
     * Type style in relation to the value
     */
    type(value) {
      const number = parseFloat(value);
      if (number < 6) {
        return "is-danger";
      } else if (number >= 6 && number < 8) {
        return "is-warning";
      } else if (number >= 8) {
        return "is-success";
      }
    },
  },
};
</script>