<template>
  <div>
    <Navbar></Navbar>
    <Preparing></Preparing>
    <Footer
      v-if="isLoading == false"
      :eulaurl="eulaurl"
      :contactusurl="contactusurl"
      :companyurl="companyurl"
    ></Footer>
  </div>
</template>

<script>
import Navbar from "../layout/Navbar";
import Footer from "../layout/Footer.vue";
import Preparing from "../contents/preparing";
import moment from "moment";

export default {
  name: "preparingPage",
  components: {
    Navbar,
    Footer,
    Preparing,
  },
  data() {
    return {
      eulaurl: "",
      contactusurl: "",
      companyurl: "",
    };
  },
  methods: {
    async getInitialData() {
      const params = this.getURLParams();
      params["isCleansing"] = 0;
      params["iquuid"] = this.readLocalStorage("iquuid2", params.dbName);
      params["logType"] = "preparing";

      await this.httpRequest("Dashboard/getInitialData", params).then((res) => {
        if (
          this.$result.data.periods !== null &&
          this.$result.data.periods.data !== undefined &&
          this.$result.data.periods.data.length > 0
        ) {
          res = this.$result.data;

          this.oPeriod = res.periods;
          this.oTopics = res.topics.data[0].Query;

          params["TopicID"] = Object.keys(this.oTopics)[0];
          this.selectedTopicID = params["TopicID"];

          var a = [
            "/verifyemail",
            params["dbName"],
            params["TopicID"],
            params["start"],
            params["end"],
          ];

          if (res.periods.data.length > 0) {
            a = [
              "/verifyemail",
              params["dbName"],
              params["TopicID"],
              moment(res.periods.data[0]["start"])
                .startOf("month")
                .startOf("day")
                .format("YYYYMMDDHHmmss"),
              moment(res.periods.data[0]["start"])
                .endOf("month")
                .endOf("day")
                .format("YYYYMMDDHHmmss"),
            ];
          }
          a = a.join("/");
          setTimeout(() => {
            this.$router.replace(a);
          }, 1000);
        } else {
          this.toast("danger", "Invalid paramaters");
        }
      });
    },
  },
  mounted() {
    this.getInitialData();
  },
  async created() {
    await this.getServerConfig().then((config) => {
      this.eulaurl = config.EULAURL;
      this.companyurl = config.CompanyURL;
      this.contactusurl = config.ContactUsURL;
    });
  },
};
</script>
