<template>
  <div>
    <a name="toTop"></a>

    <b-navbar class="is-fixed-top">
      <template slot="brand">
        <b-navbar-item tag="router-link" :to="{ path: '' }">
          <a class="navbar-item" @click.prevent="_gotoSummaryPage()">
            <img src="../assets/img/io_logo.png" :alt="$websiteDescription" />
          </a>
        </b-navbar-item>
      </template>
      <template slot="start">
        <downloadexcel
          ref="myBtn"
          class="no-print hide"
          :name="CSVName"
          :fetch="_exportCSV"
          :fields="jsonFields"
          :before-generate="_startDownload"
          :before-finish="_finishDownload"
          type="csv"
        >
          <template>
            <b-icon icon="file-delimited"></b-icon>
            <span>Export CSV</span>
          </template>
        </downloadexcel>
        <div class="select-width" v-if="this.oTopics">
          <Selectbox
            :items="oTopics"
            :labelName="labelTopicName"
            :valueName="valueTopicName"
            :selected="selectedTopicID"
            :icon="iconTopic"
            :iconPack="iconPackTopic"
            v-model="selectedTopicID"
            @input="_onChangeTopic"
          ></Selectbox>
        </div>
      </template>

      <!-- <template slot="end">
        <b-navbar-dropdown :label="email">
          <b-navbar-item
            v-if="email !== undefined && email.length > 0"
            @click="_logout()"
            ><template
              ><b-icon icon="logout"></b-icon><span>Logout</span></template
            ></b-navbar-item
          >
          <b-navbar-item v-else @click="_login()"> Login </b-navbar-item>
        </b-navbar-dropdown>
      </template> -->
      <template slot="end">
          <div class="top-right-container">
            <div v-if="email !== undefined && email.length > 0" class="top-item">
              <div class="top-item">
                  <b-icon icon="person"></b-icon>
              </div>
              <div  class="top-item">{{email}}</div>
              <div class="bell-notification">
                <span class="bell-notification-child">
                  <Notification></Notification>
                  </span>
              </div>
              <div class="top-item icon-hover" @click="_logout()">
                <b-icon icon="logout" ></b-icon>
              </div>
            </div>
            <div v-else @click="_login()"> Login </div>
          </div>
      </template> 


    </b-navbar>

    <b-modal
      :active.sync="isCSVModalActive"
      :width="800"
      scroll="keep"
      custom-class="IQIO-Modal"
    >
      <div class="card">
        <header class="card-header">
          <p class="card-header-title">Exporting options</p>
        </header>
        <div class="card-content">
          <div class="content has-text-left">
            <div class="columns">
              <div class="column is-2">
                <span class="has-text-weight-bold">Profile Name</span>
              </div>
              <div class="column is-10">
                {{ this.oProfileName }}
              </div>
            </div>
          </div>
          <div class="buttons">
            <b-button type="is-primary">
              <downloadexcel
                class="no-print"
                :name="CSVName"
                :fetch="_exportCSV"
                :fields="jsonFields"
                :before-generate="_startDownload"
                :before-finish="_finishDownload"
                type="csv"
              >
                <h3>Export CSV</h3>
              </downloadexcel>
            </b-button>
          </div>
        </div>
      </div>
    </b-modal>

    <b-modal
      :active.sync="isExcelModalActive"
      :width="640"
      scroll="keep"
      custom-class="IQIO-Modal"
    >
      <form @submit.prevent="_sendEmail">
        <div class="card">
          <header class="card-header">
            <p class="card-header-title">Input e-mail</p>
          </header>
          <div class="card-content">
            <div class="content has-text-left">
              Please input only a valid e-mail, you wish to send the export
              notification e-mail to. The valid email must be comply with the
              following domains of ["infoquest.co.th", "iqnewsclip.com"] or in
              the given contact emails

              <b-field
                label="e-mail"
                :class="['column is-full', isEmailValid()]"
              ></b-field>
              <b-input
                type="email"
                ref="emailxlsx"
                v-model="emailxlsx"
                required
                icon="email"
                minlength="10"
                maxlength="50"
                placeholder="e-mail to receive exported excel file"
              ></b-input>
            </div>
            <div class="buttons">
              <b-button
                type="is-primary"
                :disabled="isValidEmail()"
                @click.prevent="_sendEmail"
                >Submit</b-button
              >
              <b-button
                type="is-warning"
                class="white"
                @click.prevent="_clearEmail()"
                >Clear</b-button
              >
            </div>
          </div>
        </div>
      </form>
    </b-modal>

    <div class="big">
      <!-- <div class="columns">
        <div class="column"> -->
      <ChartDoughnut1
        :chartData="oChartData.chart_1"
        :options="DataoptionsDoughnutBig"
        legendName="legend_1b"
        ref="chart_1b"
      ></ChartDoughnut1>
      <!-- </div>
      </div> -->
      <div class="columns">
        <div class="column">
          <Barchart
            :chartdata="oChartData.chart_2"
            :options="DataoptionsBig"
          ></Barchart>
        </div>
      </div>
      <!-- <div class="columns">
        <div class="column"> -->


      <ChartDoughnut1
        :chartData="oChartData.chart_3"
        :options="DataoptionsDoughnutBig"
        legendName="legend_3b"
        ref="chart_3b"
      ></ChartDoughnut1>



      <!-- </div>
      </div> -->
      <div class="columns">
        <div class="column">
          <Barchart
            :chartdata="this.oChartData.chart_4"
            :options="this.DataoptionsBig"
          ></Barchart>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <LineChart
            :borderWidth="this.borderWidthBig"
            :chartdata="this.oChartData.chart_5"
            :options="this.DataoptionsLineBig"
            :start="this.start"
            :end="this.end"
          ></LineChart>
        </div>
      </div>
    </div>

    <div class="_wrapper" v-if="astate == 1">
      <div class="columns is-multiline is-mobile">
        <div class="column is-9-desktop is-full-mobile has-text-left">
          <div class="header">
            <span>{{ selectedTopicName }}</span>
          </div>
        </div>
        <div
          class="column is-3-desktop is-full-mobile is-3-tablet has-text-right"
        >
          <daterangepicker @setSelectPeriod="_onChangePeriod"></daterangepicker>
        </div>
      </div>

      <div class="columns is-multiline is-mobile">
        <div class="column has-text-left is-6-mobile">
          <a href="#toMentions" class="anchor"
            ><div class="block pt-4">
              <b-icon icon="arrow-down-bold-circle-outline" size="is-small">
              </b-icon
              >Go to Mentions
            </div></a
          >
        </div>
        <div class="column is-6-mobile has-text-right">
          <div v-if="isFoundData == true">
            <b-dropdown aria-role="list">
              <button class="button is-primary" type="button" slot="trigger">
                <template>
                  <span>Export to...</span>
                </template>
                <b-icon icon="menu-down"></b-icon>
              </button>
              <div v-if="isFoundData == true">
                <b-dropdown-item :value="1" aria-role="listitem">
                  <div
                    class="media has-text-left"
                    @click.prevent="_exportDocument('pdf', 'portrait')"
                  >
                    <b-icon
                      class="media-left"
                      pack="far"
                      icon="file-pdf"
                    ></b-icon>
                    <div class="media-content has-text-left">
                      <h3>PDF (Portrait)</h3>
                      <small>Export to pdf file</small>
                    </div>
                  </div>
                </b-dropdown-item>

                <b-dropdown-item :value="2" aria-role="listitem">
                  <div
                    class="media has-text-left"
                    @click.prevent="_exportDocument('pdf', 'landscape')"
                  >
                    <b-icon
                      class="media-left"
                      pack="far"
                      icon="file-pdf"
                    ></b-icon>
                    <div class="media-content has-text-left">
                      <h3>PDF (Landscape)</h3>
                      <small>Export to pdf file</small>
                    </div>
                  </div>
                </b-dropdown-item>

                <b-dropdown-item :value="3" aria-role="listitem">
                  <div
                    class="media has-text-left"
                    @click.prevent="_openCSVDialog()"
                  >
                    <b-icon class="media-left" pack="mdi" icon="file"></b-icon>
                    <div class="media-content has-text-left">
                      <h3>CSV</h3>
                      <small>Export to csv file</small>
                    </div>
                  </div>
                </b-dropdown-item>

                <b-dropdown-item :value="4" aria-role="listitem">
                  <div
                    class="media has-text-left"
                    @click.prevent="_exportDocument('excel')"
                  >
                    <b-icon
                      class="media-left"
                      pack="far"
                      icon="file-excel"
                    ></b-icon>
                    <div class="media-content has-text-left">
                      <h3>Excel</h3>
                      <small>Export to xlsx file</small>
                    </div>
                  </div>
                </b-dropdown-item>
              </div>
            </b-dropdown>
          </div>
          <div v-else>
            <button
              class="button is-primary"
              type="button"
              slot="trigger"
              disabled="true"
            >
              <template>
                <span>Export to...</span>
              </template>
              <b-icon icon="menu-down"></b-icon>
            </button>
          </div>
        </div>
      </div>

      <div v-if="isFoundData == true">
        <div class="columns is-multiline stat-card">
          <div
            v-for="(item, index) in oChartData.statsCard"
            :value="item.id"
            :key="item.id"
            class="column is-full-mobile is-half-tablet is-3-desktop"
            @click.prevent="_getReportByDataSource(item)"
          >
            <div class="card">
              <div
                class="card-content"
                :style="
                  item.key == selectedStatCard
                    ? { 'background-color': '#ff9' }
                    : null
                "
              >
                <div class="content">
                  <div class="level">
                    <div class="level-item">
                      <div
                        class="sub-header"
                        v-if="item.title !== null && item.title !== undefined"
                      >
                        {{ item.title }}
                      </div>
                    </div>
                  </div>
                  <div class="level">
                    <div class="level-left">
                      <div class="level-item">
                        <b-icon
                          :pack="_getPack(item.icon)"
                          :icon="item.icon"
                          size="is-large"
                          :custom-class="_getIconColor(index)"
                        ></b-icon>
                      </div>
                    </div>
                    <div class="level-right">
                      <div class="level-item big-figure">
                        {{ item.value }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="normal">
          <div class="columns is-multiline">
            <div class="column is-full-tablet is-full-mobile is-5-desktop">
              <ChartDoughnut1
                :chartData="oChartData.chart_1"
                :options="DataoptionsDoughnut"
                legendName="legend_1"
                ref="chart_1"
                :isLoading="isLoading"
              ></ChartDoughnut1>
            </div>
            <div class="column is-full-tablet is-full-mobile is-7-desktop">
              <Barchart
                :chartdata="oChartData.chart_2"
                :options="Dataoptions"
                :isLoading="isLoading"
              ></Barchart>
            </div>
          </div>

          <div class="columns is-multiline">
            <div class="column is-full-tablet is-full-mobile is-5-desktop">
              <ChartDoughnut1
                :is="chartType.chartType_3"
                :chartData="oChartData.chart_3"
                :options="DataoptionsDoughnut"
                legendName="legend_3"
                ref="chart_3"
                :isLoading="isLoading"
              ></ChartDoughnut1>
            </div>
            <div class="column is-full-tablet is-full-mobile is-7-desktop">
              <Barchart
                :chartdata="oChartData.chart_4"
                :options="Dataoptions"
                :isLoading="isLoading"
              ></Barchart>
            </div>
          </div>

          <div class="columns">
            <div class="column is-full">
              <LineChart
                :chartdata="oChartData.chart_5"
                :options="DataoptionsLine"
                :isLoading="isLoading"
                :start="start"
                :end="end"
              ></LineChart>
            </div>
          </div>
          <a name="toMentions"></a>
          <div class="columns">
            <div class="column is-mobile">
              <p class="text-big">
                {{ selectedTopicName }}
                <br />
                {{ getStartEnd(this.start, this.end) }}
                ({{ number2Commas(total) }} mentions)
              </p>
            </div>
            <div class="column is-mobile has-text-right">
              <button
                class="button is-primary"
                type="button"
                slot="trigger"
                @click.prevent="_gotoCleansingIndex()"
              >
                <template>
                  <span>Review Data</span>
                </template>
              </button>

              <div class="legend-icon">
                <div class="legend-item">
                  <div class="iconBlackCircle">V</div>
                  PR Value (Baht)
                </div>
                <div class="legend-item">
                  <div class="iconBlackCircle">R</div>
                  Potential Reach
                </div>
              </div>
            </div>
          </div>

          <div class="columns">
            <div class="column is-mobile">
              <div class="card">
                <div class="card-content">
                  <div class="content">
                    <div class="content hide-first-row">
                      <b-table
                        id="table-data-cleansing"
                        :striped="true"
                        :data="data == undefined ? [] : data"
                        :paginated="paginated"
                        backend-pagination
                        :total="total"
                        :per-page="perPage"
                        :current-page.sync="currentPage"
                        @page-change="onPageChange"
                        aria-next-label="Next page"
                        aria-previous-label="Previous page"
                        aria-page-label="Page"
                        aria-current-label="Current page"
                        aria-controls="__content"
                      >
                        <template>
                          <b-table-column
                            field="RowID"
                            class="is-full"
                            v-slot="props"
                          >
                            <div class="has-text-left">
                              <div class="datasource-icon-sourcename">
                                <span
                                  class="datasource-icon"
                                  :style="{
                                    color: _getColor(
                                      props.row.DataSource,
                                      props.row.OriginalURL,
                                      props.row.SubType
                                    ),
                                  }"
                                >
                                  <i
                                    :class="
                                      _getIcon(
                                        props.row.DataSource,
                                        props.row.OriginalURL,
                                        props.row.SubType
                                      )
                                    "
                                  ></i>
                                </span>

                                <span
                                  class="source-name"
                                  :title="
                                    props.row.Location !== undefined &&
                                    props.row.Location !== null
                                      ? props.row.Location
                                      : ''
                                  "
                                  >{{ props.row.Location }}</span
                                >
                              </div>
                              <div class="display-time">
                                {{ props.row.DisplayTime }}
                              </div>
                            </div>
                          </b-table-column>

                          <b-table-column field="HeadLine" v-slot="props">
                            <div>
                              <div class="headline-1">
                                <a
                                  @click="
                                    openLink(
                                      props.row.RowID,
                                      props.row.OriginalURL
                                    )
                                  "
                                  rel="noreferrer noopener nofollow"
                              v-html="props.row.HeadLine"   ></a
                                >

                                <span
                                  v-if="
                                    props.row.NewsType.toLowerCase() ==
                                      'printmedia' ||
                                    props.row.NewsType.toLowerCase() ==
                                      'onlinemedia' ||
                                    props.row.NewsType.toLowerCase() ==
                                      'webclip'
                                  "
                                >
                                  <a
                                    @click="
                                      openLink(
                                        props.row.RowID,
                                        props.row.OriginalURLIconPDF
                                      )
                                    "
                                    rel="noreferrer noopener nofollow"
                                  >
                                    <i class="fa fa-file-pdf icon-danger"></i>
                                  </a>
                                </span>
                                <span v-else>
                                  <div v-if="props.row.NewsIdentifier !== null">
                                    <a
                                      @click="
                                        openLink(
                                          props.row.RowID,
                                          props.row.OriginalURLIconPDF
                                        )
                                      "
                                      rel="noreferrer noopener nofollow"
                                    >
                                      <i class="fa fa-file-pdf icon-danger"></i>
                                    </a>
                                  </div>
                                </span>
                              </div>
                              <div class="hit-sentence is-clipped">
                                <div v-if="props.row.HitSentence.length > 0">
                                  <a
                                    @click="
                                      openLink(
                                        props.row.RowID,
                                        props.row.OriginalURL
                                      )
                                    "
                                    v-html="props.row.HitSentence"
                                  ></a>
                                </div>
                                <div v-else-if="props.row.Story.length > 0">
                                  <a
                                    @click="
                                      openLink(
                                        props.row.RowID,
                                        props.row.OriginalURL
                                      )
                                    "
                                    v-html="props.row.Story"
                                  ></a>
                                </div>
                                <div v-else>[-]</div>
                              </div>
                            </div>
                          </b-table-column>
                          <b-table-column field="PRValue" v-slot="props">
                            <div class="pr-value-right text-nowrap">
                              <div class="columns">
                                <div
                                  class="
                                    column
                                    has-text-right
                                    is-3-tablet is-6-mobile
                                  "
                                >
                                  <div class="iconBlackCircle">V</div>
                                </div>
                                <div class="column is-9-tablet is-6-mobile">
                                  {{ props.row.PRValue }}
                                </div>
                              </div>
                              <div class="columns">
                                <div
                                  class="
                                    column
                                    has-text-right
                                    is-3-tablet is-6-mobile
                                  "
                                >
                                  <div class="iconBlackCircle">R</div>
                                </div>

                                <div
                                  class="column is-9-tablet is-6-mobile"
                                  v-if="props.row.PotentialReach.length == 0"
                                >
                                  N/A
                                </div>
                                <div class="column" v-else>
                                  {{ props.row.PotentialReach }}
                                </div>
                              </div>
                            </div>
                          </b-table-column>
                        </template>
                        <template slot="empty" v-if="isLoading == false">
                          <section class="section">
                            <div class="content has-text-grey">
                              <p class="has-text-centered">
                                <b-icon
                                  icon="emoticon-sad"
                                  size="is-large"
                                ></b-icon>
                              </p>
                              <p class="has-text-centered">
                                No record found here.
                              </p>
                            </div>
                          </section>
                        </template>
                      </b-table>
                      <div align="right" class="m-t-10">
                        <a href="#toTop" class="anchor"
                          ><div class="block">
                            <b-icon
                              icon="arrow-up-bold-circle-outline"
                              size="is-small"
                            >
                            </b-icon
                            >Go to Top
                          </div></a
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-else>
        <NotFound
          v-if="isLoading == false"
          :startEnd="getStartEnd(this.start, this.end)"
        ></NotFound>
      </div>

      <Footer
        v-if="isLoading == false"
        :eulaurl="eulaurl"
        :contactusurl="contactusurl"
        :companyurl="companyurl"
      ></Footer>
    </div>

    <div class="_wrapper" v-if="isLoading == true">
      <b-loading
        :is-full-page="isFullPage"
        :active.sync="isLoading"
        :can-cancel="true"
      ></b-loading>
      <Preparing :waitingText="this.waitingText"></Preparing>
    </div>
    <div class="_wrapper" v-else-if="astate == 2">
      <NotAuthorizeEditor></NotAuthorizeEditor>
    </div>
  </div>
</template>

<script>
import pdfFonts from "pdfmake/build/vfs_fonts";
import Footer from "../layout/Footer";
import NotFound from "../contents/notfound";
import Preparing from "../contents/preparing";
import Selectbox from "../controls/Selectbox";
import moment from "moment";
import ChartDoughnut1 from "../components/ChartJS/ChartDoughnut1";
import Barchart from "../components/ChartJS/Barchart";
import LineChart from "../components/ChartJS/Linechart";
// import aes from "crypto-js/aes";
// import encHex from "crypto-js/enc-hex";
// import padZeroPadding from "crypto-js/pad-zeropadding";

import "chartjs-plugin-datalabels";
import "chartjs-plugin-piechart-outlabels";
import downloadexcel from "vue-json-excel";
import daterangepicker from "../components/DateRangePicker";
import NotAuthorizeEditor from "../pages/NotAuthorizeEditor";
import Notification from "../controls/Notification";

var _ = require("lodash");

export default {
  name: "reportPage",
  components: {
    Footer,
    NotFound,
    Preparing,
    Selectbox,
    downloadexcel,
    ChartDoughnut1,
    Barchart,
    LineChart,
    daterangepicker,
    NotAuthorizeEditor,
    Notification,
  },
  props: {},
  data() {
    return {
      addColumn: null,
      eulaurl: "",
      contactusurl: "",
      companyurl: "",
      start: "",
      end: "",
      astate: 0,
      dateFormat: "DD MMMM YYYY",
      excelFile: { name: "" },
      selectedStatCard: "",
      disabled: true,
      labelFields: {},
      data: [],
      page: 1,
      currentPage: 1,
      paginated: true,
      perPage: 50,
      total: 0,
      borderWidthBig: 12,

      DataoptionsLine: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: true,
          position: "bottom",
          labels: {
            fontFamily: "Bai Jamjuree",
            fontSize: 15,
            fontColor: "#000",
          },
          onHover: function (e) {
            e.target.style.cursor = "pointer";
          },
        },
        animation: {
          duration: 0,
        },
        hovover: {
          animationDuration: 0,
        },
        responsiveAnimationDuration: 0,

        scales: {
          yAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: "Mentions",
                fontFamily: "Bai Jamjuree",
                fontSize: 15,
                fontColor: "#000",
                fontStyle: "bold",
              },
              type: "linear", // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
              display: true,
              position: "left",
              id: "y-axis-1",

              ticks: {
                fontFamily: "Bai Jamjuree",
                fontSize: 15,
                fontColor: "#000",
                beginAtZero: true,
                precision: 0,
                userCallback: function (value) {
                  // Convert the number to a string and splite the string every 3 charaters from the end
                  if (value >= 1000000) {
                    value = value / 1000000;
                    value = value.toString() + "M";
                  } else if (value >= 1000) {
                    value = value / 1000;
                    value = value.toString() + "K";
                  } else {
                    value = value.toString();
                    value = value.split(/(?=(?:...)*$)/);
                    value = value.join(",");
                  }

                  return value;
                },
              },
            },
            {
              scaleLabel: {
                display: true,
                labelString: "PR Value (Baht)",
                fontFamily: "Bai Jamjuree",
                fontSize: 15,
                fontColor: "#000",
                fontStyle: "bold",
              },
              type: "linear", // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
              display: true,
              position: "right",
              id: "y-axis-2",

              // grid line settings
              gridLines: {
                drawOnChartArea: false,
                color: "#ccc",
              },

              ticks: {
                fontFamily: "Bai Jamjuree",
                fontSize: 15,
                fontColor: "#000",

                beginAtZero: true,
                userCallback: function (value) {
                  // Convert the number to a string and splite the string every 3 charaters from the end
                  if (value >= 1000000) {
                    value = value / 1000000;
                    value = value.toString() + "M";
                  } else if (value >= 1000) {
                    value = value / 1000;
                    value = value.toString() + "K";
                  } else {
                    value = value.toString();
                    value = value.split(/(?=(?:...)*$)/);
                    value = value.join(",");
                  }
                  return value;
                },
              },
            },
          ],
          xAxes: [
            {
              ticks: {
                beginAtZero: true,
                fontFamily: "Bai Jamjuree",
                fontSize: 12,
                fontColor: "#000",
                callback: function (value, i, chart) {
                  if (i == 0 || i == chart.length - 1) {
                    return "";
                  } else {
                    return moment(value).format("D MMM");
                  }
                },
              },
              type: "time",

              time: {
                unit: "day",
                unitStepSize: 1,
                displayFormats: {
                  day: "Y-M-D",
                },
                tooltipFormat: "ll",
              },
              scaleLabel: {
                display: true,
                labelString: "Date",
                fontFamily: "Bai Jamjuree",
                fontSize: 15,
                fontColor: "#000",
              },
            },
          ],
        },
        tooltips: {
          callbacks: {
            label: function (tooltipItem) {
              var value = tooltipItem.yLabel;

              value = value.toString();
              value = value.split(/(?=(?:...)*$)/);
              value = value.join(",");
              return value;
            },
          },
        },
        plugins: {
          datalabels: {
            display: false,
          },
        },
      },

      DataoptionsLineBig: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: true,
          position: "bottom",
          labels: {
            fontFamily: "Bai Jamjuree",
            fontSize: 15 * 3,
            fontColor: "#000",
            boxWidth: 200,
            padding: 30,
          },
          onHover: function (e) {
            e.target.style.cursor = "pointer";
          },
        },
        animation: {
          duration: 0,
        },
        hovover: {
          animationDuration: 0,
        },
        responsiveAnimationDuration: 0,

        scales: {
          yAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: "Mentions",
                fontFamily: "Bai Jamjuree",
                fontSize: 15 * 3,
                fontColor: "#000",
                fontStyle: "bold",
              },
              type: "linear", // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
              display: true,
              position: "left",
              id: "y-axis-1",
              gridLines: {
                display: true,
                lineWidth: 5,
              },
              ticks: {
                fontFamily: "Bai Jamjuree",
                fontSize: 15 * 3,
                fontColor: "#000",
                beginAtZero: true,
                precision: 0,
                userCallback: function (value) {
                  // Convert the number to a string and splite the string every 3 charaters from the end
                  if (value >= 1000000) {
                    value = value / 1000000;
                    value = value.toString() + "M";
                  } else if (value >= 1000) {
                    value = value / 1000;
                    value = value.toString() + "K";
                  } else {
                    value = value.toString();
                    value = value.split(/(?=(?:...)*$)/);
                    value = value.join(",");
                  }

                  return value;
                },
              },
            },
            {
              scaleLabel: {
                display: true,
                labelString: "PR Value (Baht)",
                fontFamily: "Bai Jamjuree",
                fontSize: 15 * 3,
                fontColor: "#000",
                fontStyle: "bold",
              },
              type: "linear", // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
              display: true,
              position: "right",
              id: "y-axis-2",
              gridLines: {
                display: false,
                lineWidth: 5,
              },
              ticks: {
                fontFamily: "Bai Jamjuree",
                fontSize: 15 * 3,
                fontColor: "#000",

                beginAtZero: true,
                userCallback: function (value) {
                  // Convert the number to a string and splite the string every 3 charaters from the end
                  if (value >= 1000000) {
                    value = value / 1000000;
                    value = value.toString() + "M";
                  } else if (value >= 1000) {
                    value = value / 1000;
                    value = value.toString() + "K";
                  } else {
                    value = value.toString();
                    value = value.split(/(?=(?:...)*$)/);
                    value = value.join(",");
                  }
                  return value;
                },
              },
            },
          ],
          xAxes: [
            {
              ticks: {
                beginAtZero: true,
                fontFamily: "Bai Jamjuree",
                fontSize: 15 * 2.5,
                fontColor: "#000",
                padding: 30,
                callback: function (value, i, chart) {
                  if (i == 0 || i == chart.length - 1) {
                    return "";
                  } else {
                    return moment(value).format("D MMM");
                  }
                },
              },
              gridLines: {
                display: true,
                lineWidth: 5,
              },
              type: "time",
              time: {
                unit: "day",
                unitStepSize: 1,
                displayFormats: {
                  day: "Y-M-D",
                },
                tooltipFormat: "ll",
              },
              scaleLabel: {
                display: true,
                labelString: "Date",
                fontFamily: "Bai Jamjuree",
                fontSize: 15 * 3,
                fontColor: "#000",
              },
            },
          ],
        },
        tooltips: {
          callbacks: {
            label: function (tooltipItem) {
              var value = tooltipItem.yLabel;

              value = value.toString();
              value = value.split(/(?=(?:...)*$)/);
              value = value.join(",");
              return value;
            },
          },
        },
        plugins: {
          datalabels: {
            display: false,
          },
        },
      },

      DataoptionsDoughnut: {
        layout: {
          padding: {
            left: 100,
            right: 100,
            top: 100,
            bottom: 100,
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        animation: {
          animationDuration: 0,
        },
        hover: {
          animationDuration: 0,
        },

        responsiveAnimationDuration: 0,
        segmentShowStroke: false,
        rotation: -3.1415926,
        cutoutPercentage: 50,

        legend: {
          fontFamily: "Bai Jamjuree",
          display: false,
          position: "bottom",

          labels: {
            marginTop: 50,
          },
        },

        tooltips: {
          callbacks: {
            // this callback is used to create the tooltip label
            label: function (tooltipItem, data) {

              // get the data label and data value to display
              // convert the data value to local string so it uses a comma seperated number
              var dataLabel = data.labels[tooltipItem.index];
              var value =
                ": " +
                data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

              // make this isn't a multi-line label (e.g. [["label 1 - line 1, "line 2, ], [etc...]])
              if (dataLabel.count > 0) {
                // show value on first line of multiline label
                // need to clone because we are changing the value
                dataLabel = dataLabel.slice();
                dataLabel[0] += value;
              } else {
                dataLabel += value;
              }

              // return the text to display on the tooltip
              return dataLabel;
            },
            // afterLabel: function(tooltipItem, data){
            //   console.log("afterLabel",tooltipItem);
            //   console.log("afterLabel",data);
            // }
          },
        },

        plugins: {
          labels: false,
          datalabels: {
            display: false,
            // formatter: function (value) {
            //     if (value > 1000000) {
            //         if (value % 1000000 == 0) {
            //             value = value / 1000000;
            //         } else {
            //             value = (value / 1000000).toFixed(2);
            //         }

            //         return (
            //             value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") +
            //             "M"
            //         );
            //     } else if (value > 1000) {
            //         if (value % 1000 == 0) {
            //             value = value / 1000;
            //         } else {
            //             value = (value / 1000).toFixed(2);
            //         }

            //         return (
            //             value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") +
            //             "K"
            //         );
            //     } else if (value == 0) {
            //         return "";
            //     } else {
            //         return value
            //             .toString()
            //             .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
            //     }
            // },
          },
          outlabels: {
            text: function (item) {
              // var value = Math.abs(Number(x)) >= 1000000000 ? Math.abs(Number(x)) / 1000000000 + 'B' : Math.abs(Number(x)) >= 1000000 ? Math.abs(Number(x)) / 1000000 + 'M' : Math.abs(Number(x)) >= 1000 ? Math.abs(Number(x)) / 1000 : Math.abs(Number(x));
              // return "%v \n (%p)";
              let value = item.dataset.data[item.dataIndex];
              //console.log("value",value);
              let strValue = "";
                if (value > 1000000) {
                    if (value % 1000000 == 0) {
                        value = (value / 1000000).toString();
                    } else {
                        value = (value / 1000000).toFixed(2);
                    }
                    
                    strValue = value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") + "M";
                    //console.log("case M", strValue)
                } else if (value > 1000) {
                    if (value % 1000 == 0) {
                        value = value / 1000;
                    } else {
                        value = (value / 1000).toFixed(2);
                    }
                    strValue = value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") + "K";
                    //console.log("case K", strValue)
                } else if (value == 0) {
                    strValue = "";
                    //console.log("case 0", strValue)
                } else {
                    strValue = value
                                .toString()
                                .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
                                //console.log("case else", strValue)
                }              
                
              return strValue + " \n (%p)";
            },
            display: function (context) {           
              var index = context.dataIndex;
              // var x = context.dataset.data[index];
              // var value = Math.abs(Number(x)) >= 1000000000 ? Math.abs(Number(x)) / 1000000000 + 'B' : Math.abs(Number(x)) >= 1000000 ? Math.abs(Number(x)) / 1000000 + 'M' : Math.abs(Number(x)) >= 1000 ? Math.abs(Number(x)) / 1000 : Math.abs(Number(x));
              var value = context.dataset.data[index]; 
              return value == 0 ? false : true;
            },
            borderRadius: 5, // Border radius of Label
            circular: true,
            color: "black", // Font color
            textAlign: "center",
            lineWidth: 2, // Thickness of line between chart arc and Label
            padding: 8,
            stretch: 30,
            percentPrecision: 2,
            font: {
              resizable: true,
              minSize: 12,
              maxSize: 13,
              family: "Bai Jamjuree",
            },
          },
        },
      },

      DataoptionsDoughnutBig: {
        layout: {
          padding: {
            left: 380,
            right: 380,
            top: 190,
            bottom: 0,
          },
        },
        responsive: true,
        maintainAspectRatio: true,
        animation: {
          animationDuration: 0,
        },

        responsiveAnimationDuration: 0,
        segmentShowStroke: false,
        rotation: -3.1415926,
        cutoutPercentage: 50,

        legend: {
          display: true,
          position: "bottom",
          align: "start",

          labels: {
            fontFamily: "Bai Jamjuree",
            fontSize: 36,
            fontColor: "#000",
            boxWidth: 80,
          },
        },

        tooltips: { enabled: false },
        hover: { mode: null },
        plugins: {
          datalabels: {
            display: false,
          },
          outlabels: {
            text: function (item) {
              // var value = Math.abs(Number(x)) >= 1000000000 ? Math.abs(Number(x)) / 1000000000 + 'B' : Math.abs(Number(x)) >= 1000000 ? Math.abs(Number(x)) / 1000000 + 'M' : Math.abs(Number(x)) >= 1000 ? Math.abs(Number(x)) / 1000 : Math.abs(Number(x));
              // return "%v \n (%p)";
              //console.log(item);

              let value = item.dataset.data[item.dataIndex];
              //console.log("value",value);
              let strValue = "";
                if (value > 1000000) {
                    if (value % 1000000 == 0) {
                        value = (value / 1000000).toString();
                    } else {
                        value = (value / 1000000).toFixed(2);
                    }
                    
                    strValue = value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") + "M";
                    //console.log("case M", strValue)
                } else if (value > 1000) {
                    if (value % 1000 == 0) {
                        value = value / 1000;
                    } else {
                        value = (value / 1000).toFixed(2);
                    }
                    strValue = value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") + "K";
                    //console.log("case K", strValue)
                } else if (value == 0) {
                    strValue = "";
                    //console.log("case 0", strValue)
                } else {
                    strValue = value
                                .toString()
                                .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
                                //console.log("case else", strValue)
                }              
                
              return strValue + " \n (%p)";
            },
            display: function (context) {
              var index = context.dataIndex;
              var value = context.dataset.data[index];
              return value == 0 ? false : true;
            },
            borderRadius: 5, // Border radius of Label
            circular: true,
            color: "black", // Font color
            textAlign: "center",
            lineWidth: 7, // Thickness of line between chart arc and Label
            padding: 8,
            stretch: 70,
            percentPrecision: 2,
            font: {
              resizable: true,
              minSize: 12,
              maxSize: 33,
              family: "Bai Jamjuree",
            },
          },
        },
      },

      Dataoptions: {
        responsive: true,
        maintainAspectRatio: false,
        animation: {
          duration: 0,
        },
        hover: {
          animationDuration: 0,
        },
        responsiveAnimationDuration: 0,
        scales: {
          xAxes: [
            {
              stacked: false,
              ticks: {
                fontFamily: "Bai Jamjuree",
                fontSize: 14,
                fontColor: "#000",
                beginAtZero: true,
                userCallback: function (value) {
                  // Convert the number to a string and splite the string every 3 charaters from the end
                  if (value >= 1000000) {
                    value = value / 1000000;
                    value = value.toString() + "M";
                  } else if (value >= 1000) {
                    value = value / 1000;
                    value = value.toString() + "K";
                  } else {
                    value = value.toString();
                    value = value.split(/(?=(?:...)*$)/);
                    value = value.join(",");
                    if (value % 1 !== 0) {
                      value = "";
                    }
                  }
                  return value;
                },
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                fontFamily: "Bai Jamjuree",
                fontSize: 14,
                fontColor: "#000",
                beginAtZero: true,
              },
            },
          ],
        },

        legend: {
          fontFamily: "Bai Jamjuree",
          display: false,
        },
        tooltips: {
          callbacks: {
            title: function (tooltipItem, data) {
              if (data) return data.labels[tooltipItem[0].index];
            },
            label: function (tooltipItem) {
              var value = tooltipItem.xLabel;

              value = value.toString();
              value = value.split(/(?=(?:...)*$)/);
              value = value.join(",");
              return value;
            },
          },
        },
        plugins: {
          datalabels: {
            display: true,
            color: "black",
            anchor: "end",

            font: function () {
              return {
                size: 14,
                family: "Bai Jamjuree",
              };
            },
            formatter: function (value) {
              if (value >= 1000000) {
                if (value % 1000000 == 0) {
                  value = value / 1000000;
                } else {
                  value = (value / 1000000).toFixed(2);
                }

                return (
                  value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") +
                  "M"
                );
              } else if (value >= 1000) {
                if (value % 1000 == 0) {
                  value = value / 1000;
                } else {
                  value = (value / 1000).toFixed(2);
                }

                return (
                  value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") +
                  "K"
                );
              } else if (value == 0) {
                return "";
              } else {
                return value
                  .toString()
                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
              }
            },
            align: function (context) {
              var max = 0;
              context.dataset.data.map(function (v) {
                if (parseFloat(v) > max) max = v;
              });

              if (self.stacked) {
                return "middle";
              } else {
                if (context.dataset.data[context.dataIndex] / max >= 0.9) {
                  return "start";
                } else {
                  return "end";
                }
              }
            },
            backgroundColor: function (context) {
              var max = 0;
              context.dataset.data.map(function (v) {
                if (parseFloat(v) > max) max = v;
              });

              if (self.stacked) {
                return "rgba(255, 255, 255, 0)";
              } else {
                if (context.dataset.data[context.dataIndex] / max >= 0.9) {
                  return "rgba(255, 255, 255, 0.6)";
                } else {
                  return "rgba(255, 255, 255, 0)";
                }
              }
            },
            offset: 1,
          },
        },
      },

      DataoptionsBig: {
        responsive: true,
        maintainAspectRatio: false,
        animation: {
          duration: 0,
        },
        hover: {
          animationDuration: 0,
        },
        responsiveAnimationDuration: 0,
        scales: {
          xAxes: [
            {
              gridLines: {
                display: true,
                lineWidth: 5,
              },
              stacked: false,
              ticks: {
                fontFamily: "Bai Jamjuree",
                fontSize: 14 * 5,
                fontColor: "#000",
                beginAtZero: true,
                lineWidth: 10,
                userCallback: function (value) {
                  // Convert the number to a string and splite the string every 3 charaters from the end
                  if (value >= 1000000) {
                    value = value / 1000000;
                    value = value.toString() + "M";
                  } else if (value >= 1000) {
                    value = value / 1000;
                    value = value.toString() + "K";
                  } else {
                    value = value.toString();
                    value = value.split(/(?=(?:...)*$)/);
                    value = value.join(",");
                    if (value % 1 !== 0) {
                      value = "";
                    }
                  }
                  return value;
                },
              },
            },
          ],
          yAxes: [
            {
              gridLines: {
                display: true,
                lineWidth: 5,
              },
              ticks: {
                fontFamily: "Bai Jamjuree",
                fontSize: 14 * 5,
                fontColor: "#000",
                beginAtZero: true,
              },
            },
          ],
        },

        legend: {
          fontFamily: "Bai Jamjuree",
          display: false,
        },
        tooltips: {
          callbacks: {
            title: function (tooltipItem, data) {
              if (data) return data.labels[tooltipItem[0].index];
            },
            label: function (tooltipItem) {
              var value = tooltipItem.xLabel;

              value = value.toString();
              value = value.split(/(?=(?:...)*$)/);
              value = value.join(",");
              return value;
            },
          },
        },
        plugins: {
          datalabels: {
            display: true,
            color: "black",
            anchor: "end",

            font: function () {
              return {
                size: 14 * 5,
                family: "Bai Jamjuree",
              };
            },
            formatter: function (value) {
              if (value >= 1000000) {
                if (value % 1000000 == 0) {
                  value = value / 1000000;
                } else {
                  value = (value / 1000000).toFixed(2);
                }

                return (
                  value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") +
                  "M"
                );
              } else if (value >= 1000) {
                if (value % 1000 == 0) {
                  value = value / 1000;
                } else {
                  value = (value / 1000).toFixed(2);
                }

                return (
                  value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") +
                  "K"
                );
              } else if (value == 0) {
                return "";
              } else {
                return value
                  .toString()
                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
              }
            },
            align: function (context) {
              var max = 0;
              context.dataset.data.map(function (v) {
                if (parseFloat(v) > max) max = v;
              });

              if (self.stacked) {
                return "middle";
              } else {
                if (context.dataset.data[context.dataIndex] / max >= 0.9) {
                  return "start";
                } else {
                  return "end";
                }
              }
            },
            backgroundColor: function (context) {
              var max = 0;
              context.dataset.data.map(function (v) {
                if (parseFloat(v) > max) max = v;
              });

              if (self.stacked) {
                return "rgba(255, 255, 255, 0)";
              } else {
                if (context.dataset.data[context.dataIndex] / max >= 0.9) {
                  return "rgba(255, 255, 255, 0.6)";
                } else {
                  return "rgba(255, 255, 255, 0)";
                }
              }
            },
            offset: 1,
          },
        },
      },

      chartType: {
        chartType_1: "chart-doughnut1",
        chartType_3: "chart-doughnut1",
      },

      CSVName: "",
      jsonFields: {
        Topic: "Topic",
        DataSource: "Type",
        SubType: "SubType",
        SourceName: "SourceName",
        DisplayDate1: "DisplayDate",
        HeadLine: "Headline",
        PRValue: "PRValue",
        PotentialReach: "PotentialReach",
        Page: "Page",
        Section: "Section",
        ClipType: "ClipType",
        Circulation: "Circulation",
        AdRate: "AdRate",
        ColInch: "ArticleSize",
        AdValue: "AdValue",
      },
      waitingText: "Preparing data, please wait...",
      email: "",
      emailxlsx: "",
      isLogin: false,

      DataSource: "",

      isFoundData: "",
      isLoading: false,
      isFullPage: true,

      oChartData: {},

      chartData: {},
      oTopics: {},
      oProfileName: {},
      oProfile: {},

      config: {},
      isExcelModalActive: false,
      isCSVModalActive: false,

      labelTopicName: "QueryName",
      valueTopicName: "TopicID",
      selectedTopicID: null,
      selectedTopicName: null,
      iconTopic: "cube",
      iconPackTopic: "fas",
    };
  },

  watch: {
    oTopics() {
      if (this.oTopics[this.selectedTopicID]) {
        this.selectedTopicName = this.oTopics[this.selectedTopicID].QueryName;
      }
    },
    oProfile() {
      this._getLabelFields();
    },
    oChartData() {
      this.loadAsyncData();
    },
  },

  methods: {
    _checkPermissableAddColumn() {
      var res = [];

      if (this.addColumn !== null && this.addColumn !== undefined) {
        if (Object.keys(this.config.permissableAddColumn).length > 0) {
          var cols = this.addColumn.split(",");
          var comp = [];
          for (var j in this.config.permissableAddColumn) {
            comp.push(j);
          }

          comp = this.intersect(cols, comp);
          if (comp.length > 0) {
            res.push(0);
          } else {
            res.push(1);
          }
        } else {
          res.push(1);
        }
      } else {
        res.push(0);
      }

      var sum = 0;
      for (var i in res) {
        sum += res[i];
      }

      return sum == 0 ? true : false;
    },

    async _isValidURL(params) {
      var a =
        this.oProfile !== undefined && Object.keys(this.oProfile).length > 0
          ? true
          : false;

      var b = this.isValidPeriod(params);
      b = b.result;

      var c = false;

      if (params.TopicID.length == 10) {
        var cutLength = params.TopicID.length;

        for (var i in this.oTopics) {
          if (this.oTopics[i].TopicID.substr(0, cutLength) == params.TopicID) {
            c = true;
            break;
          }
        }
      }

      var res = a && b && c;
      return res;
    },
    async _onChangePeriod(params) {
      try {
        if (this.isLoading == false) {
          this.isLoading = true;
          this.params = this.getURLParams();

          var a;
          a = [
            this.params.page,
            this.params.dbName,
            this.params.reportID.toLowerCase(),
            params.periodName,
          ];

          if (params.periodID.length > 0) {
            a.push(params.periodID);
          }

          this.$router.push("/" + a.join("/")).catch((err) => {
            throw err;
          });
          await this.getReport(this.DataSource);
        }
        this.isLoading = false;
      } catch (err) {
        throw err;
      }
    },

    async _getTop20PRValue(orientation) {
      this.isLoading = true;
      var p = this.getURLParams();

      p["order_by"] = "PRValue desc, DisplayTime asc, SourceName asc";
      p["limit"] = 20;
      p["MentionID"] = [1, 2];
      p["start"] = this.start;
      p["end"] = this.end;
      await this.httpRequest("Dashboard/getDataTable/0", p)
        .then(() => {
          var res = this.$result.data;
          return this._makePDFTableArray(res);
        })
        .then((res) => {
          this._exportPDF(res, orientation);
        })
        .catch((error) => {
          throw error;
        });
    },

    _gotoCleansingIndex() {
      var params = this.getURLParams();
      var p = [];

      p = [
        "cleansingindex",
        params.dbName,
        params.TopicID,
        "monthly",
        "latest",
      ];

      p = "/" + p.join("/");

      this.goto(p);
    },

    async loadAsyncData() {
      const params = this.getURLParams();
      params["isCleansing"] = 0;
      params["DataSource"] = this.DataSource;
      params["MentionID"] = [1, 2];
      params["order_by"] = "DisplayTime desc";
      params["start"] = this.start;
      params["end"] = this.end;
      await this.httpRequest("Dashboard/getDataTable/" + this.page, params)
        .then((res) => {
          res = this.$result.data;

          this.data = [];
          this.total = res.totalRows;
          this.data = res.items;
        })
        .then(() => {
          this._getLabelFields();
        })
        .catch((error) => {
          this.data = [];
          this.total = 0;
          throw error;
        });
    },

    _getIcon(c, url, subtype) {
      return this.getIcon(c, url, subtype);
    },

    _getColor(c, url, subtype) {
      return this.getColor(c, url, subtype);
    },

    _getIconColor(item) {
      return this.getIconColor(item);
    },
    _getPack(icon) {
      return icon.split(" ")[0];
    },

    async _exportDocument(choice, orientation = "portrait") {
      this.params = this.getURLParams();
      var iquuid = await this.readLocalStorage("iquuid2", this.params.dbName);
      this.$store.commit("setPageBack", this.params);
      if (iquuid == null) {
        this.gotoVerifyEmail();
      } else {
        switch (choice) {
          case "pdf":
            await this._getTop20PRValue(orientation);
            break;
          case "excel":
            await this._exportExcel();
            break;
        }
      }
    },

    async _exportExcel() {
      this.emailxlsx = await this.getUserEmail();
      this.isExcelModalActive = true;
    },

    async printPDF(content, fileName, option) {
      var orientation = option.orientation || "landscape";
      var pdfMake = require("pdfmake/build/pdfmake.js");
      pdfMake.vfs = pdfFonts.pdfMake.vfs;

      //ต้องระบุตามชื่อของ ไฟล์ font
      pdfMake.fonts = this.$pdfMakeFonts;
      var docDefinition = {
        pageOrientation: orientation,
        pageMargins: [0, 60, 0, 60],
        content: content,
        styles: {
          header: {
            fontSize: 16,
            bold: true,
            margin: [0, 0, 0, 10],
          },
          subheader: {
            fontSize: 14,
            bold: true,
            margin: [0, 10, 0, 5],
          },
          tableExample: {
            margin: [0, 5, 0, 15],
          },

          tableHeader: {
            bold: true,
            fontSize: 13,
            color: "black",
          },
        },
        defaultStyle: {
          font: "THSarabunNew",
        },
      };

      var progressCallbackMethod = function () {};
      pdfMake.createPdf(docDefinition).download(fileName, null, {
        progressCallback: progressCallbackMethod,
      });

      setTimeout(() => {
        this.isLoading = false;
      }, 2000);
    },

    async _exportPDF(res, orientation) {
      this.email = await this.getUserEmail();
      var p = this.getURLParams();

      var canvas_1 = document.querySelectorAll(".big #doughnut-chart")[0];
      var canvasImg_1 = canvas_1.toDataURL("image/png", 1.0);
      var heading_1 = canvas_1
        .closest(".card")
        .querySelector(".card-header-title div")
        .innerHTML.toUpperCase();

      var canvas_2 = document.querySelectorAll(".big #horizontalbar-chart")[0];
      var canvasImg_2 = canvas_2.toDataURL("image/png", 1.0);
      var heading_2 = canvas_2
        .closest(".card")
        .querySelector(".card-header-title div")
        .innerHTML.toUpperCase();

      var canvas_3 = document.querySelectorAll(".big #doughnut-chart")[1];
      var canvasImg_3 = canvas_3.toDataURL("image/png", 1.0);
      var heading_3 = canvas_3
        .closest(".card")
        .querySelector(".card-header-title div")
        .innerHTML.toUpperCase();

      var canvas_4 = document.querySelectorAll(".big #horizontalbar-chart")[1];
      var canvasImg_4 = canvas_4.toDataURL("image/png", 1.0);
      var heading_4 = canvas_4
        .closest(".card")
        .querySelector(".card-header-title div")
        .innerHTML.toUpperCase();

      var canvas_5 = document.querySelectorAll(".big #line-chart")[0];
      var canvasImg_5 = canvas_5.toDataURL("image/png", 1.0);
      var heading_5 = canvas_5
        .closest(".card")
        .querySelector(".card-header-title div")
        .innerHTML.toUpperCase();

      var canvas_1_width;
      var canvas_2_width, canvas_3_width, canvas_4_width, canvas_5_width;
      var table_width;
      var content = [];

      if (orientation == "portrait") {
        canvas_1_width = canvas_3_width = 240;
        canvas_2_width = canvas_4_width = 300;

        canvas_5_width = 475;
        table_width = 345;
        content = [
          {
            alignment: "center",
            columns: [
              { width: 60, text: "" },
              {
                alignment: "left",
                text:
                  this.selectedTopicName +
                  "\n" +
                  this.getStartEnd(this.start, this.end),
                style: "header",
              },
            ],
          },

          {
            alignment: "center",
            columns: [
              {
                text: heading_1,
                style: "header",
              },
              {
                text: heading_2,
                style: "header",
              },
            ],
          },
          {
            image: canvasImg_1,
            width: canvas_1_width,
            absolutePosition: { x: 15, y: 100 },
          },
          {
            image: canvasImg_2,
            width: canvas_2_width,
            absolutePosition: { x: 260, y: 160 },
          },

          {
            text: heading_3,
            style: "header",
            absolutePosition: { x: 60, y: 380 },
          },
          {
            text: heading_4,
            style: "header",
            absolutePosition: { x: 340, y: 380 },
          },
          {
            image: canvasImg_3,
            width: canvas_3_width,
            absolutePosition: { x: 15, y: 380 },
          },
          {
            image: canvasImg_4,
            width: canvas_4_width,
            absolutePosition: { x: 260, y: 430 },
          },
          { text: "", fontSize: 20, pageBreak: "after" },
          {
            alignment: "center",
            columns: [
              { width: 60, text: "" },
              {
                alignment: "left",
                text:
                  this.selectedTopicName +
                  "\n" +
                  this.getStartEnd(this.start, this.end),
                style: "header",
              },
            ],
          },
          {
            alignment: "center",
            text: heading_5,
            style: "header",
          },
          {
            alignment: "center",
            image: canvasImg_5,
            width: canvas_5_width,
          },
          { text: "", fontSize: 20, pageBreak: "after" },
          {
            alignment: "center",
            columns: [
              { width: 60, text: "" },
              {
                alignment: "left",
                text: "TOP 20 MENTIONS (BY PR VALUE)",
                style: "header",
              },
            ],
          },
          {
            columns: [
              { width: 60, text: "" },
              this.genTablePDF([], res, ["auto", table_width, "auto"]),
              { width: 60, text: "" },
            ],
          },
        ];
      } else {
        canvas_1_width = canvas_3_width = (600 * 6) / 12;
        canvas_2_width = canvas_4_width = (600 * 8) / 12;

        canvas_5_width = 680;
        table_width = 570;
        content = [
          {
            alignment: "center",
            columns: [
              { width: 60, text: "" },
              {
                alignment: "left",
                text:
                  this.selectedTopicName +
                  "\n" +
                  this.getStartEnd(this.start, this.end),
                style: "header",
              },
            ],
          },

          {
            text: heading_1,
            style: "header",
            absolutePosition: { x: 120, y: 120 },
          },
          {
            text: heading_2,
            style: "header",
            absolutePosition: { x: 500, y: 120 },
          },
          {
            image: canvasImg_1,
            width: canvas_1_width,
            absolutePosition: { x: 30, y: 100 },
          },
          {
            image: canvasImg_2,
            width: canvas_2_width,
            absolutePosition: { x: 360, y: 160 },
          },

          { text: "", fontSize: 20, pageBreak: "after" },

          {
            alignment: "center",
            columns: [
              { width: 60, text: "" },
              {
                alignment: "left",
                text:
                  this.selectedTopicName +
                  "\n" +
                  this.getStartEnd(this.start, this.end),
                style: "header",
              },
            ],
          },
          {
            text: heading_3,
            style: "header",
            absolutePosition: { x: 120, y: 120 },
          },
          {
            text: heading_4,
            style: "header",
            absolutePosition: { x: 500, y: 120 },
          },
          {
            image: canvasImg_3,
            width: canvas_3_width,
            absolutePosition: { x: 30, y: 100 },
          },
          {
            image: canvasImg_4,
            width: canvas_4_width,
            absolutePosition: { x: 360, y: 160 },
          },
          { text: "", fontSize: 20, pageBreak: "after" },
          {
            alignment: "center",
            columns: [
              { width: 60, text: "" },
              {
                alignment: "left",
                text:
                  this.selectedTopicName +
                  "\n" +
                  this.getStartEnd(this.start, this.end),
                style: "header",
              },
            ],
          },
          {
            alignment: "center",
            text: heading_5,
            style: "header",
          },
          {
            alignment: "center",
            image: canvasImg_5,
            width: canvas_5_width,
          },
          { text: "", fontSize: 20, pageBreak: "after" },
          {
            alignment: "center",
            columns: [
              { width: 60, text: "" },
              {
                alignment: "left",
                text: "TOP 20 MENTIONS (BY PR VALUE)",
                style: "header",
              },
            ],
          },
          {
            columns: [
              { width: 60, text: "" },
              this.genTablePDF([], res, ["auto", table_width, "auto"]),
              { width: 60, text: "" },
            ],
          },
        ];
      }

      var options = { orientation: orientation };

      var fileName = this._getExportedFileName(".pdf");

      this.gaEvent("pdf", fileName);

      this.writeLog("report", [
        { key: "dbName", value: p.dbName },
        { key: "remark", value: fileName + " [" + orientation + "]" },
        { key: "email", value: this.email },
        { key: "originalURL", value: location.href },
      ]);
      await this.printPDF(content, fileName, options);
    },

    async _sendEmail() {
      var params = this.getURLParams();

      params["email"] = await this.getUserEmail();
      params["emailTo"] = this.emailxlsx;
      params["order_by"] = "QueryName asc, DisplayTime asc";

      params["logType"] = "report";
      params["ProfileID"] = this.oProfile.data[0].ProfileID;

      params["profileName"] = this.oProfileName;
      params["periodName"] =
        moment(this.start.substr(0, 8)).format("YYYYMMDD") +
        " - " +
        moment(this.end.substr(0, 8)).format("YYYYMMDD");

      this.excelFile.name = this._getExportedFileName(".xlsx");

      params["excelFile"] = this.excelFile.name;
      params["iquuid"] = await this.readLocalStorage("iquuid2", params.dbName);
      params["BrowserID"] = this.getBrowserID();
      params["start"] = this.start;
      params["end"] = this.end;

      this.isExcelModalActive = false;

      this.toast(
        "success",
        "IQIO is now checking the sending permission, please wait..."
      );

      await this.httpRequest("Dashboard/checkPermission", params).then(() => {
        if (this.$result.data.result == 200) {
          this.toast("success", "Email validation has passed.");

          this.httpRequest(
            "Dashboard/createJobExportExcel",
            params,
            "Checking whether the data exist or not. The process of preparing the exported file may takes a few minuites (if any)."
          ).then(() => {
            if (this.$result.data.result == 200) {
              this.toast("success", this.$result.data.message);
            } else {
              this.toast("danger", this.$result.data.message);
            }
          });
        } else {
          this.toast("danger", this.$result.data.message);
        }
      });
    },

    async _getReportByDataSource(item) {
      this.params = this.getURLParams();
      var iquuid = await this.readLocalStorage("iquuid2", this.params.dbName);
      this.$store.commit("setPageBack", this.params);
      if (iquuid == null) {
        this.gotoVerifyEmail();
      } else {
        this.DataSource = item.key;
        if (
          this.selectedStatCard.length == 0 ||
          this.selectedStatCard !== item.key
        ) {
          this.selectedStatCard = item.key;
        } else {
          this.selectedStatCard = "";
          this.DataSource = "";
        }

        await this.getReport(this.DataSource);
        this.isLoading = false;
      }
    },

    async _makePDFTableArray(table) {
      var rows = [];

      var header_top20PRValue = [];
      _.forEach(table.fields, function (v) {
        header_top20PRValue.push(v.key);
      });
      let self = this;
      var sumPR = 0;

      _.forEach(table.items, function (v) {
        var a = [];

        a[0] = [v.SourceName, v.DisplayTime];

        a[1] = [
          {
            text: self.stripHtml(v.HeadLine),
            link: v.OriginalURL,
            bold: true,
            alignment: "left",
          },
          {
            text: self.stripHtml(v.HitSentence),
          },
        ];

        a[2] = { text: self.number2Commas(v.PRValue1), alignment: "right" };
        sumPR += isNaN(v.PRValue1) ? 0 : parseFloat(v.PRValue1);
        rows.push(a);
      });

      var a = [];
      a[0] = { text: ["Total"], bold: true };
      a[1] = { text: [""] };
      a[2] = {
        text: [sumPR.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",")],
        alignment: "right",
        bold: true,
      };
      rows.push(a);
      return rows;
    },

    async _openCSVDialog() {
      this.params = this.getURLParams();
      var iquuid = await this.readLocalStorage("iquuid2", this.params.dbName);
      this.$store.commit("setPageBack", this.params);
      if (iquuid == null) {
        this.gotoVerifyEmail();
      } else {
        this.addColumn = null;
        this.isCSVModalActive = true;
      }
    },
    _getLabelFields() {
      if (this.oProfile.data) {
        var profileid = this.oProfile.data[0].ProfileID;
        this.labelFields =
          this.config["$labelFields_" + profileid] !== undefined
            ? this.config["$labelFields_" + profileid]
            : [];
      }
      //console.log("mark byte");
    },

    onPageChange(page) {
      this.page = page;
      this.currentPage = page;
      this.loadAsyncData();
    },

    isEmailValid: function () {
      if (typeof this.emailxlsx == "undefined") return false;

      if (this.emailxlsx.length == 0) {
        this.disabled = true;
        return false;
      } else {
        var reg =
          /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;

        if (this.emailxlsx.indexOf(",") > 0) {
          var e = this.emailxlsx.split(",");
          var r = [];

          e.map(function (v) {
            v = v.trim();
            r.push(reg.test(v));
          });

          if (r.indexOf(false) == -1) {
            this.disabled = false;
            return "has-success";
          } else {
            this.disabled = true;
            return "has-error";
          }
        } else {
          if (reg.test(this.emailxlsx)) {
            this.disabled = false;

            return "has-success";
          } else {
            this.disabled = true;
            return "has-error";
          }
        }
      }
    },

    isValidEmail: function () {
      if (this.disabled) return true;
      else return false;
    },

    _clearEmail() {
      this.emailxlsx = "";
    },

    _getExportedFileName(extension = ".csv") {
      var start = moment(this.start.substr(0, 8)).format("YYYYMMDD");
      var end = moment(this.end.substr(0, 8)).format("YYYYMMDD");
      var filename =
        this.selectedTopicName
          .trim()
          .replace(/([ /:])/g, "_")
          .trim() +
        "_" +
        start +
        "-" +
        end +
        extension;
      return filename;
    },

    async _exportCSV() {
      this.params = this.getURLParams();
      var iquuid = this.readLocalStorage("iquuid2", this.params.dbName);
      this.$store.commit("setPageBack", this.params);

      if (iquuid == null) {
        this.gotoVerifyEmail();
      } else {
        this.isLoading = true;
        var p = this.getURLParams();
        var elements = this.$route.path.split("/");

        var filename = this._getExportedFileName(".csv");
        this.CSVName = filename;

        p["order_by"] = "QueryName asc, DisplayTime asc";
        p["iquuid"] = await this.readLocalStorage("iquuid2", p.dbName);
        p["ProfileID"] = this.oProfile.data[0].ProfileID;
        p["start"] = this.start;
        p["end"] = this.end;
        if (this.addColumn !== null && this.addColumn !== undefined) {
          this.params["addColumn"] = this.addColumn;
        }

        return await this.httpRequest("Dashboard/getDataCSV/0", p).then(() => {
          this.gaEvent("csv", filename);
          this.writeLog("report", [
            { key: "dbName", value: elements[2] },
            { key: "email", value: this.$result.data.data.email },
            { key: "remark", value: filename },
            { key: "originalURL", value: location.href },
          ]);

          console.log("this.$result.data.data.items", this.$result.data.data.items);

          this.isCSVModalActive = false;

          if (this.addColumn !== null && this.addColumn !== undefined) {
            var ac = this.addColumn.split(",");
            var comp = [];
            for (var j in this.config.permissableAddColumn) {
              comp.push(j);
            }

            for (var i in ac) {
              if (comp.indexOf(ac[i].toString().toLowerCase()) >= 0) {
                this.jsonFields[
                  this.config.permissableAddColumn[
                    ac[i].toString().toLowerCase()
                  ]
                ] =
                  this.config.permissableAddColumn[
                    ac[i].toString().toLowerCase()
                  ];
              }
            }
          }

          this.isLoading = false;
          return this.$result.data.data.items;
        });
      }
    },

    _startDownload() {
      this.isLoading = true;
      if (this.labelFields !== undefined) {
        for (var i in this.labelFields) {
          var lo = this.labelFields[i]["name"].toString();
          this.jsonFields["zzzz_" + lo] = lo;
        }
      }
    },

    _finishDownload() {
      this.isLoading = false;
    },
    _login() {
      this.gotoVerifyEmail();
    },
    _logout() {
      const params = this.getURLParams();
      this.deleteLocalStorage("iquuid2", params.dbName);
      this.deleteLocalStorage("iqgoauth2");
      this.$store.commit("setPageBack", params);
      this.writeLog("verifyemail", [
        {
          key: "originalURL",
          value: location.href,
        },
        { key: "remark", value: "logout" },
        { key: "email", value: this.email },
      ]);
      this.email = "";
      this.gotoVerifyEmail();
    },
    _gotoSummaryPage() {
      var item = {
        row: {
          DatabaseName: this.params.dbName,
          ProfileID: this.params.TopicID.toLowerCase().substr(0, 6),
        },
      };

      this.gotoSummary(item);
    },

    async _onChangeTopic() {
      try {
        if (this.selectedTopicID !== null && this.isLoading == false) {
          try {
            this.selectedTopicName =
              this.oTopics[this.selectedTopicID].QueryName;
          } catch (err) {
            this.selectedTopicName = null;
          }
          if (this.selectedToicName !== null) {
            this.$route.params.profileid = this.selectedTopicID;

            this.$router
              .push({
                params: this.$route.params,
              })
              .catch((err) => {
                throw err;
              });
            await this.getReport(this.DataSource);

            this.isLoading = false;
          }
        }
      } catch (err) {
        throw err;
      }
    },

    async _getInitialData() {
      this.params = this.getURLParams();
      this.params["isCleansing"] = 0;
      this.params["iquuid"] = await this.readLocalStorage(
        "iquuid2",
        this.params.dbName
      );
      this.params["logType"] = "report";
      this.params["start"] = this.start;
      this.params["end"] = this.end;

      await this.httpRequest("Dashboard/getInitialData", this.params).then(
        (res) => {
          if (
            this.$result.data.periods !== null &&
            this.$result.data.periods.data !== undefined &&
            this.$result.data.periods.data.length > 0
          ) {
            res = this.$result.data;
            if (res.result == 200) {
              this.oPeriod = [];
              for (var i in res.periods.data) {
                var a = res.periods.data[i];
                a.PeriodName = a.PeriodName.split("/")[1];
                this.oPeriod.push(a);
              }
              this.oTopics = res.topics.data[0].Query;
              this.oProfile = res.profile;
              this.oProfileName = res.profile.data[0].ProfileName;
              this.selectedTopicName = this.oTopics[this.selectedTopicID]
                ? this.oTopics[this.selectedTopicID].QueryName
                : "";
            } else {
              this.waitingText = res.message;
            }
          }
        }
      );
    },

    async getReport(datasource) {
      this.params = this.getURLParams();

      var iquuid = await this.readLocalStorage("iquuid2", this.params.dbName);
      this.$store.commit("setPageBack", this.params);

      if (iquuid == null) {
        this.gotoVerifyEmail();
      } else {
        var se = this.getStartEndInterval(
          this.params.periodName,
          this.params.periodID
        );

        this.start = se.start;
        this.end = se.end;

        this.isLoading = true;
        this.params["isCleansing"] = 0;
        this.params["MentionID"] = [1, 2];
        this.params["start"] = this.start;
        this.params["end"] = this.end;

        if (datasource !== undefined) this.DataSource = datasource;
        if (this.DataSource.length > 0)
          this.params["DataSource"] = this.DataSource;

        await this.httpRequest("Dashboard", this.params).then(() => {
          if (this.$result.data.chart_1.length == 0) {           
            this.isFoundData = false;
          } else {
            this.isFoundData = true;
            this.oChartData = this.$result.data;
          }
        });
      }
      this.astate = 1;
    },
  },

  async mounted() {
    this.isLoading = true;    
  },

  async created() {
    try {
      await this.getServerConfig().then((res) => {
        this.config = res;
        this.eulaurl = res.EULAURL;
        this.contactusurl = res.ContactUsURL;
        this.companyurl = res.CompanyURL;
      });

      this.params = this.getURLParams();

      this.isLogin = false;
      this.isFoundData = true;
      this.selectedTopicID = this.params.TopicID;
      var iquuid = await this.readLocalStorage("iquuid2", this.params.dbName);
      var BrowserID = this.getBrowserID();
      this.$store.commit("setPageBack", this.params);
      
      if (iquuid == null) {
        this.gotoVerifyEmail();
      } else {
        if (await this.isValidDatabase()) {
          await this._getInitialData();
          if (await this._isValidURL(this.params)) {
            var res = await this.checkPermission(
              iquuid,
              BrowserID,
              this.params.page
            );

            var _continue = res.data.continue;
            var _goto = res.data.goto;
            var _clearUUID = res.data.clearUUID;

            if (_continue) {
              this.email = await this.getUserEmail();
              if (this.email.length > 0) this.emailxlsx = this.email;

              await this.getReport(this.DataSource);
              await this.gaPage();

              this.addColumn = this.$route.query.addcolumn;

              if (
                (this.params.periodID !== null &&
                  this.params.periodID !== undefined &&
                  this.params.periodID.toLowerCase() == "csv") ||
                (this.params.csv !== undefined &&
                  this.params.csv !== null &&
                  this.params.csv.toLowerCase() == "csv")
              ) {
                if (this._checkPermissableAddColumn() == true) {
                  this.isLoading = true;
                  var elem = this.$refs.myBtn;
                  await elem.generate();

                  var a = [
                    this.params.page,
                    this.params.dbName,
                    this.params.TopicID,
                    this.params.periodName,
                  ];

                  if (this.params.periodID.toLowerCase() !== "csv") {
                    a.push(this.params.periodID);
                  }
                  this.$router.push("/" + a.join("/")).catch((err) => {
                    throw err;
                  });

                  this.isLoading = false;
                } else {
                  this.toast(
                    "danger",
                    "Unknown column of [" + this.$route.query.addcolumn + "]"
                  );
                }
              }
            } else {
              if (_goto == 401 && _clearUUID == true) {
                this.deleteLocalStorage("iquuid2", this.params.dbName);
              }
              if (_goto == "verifyemail") {
                this.gotoVerifyEmail();
              } else {
                this.astate = 2;
                this.toast(
                  "danger",
                  "Restricted section. Only valid email can visit this page."
                );
                this.isLoading = false;
              }
            }
          } else {
            var topics = [];
            for (var i in this.oProfile.data) {
              topics.push(this.oProfile.data[i].ProfileID);
            }
            this.gotoDefaultPage(false, topics);
          }
        } else {
          this.redirectHost(this.params);
        }
      }
    } catch (err) {
      throw err;
    }
  },

  /*
  * Load export history
  */
  // async loadExportHistory() {

  //   console.log("loadExportHistory");
    
  //   let key = encHex.parse(this.hexkey);
  //   let iv = encHex.parse(this.hexiv);

  //   var encrypted = aes
  //     .encrypt(this.$prefixAuthKey + "-" + Date.now(), key, {
  //       iv: iv,
  //       padding: padZeroPadding,
  //     })
  //     .toString();
  //   //var params = { AuthKey: encrypted , };
  //   var params = this.getURLParams();
  //   params["AuthKey"] = encrypted;

  //   console.log("params", params);

  //   await this.httpRequest("Dashboard/getNotification", params)
  //     .then((res) => {
        
  //       res = this.$result.data;
  //       this.data = [];
  //       // let currentTotal = res.databases.length;
  //       // if (res.total_results / this.perPage > 1000) {
  //       //   currentTotal = this.perPage * 1000;
  //       // }
  //       // this.total = currentTotal;

  //       // this.paginated = true;

  //       let db = [];
  //       // res.databases.map((v) => {
  //       //   db.push(v);
  //       // });

  //       console.log("this.$result",this.$result);
  //       console.log("res",res);

  //       this.data = db;
  //       //this.isLoading = false;
  //     })
  //     .catch((error) => {
  //       this.data = [];
  //       this.total = 0;
  //       this.isLoading = false;
  //       throw error;
  //     });
  // },

};
</script>