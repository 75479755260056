<template>
  <!-- 
  @Page: Infoquest 360 - Summary Page
  @LastUpdate: 2021-07-14
  @Objectives: 
    to display the BarChart of Share of Voices (Both by Mentions and PR Value) and it's figures displayed in table under each chart
    t0 show the selections of all Queries Name in the specific Customer's Database in order to view other statistics in detail.
-->
  <div>
    <a name="toTop"></a>
    <b-navbar class="is-fixed-top">
            <template slot="brand">
                <b-navbar-item class="no-pointer">
                    <img
                        src="../assets/img/io_logo.png"
                        :alt="$websiteDescription"
                    />
                </b-navbar-item>
            </template>
            <template slot="start">
                <downloadexcel
                    ref="myBtn"
                    class="no-print hide"
                    :name="CSVName"
                    :fetch="_exportCSV"
                    :fields="jsonFields"
                    :before-generate="_startDownload"
                    :before-finish="_finishDownload"
                    type="csv"
                >
                    <template>
                        <b-icon icon="file-delimited"></b-icon>
                        <span>Export CSV</span>
                    </template>
                </downloadexcel></template
            >

            <!-- <template slot="end">
                <b-navbar-dropdown :label="email">
                    <b-navbar-item
                        v-if="email !== undefined && email.length > 0"
                        @click="_logout()"
                        ><template
                            ><b-icon icon="logout"></b-icon
                            ><span>Logout</span></template
                        ></b-navbar-item
                    >
                    <b-navbar-item v-else @click="_login()">
                        Login
                    </b-navbar-item>
                </b-navbar-dropdown>
            </template>
            <template slot="end">
                <div> 
                    <Notification></Notification>
                    
                </div>
            </template> -->


          <template slot="end">
              <div class="top-right-container">
                <div v-if="email !== undefined && email.length > 0" class="top-item">
                  <div class="top-item">
                      <b-icon icon="person"></b-icon>
                  </div>
                  <div  class="top-item">{{email}}</div>
                  <div class="bell-notification">
                    <span class="bell-notification-child">
                      <Notification></Notification>
                      </span>
                  </div>
                  <div class="top-item icon-hover" @click="_logout()">
                    <b-icon icon="logout" ></b-icon>
                  </div>
                </div>
                <div v-else @click="_login()"> Login </div>
              </div>
          </template> 
          <!-- <template slot="end"> 
            <Notification></Notification>
          </template> -->



        </b-navbar>

    <b-modal
      :active.sync="isCSVModalActive"
      :width="800"
      scroll="keep"
      custom-class="IQIO-Modal"
    >
      <div class="card">
        <header class="card-header">
          <p class="card-header-title">Exporting options</p>
        </header>
        <div class="card-content">
          <div class="content has-text-left">
            <div class="columns">
              <div class="column is-2 has-text-weight-bold">Profile Name</div>
              <div class="column is-10">
                {{ this.ProfileName }}
              </div>
            </div>
          </div>
          <div class="buttons">
            <b-button type="is-primary">
              <downloadexcel
                id="myBtn"
                class="no-print"
                :name="CSVName"
                :fetch="_exportCSV"
                :fields="jsonFields"
                :before-generate="_startDownload"
                :before-finish="_finishDownload"
                type="csv"
              >
                <template>
                  <b-icon icon="file-delimited"></b-icon>
                  <span>Export CSV</span>
                </template>
              </downloadexcel>
            </b-button>
          </div>
        </div>
      </div>
    </b-modal>

    <b-modal
      :active.sync="isExcelModalActive"
      :width="640"
      scroll="keep"
      custom-class="IQIO-Modal"
    >
      <form>
        <div class="card">
          <header class="card-header">
            <p class="card-header-title">Input e-mail</p>
          </header>
          <div class="card-content">
            <div class="content has-text-left">
              Please input only a valid e-mail, you wish to send the export
              notification e-mail to. The valid email must be comply with the
              following domains of ["infoquest.co.th", "iqnewsclip.com"] or in
              the given contact emails

              <b-field
                label="e-mail"
                :class="['column is-full', isEmailValid()]"
              >
              </b-field>
              <b-input
                type="email"
                ref="emailxlsx"
                v-model="emailxlsx"
                required
                icon="email"
                minlength="10"
                maxlength="50"
                placeholder="e-mail to receive exported excel file"
              ></b-input>
            </div>
            <div class="buttons">
              <b-button
                type="is-primary"
                :disabled="isValidEmail()"
                @click.prevent="_sendEmail"
                >Submit</b-button
              >
              <b-button
                type="is-warning"
                class="white"
                @click.prevent="_clearEmail()"
                >Clear</b-button
              >
            </div>
          </div>
        </div>
      </form>
    </b-modal>

    <div class="big">
      <div class="columns">
        <div class="column">
          <Hbarchart
            :chartdata="this.oChart_6"
            :options="DataoptionsBig"
          ></Hbarchart>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <Hbarchart
            :chartdata="this.oChart_7"
            :options="DataoptionsBig"
          ></Hbarchart>
        </div>
      </div>
    </div>

    <div class="_wrapper" v-if="astate == 1">
      <div class="columns is-multiline is-mobile">
        <!-- 1st column -->
        <div class="column is-9-desktop is-full-mobile has-text-left">
          <div class="header">
            <span>{{ this.ProfileName }}</span>
          </div>
        </div>
        <!-- 2nd column -->
        <div
          class="column is-3-desktop is-full-mobile is-3-tablet has-text-right"
        >
          <daterangepicker @setSelectPeriod="_onChangePeriod"></daterangepicker>
        </div>
      </div>

      <div class="columns is-multiline is-mobile">
        <!-- 3rd column -->
        <div class="column has-text-left is-6-mobile">
          <a href="#toTopicList" class="anchor" v-if="isFoundData == true"
            ><div class="block pt-4">
              <b-icon icon="arrow-down-bold-circle-outline" size="is-small">
              </b-icon
              >Go to Topic List
            </div></a
          >
        </div>
        <!-- 4th column -->
        <div class="column is-6 has-text-right">
          <div v-if="isFoundData == true">
            <b-dropdown aria-role="list">
              <button class="button is-primary" type="button" slot="trigger">
                <template>
                  <span>Export to...</span>
                </template>
                <b-icon icon="menu-down"></b-icon>
              </button>
              <div>
                <b-dropdown-item :value="1" aria-role="listitem">
                  <div
                    class="media has-text-left"
                    @click.prevent="_exportPDF('portrait')"
                  >
                    <b-icon
                      class="media-left"
                      pack="far"
                      icon="file-pdf"
                    ></b-icon>
                    <div class="media-content has-text-left">
                      <h3>PDF (Portrait)</h3>
                      <small>Export to pdf file</small>
                    </div>
                  </div>
                </b-dropdown-item>

                <b-dropdown-item :value="2" aria-role="listitem">
                  <div
                    class="media has-text-left"
                    @click.prevent="_exportPDF('landscape')"
                  >
                    <b-icon
                      class="media-left has-text-left"
                      pack="far"
                      icon="file-pdf"
                    ></b-icon>
                    <div class="media-content">
                      <h3>PDF (Landscape)</h3>
                      <small>Export to pdf file</small>
                    </div>
                  </div>
                </b-dropdown-item>

                <b-dropdown-item :value="4" aria-role="listitem">
                  <div
                    class="media has-text-left"
                    @click.prevent="_openCSVDialog()"
                  >
                    <b-icon class="media-left" pack="mdi" icon="file"></b-icon>
                    <div class="media-content has-text-left">
                      <h3>CSV</h3>
                      <small>Export to csv file</small>
                    </div>
                  </div>
                </b-dropdown-item>

                <b-dropdown-item :value="3" aria-role="listitem">
                  <div
                    class="media has-text-left"
                    @click.prevent="_exportExcel()"
                  >
                    <b-icon
                      class="media-left"
                      pack="far"
                      icon="file-excel"
                    ></b-icon>
                    <div class="media-content has-text-left">
                      <h3>Excel</h3>
                      <small>Export to xlsx file</small>
                    </div>
                  </div>
                </b-dropdown-item>
              </div>
            </b-dropdown>
          </div>
          <div v-else>
            <button
              class="button is-primary"
              type="button"
              slot="trigger"
              disabled="true"
            >
              <template>
                <span>Export to...</span>
              </template>
              <b-icon icon="menu-down"></b-icon>
            </button>
          </div>
        </div>
      </div>

      <div v-if="isFoundData == true">
        <div class="columns is-multiline stat-card">
          <div
            v-for="(item, index) in oStatsCard"
            :value="item.key"
            :key="item.key"
            class="column is-full-mobile is-half-tablet is-3-desktop"
            @click.prevent="_getReportByDataSource(item)"
          >
            <div class="card">
              <div
                class="card-content"
                :style="
                  item.key == selectedStatCard
                    ? { 'background-color': '#ff9' }
                    : null
                "
              >
                <div class="content">
                  <div class="level">
                    <div class="level-item">
                      <div class="sub-header">{{ item.title }}</div>
                    </div>
                  </div>
                  <div class="level">
                    <div class="level-left">
                      <div class="level-item">
                        <b-icon
                          :pack="_getPack(item.icon)"
                          :icon="item.icon"
                          size="is-large"
                          :custom-class="_getIconColor(index)"
                        ></b-icon>
                      </div>
                    </div>
                    <div class="level-right">
                      <div class="level-item big-figure">
                        {{ item.value }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="columns is-multiline is-mobile">
          <div class="column is-full-tablet is-full-mobile">
            <Hbarchart
              :chartdata="oChart_6"
              :options="Dataoptions"
              @_getTable="_getTable6"
              :datasource="config.$DataSource"
              :isLoading="isLoading"
            ></Hbarchart>
          </div>
        </div>

        <div class="columns is-multiline is-mobile">
          <div class="column is-full-tablet is-full-mobile">
            <Hbarchart
              :chartdata="oChart_7"
              :options="Dataoptions"
              @_getTable="_getTable7"
              :datasource="config.$DataSource"
              :isLoading="isLoading"
            ></Hbarchart>
          </div>
        </div>

        <div>
          <a name="toTopicList"></a>
          <p class="text-big text-left">
            <span
              style="display: block; white-space: pre; padding-bottom: 20px"
            >
              {{ this.ProfileName }}
              <br />
              {{ getStartEnd(this.start, this.end) }} ({{ sumMention }}
              Mentions)
            </span>
          </p>
          <div class="card">
            <div slot="trigger" class="card-header" role="button"></div>
            <div class="card-content">
              <div class="content canClickRow">
                <b-table
                  id="table_summary_link"
                  :striped="true"
                  :data="data == undefined ? [] : data"
                  :paginated="paginated"
                  :total="total"
                  :per-page="perPage"
                  @page-change="onPageChange"
                  aria-next-label="Next page"
                  aria-previous-label="Previous page"
                  aria-page-label="Page"
                  aria-current-label="Current page"
                  aria-controls="__content"
                  @select="_onSelectRow"
                >
                  <template>
                    <b-table-column
                      field="QueryName"
                      label="Topic"
                      :sortable="false"
                      v-slot="props"
                      ><div class="has-text-left">
                        {{ props.row.QueryName }}
                      </div></b-table-column
                    >

                    <b-table-column
                      field="Count"
                      label="# Mentions"
                      class="has-text-right"
                      :numeric="true"
                      :sortable="false"
                      :custom-sort="_sortMention"
                      v-slot="props"
                      >{{ props.row.Count }}</b-table-column
                    >

                    <b-table-column
                      field="PRValue"
                      label="PR Value"
                      class="has-text-right"
                      :numeric="true"
                      :sortable="false"
                      :custom-sort="_sortPRValue"
                      v-slot="props"
                      >{{ props.row.PRValue }}</b-table-column
                    >

                    <b-table-column
                      field="TopicID"
                      centered
                      label="Monthly"
                      :sortable="false"
                    >
                      <b-button type="is-primary">
                        <template>
                          <span>View</span>
                        </template>
                      </b-button>
                    </b-table-column>
                  </template>
                  <template slot="empty">
                    <section class="section">
                      <div class="content has-text-grey">
                        <p class="has-text-centered">
                          <b-icon icon="emoticon-sad" size="is-large"></b-icon>
                        </p>
                        <p class="has-text-centered">No record found here.</p>
                      </div>
                    </section>
                  </template>
                </b-table>
                <div align="right" class="m-t-10">
                  <a href="#toTop" class="anchor"
                    ><div class="block">
                      <b-icon
                        icon="arrow-up-bold-circle-outline"
                        size="is-small"
                      >
                      </b-icon
                      >Go to Top
                    </div></a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-else>
        <NotFound
          v-if="isLoading == false"
          :startEnd="getStartEnd(this.start, this.end)"
        ></NotFound>
      </div>
    </div>
    <div class="_wrapper" v-if="isLoading == true">
      <b-loading
        :is-full-page="isFullPage"
        :active.sync="isLoading"
        :can-cancel="true"
      ></b-loading>
      <Preparing :waitingText="this.waitingText"></Preparing>
    </div>
    <div class="_wrapper" v-else-if="astate == 2">
      <NotAuthorizeEditor></NotAuthorizeEditor>
    </div>

    <Footer
      :eulaurl="eulaurl"
      :contactusurl="contactusurl"
      :companyurl="companyurl"
    ></Footer>
  </div>
</template>

<script>
import pdfFonts from "pdfmake/build/vfs_fonts";
import Footer from "../layout/Footer.vue";
import NotFound from "../contents/notfound";
import Preparing from "../contents/preparing";
import Hbarchart from "../components/ChartJS/Hbarchart";
import moment from "moment";
import downloadexcel from "vue-json-excel";
//import downloadcsv from "vue-json-csv";
import daterangepicker from "../components/DateRangePicker";
import NotAuthorizeEditor from "../pages/NotAuthorizeEditor";
import Notification from "../controls/Notification";
// import aes from "crypto-js/aes";
// import encHex from "crypto-js/enc-hex";
// import padZeroPadding from "crypto-js/pad-zeropadding";

var _ = require("lodash");
export default {
  name: "summaryPage",
  components: {
    Footer,
    Hbarchart,
    NotFound,
    Preparing,
    downloadexcel,
    daterangepicker,
    NotAuthorizeEditor,
    Notification
  },
  props: {},
  filters: {
    date(val) {
      return val ? val.toLocaleString() : "";
    },
  },
  data() {
    return {
      addColumn: null,
      isLoading: true,
      eulaurl: "",
      companyurl: "",
      contactusurl: "",
      astate: 0,
      dateFormat: "DD MMMM YYYY",
      start: "",
      end: "",
      params: [],
      chartHeight: 240,
      tableHeader: [],
      table_6: [],
      table_7: [],
      excelFile: { name: "" },
      waitingText: "Preparing data, please wait...",
      DataSource: "",

      isFoundData: true,
      isFullPage: true,
      ProfileName: "",
      oChart_6: {},
      oChart_7: {},
      oStatsCard: {},
      oTopics: {},
      oProfile: {},
      paginated: false,
      labelFields: [],
      isLogin: false,
      emailxlsx: "",
      email: "",
      selectedStatCard: "",
      selected: {},
      CSVName: "",
      jsonFields: {
        Topic: "Topic",
        DataSource: "Type",
        SubType: "SubType",
        SourceName: "SourceName",
        DisplayDate1: "DisplayDate",
        HeadLine: "Headline",
        PRValue: "PRValue",
        PotentialReach: "PotentialReach",
        Page: "Page",
        Section: "Section",
        ClipType: "ClipType",
        Circulation: "Circulation",
        AdRate: "AdRate",
        ColInch: "ArticleSize",
        AdValue: "AdValue",
      },
      isExcelModalActive: false,
      isCSVModalActive: false,
      config: {},
      data: [],
      total: 0,
      loading: false,
      sortField: "",
      sortOrder: "desc",
      defaultSortOrder: "desc",
      page: 1,
      perPage: 50,
      sumMention: 0,
      // hexkey: "",
      // hexiv: "",
      Dataoptions: {
        responsive: true,
        maintainAspectRatio: true,
        animation: {
          duration: 0,
        },
        hover: {
          animationDuration: 0,
        },
        responsiveAnimationDuration: 0,

        scales: {
          xAxes: [
            {
              stacked: true,
              ticks: {
                // fontSize: this.legendFontSize,
                fontFamily: "Bai Jamjuree",
                fontSize: 14,
                fontColor: "#000",

                beginAtZero: true,

                userCallback: function (value) {
                  // Convert the number to a string and splite the string every 3 charaters from the end
                  if (value >= 1000000) {
                    value = value / 1000000;
                    value = value.toString() + "M";
                  } else if (value >= 1000) {
                    value = value / 1000;
                    value = value.toString() + "K";
                  } else {
                    value = value.toString();
                    value = value.split(/(?=(?:...)*$)/);
                    value = value.join(",");
                    if (value % 1 !== 0) {
                      value = "";
                    }
                  }
                  return value;
                },
              },
              gridLines: {
                drawOnChartArea: true,
                lineWidth: 1,
              },
            },
          ],
          yAxes: [
            {
              stacked: true,
              ticks: {
                fontFamily: "Bai Jamjuree",
                fontSize: 14,
                fontColor: "#000",
                beginAtZero: true,
              },
              gridLines: {
                drawOnChartArea: true,
                lineWidth: 1,
              },
            },
          ],
        },

        legend: {
          display: true,
          position: "bottom",
          labels: {
            fontFamily: "Bai Jamjuree",
            fontColor: "#000",
            fontSize: 14,
          },
          onHover: function (e) {
            e.target.style.cursor = "pointer";
          },
        },

        tooltips: {
          callbacks: {
            title: function (tooltipItem, data) {
              return data.labels[tooltipItem[0].index];
            },
            label: function (tooltipItem, data) {
              var dataset = data.datasets[tooltipItem.datasetIndex];

              //calculate the total of this data set
              var total = 0;

              data.datasets.map(function (v) {
                total += v.data[tooltipItem.index];
              });

              //get the current items value
              var currentValue = dataset.data[tooltipItem.index];

              //calculate the precentage based on the total and current item, also this does a rough rounding to give a whole number
              var percentage = (currentValue / total) * 100;

              return (
                data.datasets[tooltipItem.datasetIndex].label +
                ": " +
                tooltipItem.xLabel
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
                " (" +
                percentage.toFixed(2) +
                "%)"
              );
            },
          },
        },

        plugins: {
          datalabels: {
            display: false,
            color: "black",
            anchor: "end",
            formatter: function (value) {
              if (value >= 1000000) {
                if (value % 1000000 == 0) {
                  value = value / 1000000;
                } else {
                  value = (value / 1000000).toFixed(2);
                }

                return (
                  value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") +
                  "M"
                );
              } else if (value >= 1000) {
                if (value % 1000 == 0) {
                  value = value / 1000;
                } else {
                  value = (value / 1000).toFixed(2);
                }

                return (
                  value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") +
                  "K"
                );
              } else if (value == 0) {
                return "";
              } else {
                return value
                  .toString()
                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
              }
            },
            align: function (context) {
              var max = 0;
              context.dataset.data.map(function (v) {
                if (parseFloat(v) > max) max = v;
              });

              if (self.stacked) {
                return "middle";
              } else {
                if (context.dataset.data[context.dataIndex] / max >= 0.9) {
                  return "start";
                } else {
                  return "end";
                }
              }
            },
            backgroundColor: function (context) {
              var max = 0;
              context.dataset.data.map(function (v) {
                if (parseFloat(v) > max) max = v;
              });

              if (self.stacked) {
                return "rgba(255, 255, 255, 0)";
              } else {
                if (context.dataset.data[context.dataIndex] / max >= 0.9) {
                  return "rgba(255, 255, 255, 0.6)";
                } else {
                  return "rgba(255, 255, 255, 0)";
                }
              }
            },
            offset: 1,
          },
        },
      },

      DataoptionsBig: {
        responsive: true,
        maintainAspectRatio: true,
        animation: {
          duration: 0,
        },
        hover: {
          animationDuration: 0,
        },
        responsiveAnimationDuration: 0,

        scales: {
          xAxes: [
            {
              stacked: true,
              ticks: {
                // fontSize: this.legendFontSize,
                fontFamily: "Bai Jamjuree",
                fontSize: 14 * 3,
                fontColor: "#000",

                beginAtZero: true,

                userCallback: function (value) {
                  // Convert the number to a string and splite the string every 3 charaters from the end
                  if (value >= 1000000) {
                    value = value / 1000000;
                    value = value.toString() + "M";
                  } else if (value >= 1000) {
                    value = value / 1000;
                    value = value.toString() + "K";
                  } else {
                    value = value.toString();
                    value = value.split(/(?=(?:...)*$)/);
                    value = value.join(",");
                    if (value % 1 !== 0) {
                      value = "";
                    }
                  }
                  return value;
                },
              },
            },
          ],
          yAxes: [
            {
              stacked: true,
              ticks: {
                fontFamily: "Bai Jamjuree",
                fontSize: 14 * 3,
                fontColor: "#000",
                beginAtZero: true,
              },
              // barPercentage: 0.8,
              // maxBarThickness: 130,
            },
          ],
        },

        legend: {
          display: true,
          position: "bottom",
          labels: {
            padding: 100,
            fontFamily: "Bai Jamjuree",
            fontSize: 15 * 4,
            fontColor: "#000",
            fontStyle: "false",
          },
          onHover: function (e) {
            e.target.style.cursor = "pointer";
          },
        },

        tooltips: {
          callbacks: {
            title: function (tooltipItem, data) {
              return data.labels[tooltipItem[0].index];
            },
            label: function (tooltipItem, data) {
              var dataset = data.datasets[tooltipItem.datasetIndex];

              //calculate the total of this data set
              var total = 0;

              data.datasets.map(function (v) {
                total += v.data[tooltipItem.index];
              });

              //get the current items value
              var currentValue = dataset.data[tooltipItem.index];

              //calculate the precentage based on the total and current item, also this does a rough rounding to give a whole number
              var percentage = (currentValue / total) * 100;

              return (
                data.datasets[tooltipItem.datasetIndex].label +
                ": " +
                tooltipItem.xLabel
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
                " (" +
                percentage.toFixed(2) +
                "%)"
              );
            },
          },
        },

        plugins: {
          datalabels: {
            display: false,
            color: "black",
            anchor: "end",
            formatter: function (value) {
              if (value >= 1000000) {
                if (value % 1000000 == 0) {
                  value = value / 1000000;
                } else {
                  value = (value / 1000000).toFixed(2);
                }

                return (
                  value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") +
                  "M"
                );
              } else if (value >= 1000) {
                if (value % 1000 == 0) {
                  value = value / 1000;
                } else {
                  value = (value / 1000).toFixed(2);
                }

                return (
                  value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") +
                  "K"
                );
              } else if (value == 0) {
                return "";
              } else {
                return value
                  .toString()
                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
              }
            },
            align: function (context) {
              var max = 0;
              context.dataset.data.map(function (v) {
                if (parseFloat(v) > max) max = v;
              });

              if (self.stacked) {
                return "middle";
              } else {
                if (context.dataset.data[context.dataIndex] / max >= 0.9) {
                  return "start";
                } else {
                  return "end";
                }
              }
            },
            backgroundColor: function (context) {
              var max = 0;
              context.dataset.data.map(function (v) {
                if (parseFloat(v) > max) max = v;
              });

              if (self.stacked) {
                return "rgba(255, 255, 255, 0)";
              } else {
                if (context.dataset.data[context.dataIndex] / max >= 0.9) {
                  return "rgba(255, 255, 255, 0.6)";
                } else {
                  return "rgba(255, 255, 255, 0)";
                }
              }
            },
            offset: 1,
          },
        },
      },
    };
  },
  watch: {
    oTopics() {
      this.loadAsyncData();
    },
    oProfile() {
      this._getLabelFields();
    },
  },

  methods: {
    _checkPermissableAddColumn() {
      var res = [];

      if (this.addColumn !== null && this.addColumn !== undefined) {
        if (Object.keys(this.config.permissableAddColumn).length > 0) {
          var cols = this.addColumn.split(",");
          var comp = [];
          for (var j in this.config.permissableAddColumn) {
            comp.push(j);
          }

          comp = this.intersect(cols, comp);
          if (comp.length > 0) {
            res.push(0);
          } else {
            res.push(1);
          }
        } else {
          res.push(1);
        }
      } else {
        res.push(0);
      }

      var sum = 0;
      for (var i in res) {
        sum += res[i];
      }

      return sum == 0 ? true : false;
    },
    async _isValidURL(params) {
      var a =
        this.oProfile !== undefined && Object.keys(this.oProfile).length > 0
          ? true
          : false;

      var b = this.isValidPeriod(params);
      b = b.result;

      var c = false;
      var cutLength = params.TopicID.length;
      if (this.oProfile) {
        if (cutLength == 6) {
          for (var i in this.oProfile.data) {
            if (
              this.oProfile.data[i]["ProfileID"]
                .substr(0, cutLength)
                .toLowerCase() == params.TopicID
            ) {
              c = true;
              break;
            }
          }
        }
      }
      return a && b && c;
    },
    async _onChangePeriod(params) {
      try {
        if (this.isLoading == false) {
          this.isLoading = true;
          this.params = this.getURLParams();

          var a = [
            this.params.page,
            this.params.dbName,
            this.params.reportID.toLowerCase(),
            params.periodName,
          ];

          if (
            params.periodID !== null &&
            params.periodID !== undefined &&
            params.periodID.length > 0
          ) {
            a.push(params.periodID);
          }
          this.$router.push("/" + a.join("/"));
          await this.getSummaryReport(this.DataSource);
        }
        this.isLoading = false;
      } catch (err) {
        throw err;
      }
    },

    async _getReportByDataSource(item) {
      this.params = this.getURLParams();
      var iquuid = this.readLocalStorage("iquuid2", this.params.dbName);
      this.$store.commit("setPageBack", this.params);
      if (iquuid == null) {
        this.gotoVerifyEmail();
      } else {
        this.DataSource = item.key;
        if (
          this.selectedStatCard.length == 0 ||
          this.selectedStatCard !== item.key
        ) {
          this.selectedStatCard = item.key;
        } else {
          this.selectedStatCard = "";
          this.DataSource = "";
        }

        await this.getSummaryReport(this.DataSource);
        this.isLoading = false;
      }
    },
    _openCSVDialog() {
      this.params = this.getURLParams();
      var iquuid = this.readLocalStorage("iquuid2", this.params.dbName);
      this.$store.commit("setPageBack", this.params);
      if (iquuid == null) {
        this.gotoVerifyEmail();
      } else {
        this.addColumn = null;
        this.isCSVModalActive = true;
      }
    },
    loadAsyncData() {
      if (this.oTopics.data) {
        var res = this.oTopics.data;

        if (res !== undefined) {
          this.data = [];
          let currentTotal = res.length;
          if (res.length / this.perPage > 1000) {
            currentTotal = this.perPage * 1000;
          }
          this.total = currentTotal;

          this.paginated = true;

          this.data = res;

          this.sumMention = 0;
          for (var i = 0; i < res.length; i++) {
            this.sumMention += parseInt(
              res[i].Count.toString().replace(/,/g, ""),
              10
            );
          }

          this.sumMention = this.sumMention
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
      }
    },

    async printPDF(content, fileName, option) {
      var orientation = option.orientation || "landscape";
      var pdfMake = require("pdfmake/build/pdfmake.js");
      pdfMake.vfs = pdfFonts.pdfMake.vfs;

      //ต้องระบุตามชื่อของ ไฟล์ font
      pdfMake.fonts = this.$pdfMakeFonts;
      var docDefinition = {
        pageOrientation: orientation,
        pageMargins: [0, 60, 0, 60],
        content: content,
        styles: {
          header: {
            fontSize: 18,
            bold: true,
            margin: [0, 0, 0, 10],
          },
          subheader: {
            fontSize: 16,
            bold: true,
            margin: [0, 10, 0, 5],
          },
          tableExample: {
            margin: [0, 5, 0, 15],
          },

          tableHeader: {
            bold: true,
            fontSize: 13,
            color: "black",
          },
        },
        defaultStyle: {
          font: "THSarabunNew",
        },
      };

      var progressCallbackMethod = function () {};
      await pdfMake.createPdf(docDefinition).download(fileName, null, {
        progressCallback: progressCallbackMethod,
      });

      setTimeout(() => {
        this.astate = 1;
        this.isLoading = false;
      }, 2000);
    },

    async _exportPDF(orientation) {
      this.isLoading = true;
      this.params = this.getURLParams();
      var iquuid = this.readLocalStorage("iquuid2", this.params.dbName);
      this.$store.commit("setPageBack", this.params);
      if (iquuid == null) {
        this.gotoVerifyEmail();
      } else {
        this.astate = 1;
        this.params = this.getURLParams();
        this.email = await this.getUserEmail();

        scrollTo(0, 10);

        var canvas_6 = document.querySelectorAll(
          ".big #horizontalbar-chart"
        )[0];

        var canvasImg_6 = canvas_6.toDataURL("image/png", 1.0);
        var heading_6 = canvas_6
          .closest(".card")
          .querySelector(".card-header-title div")
          .innerHTML.toUpperCase();

        var canvas_7 = document.querySelectorAll(
          ".big #horizontalbar-chart"
        )[1];

        var canvasImg_7 = canvas_7.toDataURL("image/png", 1.0);
        var heading_7 = canvas_7
          .closest(".card")
          .querySelector(".card-header-title div")
          .innerHTML.toUpperCase();

        var canvas_6_width;
        var canvas_7_width;
        if (orientation == "portrait") {
          canvas_6_width = canvas_7_width = 475;
        } else {
          canvas_6_width = canvas_7_width = 680;
        }

        var rows1 = [];
        var rows2 = [];

        _.forEach(this.oStatsCard, function (value) {
          rows1.push(value.title);
          rows2.push(value.value);
        });

        var content = [
          {
            alignment: "center",
            columns: [
              { width: 60, text: "" },
              {
                alignment: "left",
                text:
                  this.ProfileName +
                  "\n" +
                  this.getStartEnd(this.start, this.end),
                style: "header",
              },
            ],
          },

          {
            alignment: "center",
            columns: [
              { width: 60, text: "" },
              this.genTablePDF(rows1, [rows2]),
              { width: 60, text: "" },
            ],
          },
          { text: "", fontSize: 20, pageBreak: "after" },

          {
            alignment: "center",
            columns: [
              {
                text: heading_6,
                style: "header",
              },
            ],
          },
          {
            alignment: "center",
            columns: [
              { width: 60, text: "" },
              {
                image: canvasImg_6,
                width: canvas_6_width,
              },
            ],
          },

          { text: "", fontSize: 20, pageBreak: "after" },

          {
            columns: [
              { width: 60, text: "" },
              this.genTablePDF(this.tableHeader, this.table_6, [
                200,
                "*",
                "*",
                "*",
                "*",
              ]),
              { width: 60, text: "" },
            ],
          },

          { text: "", fontSize: 20, pageBreak: "after" },
          {
            alignment: "center",
            columns: [
              {
                text: heading_7,
                style: "header",
              },
            ],
          },
          {
            alignment: "center",
            columns: [
              { width: 60, text: "" },
              {
                image: canvasImg_7,
                width: canvas_7_width,
              },
            ],
          },
          { text: "", fontSize: 20, pageBreak: "after" },

          {
            columns: [
              { width: 60, text: "" },
              this.genTablePDF(this.tableHeader, this.table_7, [
                200,
                "*",
                "*",
                "*",
                "*",
              ]),
              { width: 60, text: "" },
            ],
          },
        ];

        var options = { orientation: orientation };

        var filename = this._getExportedFileName(".pdf");

        this.gaEvent("pdf", filename);

        this.writeLog("summary", [
          { key: "dbName", value: this.params.dbName },
          { key: "remark", value: filename + " [" + orientation + "]" },
          { key: "email", value: this.email },
          { key: "originalURL", value: location.href },
        ]);

        await this.printPDF(content, filename, options);
      }
    },

    onPageChange(page) {
      this.page = page;
      this.loadAsyncData();
    },

    _clearEmail() {
      this.emailxlsx = "";
    },

    async _sendEmail() {
      this.params = this.getURLParams();
      var iquuid = this.readLocalStorage("iquuid2", this.params.dbName);
      this.$store.commit("setPageBack", this.params);
      if (iquuid == null) {
        this.gotoVerifyEmail();
      } else {
        this.params = this.getURLParams();
        this.params["email"] = await this.getUserEmail();
        this.params["emailTo"] = this.emailxlsx;
        this.params["order_by"] = "QueryName asc, DisplayTime asc";
        this.params["ProfileID"] = this.oProfile.data[0].ProfileID;

        this.params["logType"] = "summary";
        this.params["profileName"] = this.ProfileName;
        this.params["periodName"] =
          moment(this.start.substr(0, 8)).format("YYYYMMDD") +
          " - " +
          moment(this.end.substr(0, 8)).format("YYYYMMDD");

        this.excelFile.name = this._getExportedFileName(".xlsx");

        this.params["excelFile"] = this.excelFile.name;
        this.params["iquuid"] = this.readLocalStorage(
          "iquuid2",
          this.params.dbName
        );
        this.params["BrowserID"] = this.getBrowserID();
        this.params["start"] = this.start;
        this.params["end"] = this.end;

        this.isExcelModalActive = false;
        this.toast(
          "success",
          "InfoQuest 360 is now checking the sending permission, please wait..."
        );

        await this.httpRequest("Dashboard/checkPermission", this.params).then(
          () => {
            if (this.$result.data.result == 200) {
              this.toast("success", "Email validation has passed.");

              this.httpRequest(
                "Dashboard/createJobExportExcel",
                this.params,
                "Checking whether the data exist or not. The process of preparing the exported file may takes a few minuites (if any)."
              ).then(() => {
                if (this.$result.data.result == 200) {
                  this.toast("success", this.$result.data.message);
                } else {
                  this.toast("danger", this.$result.data.message);
                }
              });
            } else {
              this.toast("danger", this.$result.data.message);
            }
          }
        );
      }
    },

    async _exportExcel() {
      this.params = this.getURLParams();
      var iquuid = this.readLocalStorage("iquuid2", this.params.dbName);
      this.$store.commit("setPageBack", this.params);
      if (iquuid == null) {
        this.gotoVerifyEmail();
      } else {
        this._getLabelFields();
        this.emailxlsx = await this.getUserEmail();
        this.isExcelModalActive = true;
      }
    },

    _getExportedFileName(extension = ".csv") {
      var start = moment(this.start.substr(0, 8)).format("YYYYMMDD");
      var end = moment(this.end.substr(0, 8)).format("YYYYMMDD");

      var filename =
        // "Summary_" +
        this.oProfile.data[0].ProfileName.replace(/([ /:])/g, "_").trim() +
        "_" +
        start +
        "-" +
        end +
        extension;
      return filename;
    },

    async _exportCSV() {
      this.params = this.getURLParams();
      var iquuid = this.readLocalStorage("iquuid2", this.params.dbName);
      this.$store.commit("setPageBack", this.params);

      if (iquuid == null) {
        this.gotoVerifyEmail();
      } else {
        this.isLoading = true;
        var elements = this.$route.path.split("/");

        var filename = this._getExportedFileName(".csv");
        this.CSVName = filename;

        this.params["order_by"] = "QueryName asc, DisplayTime asc";
        this.params["iquuid"] = this.readLocalStorage(
          "iquuid2",
          this.params.dbName
        );
        this.params["ProfileID"] = this.oProfile.data[0].ProfileID;
        this.params["start"] = this.start;
        this.params["end"] = this.end;
        if (this.addColumn !== null && this.addColumn !== undefined) {
          this.params["addColumn"] = this.addColumn;
        }

        return await this.httpRequest(
          "Dashboard/getDataCSV/0",
          this.params
        ).then(() => {
          this.gaEvent("csv", filename);

          this.writeLog("summary", [
            { key: "dbName", value: elements[2] },
            { key: "email", value: this.email },
            { key: "remark", value: filename },
            { key: "originalURL", value: location.href },
          ]);
          this.isCSVModalActive = false;

          var newColumn =
            this.$result.data.new_column !== null
              ? this.$result.data.new_column
              : [];
          if (newColumn.length > 0) {
            for (var i in newColumn) {
              this.jsonFields[newColumn[i]] = newColumn[i];
            }
          }

          if (this.addColumn !== null && this.addColumn !== undefined) {
            var ac = this.addColumn.split(",");

            var comp = [];
            for (var j in this.config.permissableAddColumn) {
              comp.push(j);
            }

            for (i in ac) {
              if (comp.indexOf(ac[i].toString().toLowerCase()) >= 0) {
                this.jsonFields[
                  this.config.permissableAddColumn[
                    ac[i].toString().toLowerCase()
                  ]
                ] =
                  this.config.permissableAddColumn[
                    ac[i].toString().toLowerCase()
                  ];
              }
            }
          }

          if (this.$result.data.data.items.length == 0) {
            this.astate = 1;
            // this.toast(
            //   "danger",
            //   "No data in the selected period [" + ses_ + " - " + see_ + "]"
            // );
          }
          this.isLoading = false;
          return this.$result.data.data.items;
        });
      }
      this.isLoading = false;
    },

    async _makePDFTableArray(table_rows) {
      var rows = [];

      this.tableHeader = [{ text: "Topic", alignment: "left" }];

      for (var ii in table_rows) {
        var row = [];
        row.push({ text: table_rows[ii]["QueryName"], alignment: "left" });
        if (Object.keys(table_rows[ii]).length > 0) {
          for (var j in this.config["$DataSource"]) {
            var alignment = "right";
            if (j == "QueryName") {
              alignment = "left";
            }

            row.push({
              text: table_rows[ii][j],
              alignment: alignment,
            });

            if (ii == 0) {
              this.tableHeader.push({
                text: this.config["$DataSource"][j]["name"],
                alignment: alignment,
              });
            }
          }
        }
        rows.push(row);
      }

      return rows;
    },

    async _getTable6(data) {
      this.table_6 = await this._makePDFTableArray(data.table);
    },

    async _getTable7(data) {
      this.table_7 = await this._makePDFTableArray(data.table);
    },

    _onSelectRow(row) {
      this.params = this.getURLParams();
      this.addColumn = this.$route.query.addcolumn;

      var a;
      a = [
        "report",
        this.params.dbName,
        row.TopicID.toLowerCase(),
        this.params.periodName,
      ];

      if (
        this.params.periodID !== null &&
        this.params.periodID !== undefined &&
        this.params.periodID.length > 0
      ) {
        a.push(this.params.periodID);
      }
      this.$router
        .push({
          path: "/" + a.join("/"),
        })
        .catch((err) => {
          throw err;
        });
    },

    _sortMention(a, b, c) {
      a = parseInt(a.Count.split(",").join(""), 10);
      b = parseInt(b.Count.split(",").join(""), 10);
      if (c) {
        return a > b ? 1 : a < b ? -1 : 0;
      } else {
        return a < b ? 1 : a > b ? -1 : 0;
      }
    },

    _sortPRValue(a, b, c) {
      a = parseInt(a.PRValue.split(",").join(""), 10);
      b = parseInt(b.PRValue.split(",").join(""), 10);
      if (c) {
        return a > b ? 1 : a < b ? -1 : 0;
      } else {
        return a < b ? 1 : a > b ? -1 : 0;
      }
    },

    _startDownload() {
      this.astate = 1;
      if (this.labelFields !== undefined) {
        for (var i in this.labelFields) {
          var lo = this.labelFields[i]["name"].toString();
          this.jsonFields["zzzz_" + lo] = lo;
        }
      }
    },

    _finishDownload() {
      this.astate = 1;
    },

    _getProfileName() {
      if (this.oProfile.data)
        this.ProfileName = this.oProfile.data[0].ProfileName;
    },

    _getLabelFields() {
      if (this.oProfile.data) {
        var profileid = this.oProfile.data[0].ProfileID;
        this.labelFields =
          this.config["$labelFields_" + profileid] !== undefined
            ? this.config["$labelFields_" + profileid]
            : [];
      }
    },

    _getIconColor(item) {
      return this.getIconColor(item);
    },

    _getPack(icon) {
      return icon.split(" ")[0];
    },

    isEmailValid: function () {
      if (typeof this.emailxlsx == "undefined") return false;

      if (this.emailxlsx.length == 0) {
        this.disabled = true;
        return false;
      } else {
        var reg =
          /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;
        if (this.emailxlsx.indexOf(",") > 0) {
          var e = this.emailxlsx.split(",");
          var r = [];

          e.map(function (v) {
            v = v.trim();
            r.push(reg.test(v));
          });

          if (r.indexOf(false) == -1) {
            this.disabled = false;
            return "has-success";
          } else {
            this.disabled = true;
            return "has-error";
          }
        } else {
          if (reg.test(this.emailxlsx)) {
            this.disabled = false;

            return "has-success";
          } else {
            this.disabled = true;
            return "has-error";
          }
        }
      }
    },

    isValidEmail: function () {
      if (this.disabled) return true;
      else return false;
    },

    _login() {
      this.gotoVerifyEmail();
    },

    _logout() {
      const params = this.getURLParams();
      this.deleteLocalStorage("iquuid2", params.dbName);
      this.deleteLocalStorage("iqgoauth2");
      this.$store.commit("setPageBack", params);
      this.writeLog("verifyemail", [
        {
          key: "originalURL",
          value: location.href,
        },
        { key: "remark", value: "logout" },
        { key: "email", value: this.email },
      ]);
      this.email = "";
      this.gotoVerifyEmail();
    },

    async _getInitialSummaryData() {
      this.params = this.getURLParams();

      this.params["isCleansing"] = 0;
      this.params["iquuid"] = this.readLocalStorage(
        "iquuid2",
        this.params.dbName
      );
      this.params["logType"] = "summary";

      await this.httpRequest(
        "Dashboard/getInitialSummaryData",
        this.params
      ).then(() => {
        if (
          this.$result.data.periods !== null &&
          this.$result.data.periods.data !== undefined &&
          this.$result.data.periods.data.length > 0
        ) {
          var res = this.$result.data;

          if (parseInt(res.result, 10) == 200) {
            this.oProfile = res.profile;

            this._getProfileName();
          } else {
            this.waitingText = res.message;
          }
        }
      });
    },

    async getSummaryReport(datasource = "") {
      this.params = this.getURLParams();
      var iquuid = this.readLocalStorage("iquuid2", this.params.dbName);
      this.$store.commit("setPageBack", this.params);
      if (iquuid == null) {
        this.gotoVerifyEmail();
      } else {
        if (this._isValidURL(this.params)) {
          this.isLoading = true;
          this.params.pageBack = "summary";
          this.params.page = this.params.pageBack;
          // this.waitingText = "Preparing data, please wait...";

          this.params["isCleansing"] = 0;
          if (datasource !== undefined) this.DataSource = datasource;
          if (this.DataSource.length > 0)
            this.params["DataSource"] = this.DataSource;
          this.params["TopicID"] = this.params["TopicID"]
            .toString()
            .substr(0, 6);
          this.params["reportID"] = this.params["TopicID"]
            .toString()
            .substr(0, 6);

          var se = this.getStartEndInterval(
            this.params.periodName,
            this.params.periodID
          );

          this.start = se.start;
          this.end = se.end;

          this.params.start = this.start;
          this.params.end = this.end;

          if (this.start !== undefined && this.end !== undefined) {
            await this.httpRequest(
              "Dashboard/getSummaryReport",
              this.params
            ).then((res1) => {
              res1 = this.$result.data;
              this.isFoundData = res1.foundData;
              this.oChart_6 =
                Array.isArray(res1.chart_6) && res1.chart_6.length == 0
                  ? { chartData: {} }
                  : res1.chart_6;

              this.oChart_7 =
                Array.isArray(res1.chart_7) && res1.chart_7.length == 0
                  ? { chartDAta: {} }
                  : res1.chart_7;
              this.oStatsCard = res1.statsCard;
              this.oTopics = res1.topics;
              // setTimeout(() => {
              //   this.isLoading = false;
              // }, 3000);
            });
          }
        } else {
          var topics = [];
          for (var i in this.oProfile.data) {
            topics.push(this.oProfile.data[i].ProfileID);
          }
          this.gotoDefaultPage(true, topics);
        }
      }

      this.astate = 1;
      // this.isLoading = false;
    },

    //async loadExportHistory() {
      
      // console.log("loadExportHistory");
      
      // // let key = encHex.parse(this.hexkey);
      // // let iv = encHex.parse(this.hexiv);

      // //             this.hexkey = config.$HexKey;
      // //       this.hexiv = config.$HexIV;

      // // console.log("this.$prefixAuthKey", this.$prefixAuthKey);
      // // console.log("key", this.hexkey);
      // // console.log("iv", this.hexiv);

      // var encrypted = "";
      // // var encrypted = aes
      // //   .encrypt(this.$prefixAuthKey + "-" + Date.now(), key, {
      // //     iv: iv,
      // //     padding: padZeroPadding,
      // //   })
      // //   .toString();
      
      // //var params = { AuthKey: encrypted , };
      // var params = this.getURLParams();
      // params["AuthKey"] = encrypted;


      // await this.httpRequest("Dashboard/getNotification", params)
      //   .then((res) => {
          
      //     res = this.$result.data;
      //     this.data = [];
      //     // let currentTotal = res.databases.length;
      //     // if (res.total_results / this.perPage > 1000) {
      //     //   currentTotal = this.perPage * 1000;
      //     // }
      //     // this.total = currentTotal;

      //     // this.paginated = true;

      //     let db = [];
      //     // res.databases.map((v) => {
      //     //   db.push(v);
      //     // });

      //     //console.log("this.$result",this.$result);
      //     console.log("res",res.data);

      //     let alert_count = res.data.filter(x => x.status === "0");
      //     console.log("alert count", alert_count.length);

      //     this.data = db;
      //     //this.isLoading = false;
      //   })
      //   .catch((error) => {
      //     this.data = [];
      //     this.total = 0;
      //     this.isLoading = false;
      //     throw error;
      //   });
    //},
  },

  async mounted() {
    this.isLoading = true;
  },
  async created() {
    try {  

      await this.getServerConfig().then((res) => {
        this.config = res;
        this.eulaurl = this.config.EULAURL;
        this.companyurl = this.config.CompanyURL;
        this.contactusurl = this.config.ContactUsURL;
      });

      this.params = this.getURLParams();

      this.isLogin = false;
      this.isFoundData = true;
      var iquuid = this.readLocalStorage("iquuid2", this.params.dbName);
      var BrowserID = this.getBrowserID();
      this.$store.commit("setPageBack", this.params);
      if (iquuid == null) {
        this.gotoVerifyEmail();
      } else {
        if (await this.isValidDatabase()) {
          await this._getInitialSummaryData();
          if (await this._isValidURL(this.params)) {
            this.params.pageBack = "summary";
            this.params.page = this.params.pageBack;
            var res = await this.checkPermission(
              iquuid,
              BrowserID,
              this.params.page
            );

            var _continue = res.data.continue;
            var _goto = res.data.goto;
            var _clearUUID = res.data.clearUUID;

            if (_continue) {
              this.email = await this.getUserEmail();
              if (this.email.length > 0) this.emailxlsx = this.email;

              await this.getSummaryReport(this.DataSource);
              await this.gaPage();

              this.addColumn = this.$route.query.addcolumn;
              if (
                (this.params.periodID !== null &&
                  this.params.periodID !== undefined &&
                  this.params.periodID.toLowerCase() == "csv") ||
                (this.params.csv !== undefined &&
                  this.params.csv !== null &&
                  this.params.csv.toLowerCase() == "csv")
              ) {
                if (this._checkPermissableAddColumn() == true) {
                  this.isLoading = true;
                  var elem = this.$refs.myBtn;
                  await elem.generate();

                  var a = [
                    this.params.page,
                    this.params.dbName,
                    this.params.TopicID,
                    this.params.periodName,
                  ];

                  if (this.params.periodID.toLowerCase() !== "csv") {
                    a.push(this.params.periodID);
                  }
                  this.$router.push("/" + a.join("/")).catch((err) => {
                    throw err;
                  });

                  this.isLoading = false;
                } else {
                  this.toast(
                    "danger",
                    "Unknown column of [" + this.$route.query.addcolumn + "]"
                  );
                }
              }
            } else {
              if (_goto == 401 && _clearUUID == true) {
                this.deleteLocalStorage("iquuid2", this.params.dbName);
              }

              if (_goto == "verifyemail") {
                this.gotoVerifyEmail();
              } else {
                this.astate = 2;
                this.toast(
                  "danger",
                  "Restricted section. Only valid email can visit this page."
                );
              }
              this.isLoading = false;
            }
          } else {
            var topics = [];
            for (var i in this.oProfile.data) {
              topics.push(this.oProfile.data[i].ProfileID);
            }
            this.gotoDefaultPage(true, topics);
          }
        } else {
          this.redirectHost(this.params);
        }
      }
    } catch (err) {
      throw err;
    }
  },

};
</script>

<style scoped>
.slot {
  background-color: #aaa;
  padding: 0.5rem;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.text-black {
  color: #000;
}
</style>