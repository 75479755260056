  <template>
  <div>
    <b-navbar class="is-fixed-top">
      <template slot="brand">
        <b-navbar-item tag="router-link" :to="{ path: '' }">
          <a class="navbar-item" @click.prevent="_gotoSummaryPage()">
            <img src="../assets/img/io_logo.png" :alt="$websiteDescription" />
          </a>
        </b-navbar-item>
      </template>
    </b-navbar>
    <div class="_wrapper">
      <div>
        <div class="card" v-if="state == 0">
          <header class="card-header">
            <p class="card-header-title">{{ waitingText }}</p>
          </header>
          <div class="card-content">
            <div class="content"></div>
          </div>
        </div>
        <div class="card" v-if="this.state == 0.5">
          <div class="card-content">
            <div class="content">
              <div class="columns" v-if="this.cookieEnabled">
                <div class="column is-1"></div>
                <div class="column has-text-left">
                  It usually takes a few seconds to complete, and you will be
                  redirected to email verification process. If you are waiting
                  for more than 10 seconds, please refresh your browser.
                </div>
                <div class="column is-1"></div>
              </div>
              <div class="columns" v-if="this.cookieEnabled">
                <div class="column is-1"></div>
                <div class="column is-10">
                  <b-loading
                    :is-full-page="isFullPage"
                    :active.sync="isLoading"
                    :can-cancel="true"
                  ></b-loading>
                </div>

                <div class="column is-1"></div>
              </div>
              <div class="field is-grouped is-grouped-centered" v-else>
                <p class="level-item">
                  <label>
                    <p class="card-header">In Chrome</p>
                    <ol>
                      <li>On your computer, open Chrome.</li>
                      <li>
                        At the top right, click More Moreand then
                        <strong>Settings</strong>.
                      </li>
                      <li>
                        At the bottom, click
                        <strong>Advanced</strong>.
                      </li>
                      <li>
                        Under "Privacy and security," click
                        <strong>Site settings</strong>.
                      </li>
                      <li>
                        Click
                        <strong>Cookies</strong>.
                      </li>
                      <li>From here, you can:</li>
                      <blockquote>
                        <ul>
                          <li>
                            Turn on cookies: Next to "Blocked," turn on the
                            switch.
                          </li>
                          <li>
                            Turn off cookies: Turn off
                            <strong
                              >Allow sites to save and read cookie data</strong
                            >.
                          </li>
                        </ul>
                      </blockquote>
                    </ol>
                    <a
                      href="https://support.google.com/chrome/answer/95647"
                      target="_blank"
                      >Learn how to change more cookie settings in Chrome.</a
                    >
                  </label>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="card" v-if="this.state == 1">
          <header class="card-header">
            <p class="card-header-title">Step 1: Verify Email</p>
          </header>
          <div class="card-content">
            <div class="content">
              <div class="columns">
                <div class="column"></div>
                <div class="column is-two-fifths">
                  Please enter the email address registered with InfoQuest 360
                </div>
                <div class="column"></div>
              </div>
              <div class="columns">
                <div class="column"></div>
                <div class="column is-two-fifths">
                  <div class="columns">
                    <div class="column">
                      <div
                        :class="
                          (['column is-8', isEmailValid()], 'has-text-centered')
                        "
                      >
                        <b-field :label-position="labelPosition">
                          <b-input
                            type="email"
                            ref="email"
                            required
                            maxlength="60"
                            placeholder="Enter your email"
                            v-model="email"
                            @input="resetInterval"
                          ></b-input>
                        </b-field>
                      </div>
                    </div>
                  </div>
                  <div class="columns">
                    <div class="column">
                      <div v-if="isSending == false">
                        <div v-if="step == 0">
                          <b-button
                            id="btnSendEmail"
                            ref="btnSendEmail"
                            :disabled="isValidEmail()"
                            class="centered"
                            type="is-primary"
                            @click.prevent="SendEmail"
                            >Send Verification Email</b-button
                          >
                        </div>
                        <div v-else-if="step == 1">
                          <b-button
                            id="btnReSendEmail"
                            ref="btnSendEmail"
                            :disabled="isValidEmail()"
                            class="centered"
                            type="is-success"
                            @click.prevent="SendEmail"
                            >Resend verification email</b-button
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="columns">
                    <div class="column">
                      <div class="divider">OR</div>
                    </div>
                  </div>

                  <div class="columns is-desktop">
                    <div class="column">
                      <b-button
                        id="btnLoginGoogle"
                        ref="btnLoginGoogle"
                        icon-left="google"
                        pack="fab"
                        class="btn-google"
                        @click.prevent="LoginGoogle"
                        >Sign in with Google</b-button
                      >
                    </div>
                    <div class="column">
                      <b-button
                        id="btnLoginMicrosoft"
                        ref="btnLoginMicrosoft"
                        type="is-dark"
                        @click.prevent="LoginMicrosoft"
                        ><div style="display: flex; justify-content: center">
                          <div>
                            <b-image
                              :src="require('@/assets/img/ms.png')"
                              style="
                                margin-left: -7px;
                                margin-top: 5px;
                                margin-right: 5px;
                                width: 24px;
                              "
                            ></b-image>
                          </div>
                          <div style="margin-top: 5px">
                            Sign in with Microsoft
                          </div>
                        </div></b-button
                      >
                    </div>
                  </div>
                </div>
                <div class="column"></div>
              </div>
            </div>
          </div>
        </div>

        <div class="card" v-if="this.state == 2">
          <header class="card-header">
            <p class="card-header-title">Step 2: Waiting for Activation</p>
          </header>
          <div class="card-content">
            <div class="content">
              <div class="columns">
                <div class="column"></div>
                <div class="column is-two-fifths">
                  The activation link has been sent to your email address.
                  Please activate your account by clicking the link.
                </div>
                <div class="column"></div>
              </div>
              <div class="columns">
                <div class="column"></div>
                <div class="column is-two-fifths">
                  <div class="columns">
                    <div class="column">
                      <div
                        :class="
                          (['column is-8', isEmailValid()], 'has-text-centered')
                        "
                      >
                        <b-field :label-position="labelPosition">
                          <b-input
                            type="email"
                            ref="email"
                            required
                            maxlength="60"
                            placeholder="Enter your email"
                            v-model="email"
                            @input="resetInterval"
                          ></b-input>
                        </b-field>
                      </div>
                    </div>
                  </div>
                  <div class="columns">
                    <div class="column">
                      <div v-if="isSending == false">
                        <div v-if="step == 0">
                          <b-button
                            id="btnSendEmail"
                            ref="btnSendEmail"
                            :disabled="isValidEmail()"
                            class="centered"
                            type="is-primary"
                            @click.prevent="SendEmail"
                            >Resend verification email</b-button
                          >
                        </div>
                        <div v-else-if="step == 1">
                          <b-button
                            id="btnReSendEmail"
                            ref="btnSendEmail"
                            :disabled="isValidEmail()"
                            class="centered"
                            type="is-success"
                            @click.prevent="SendEmail"
                            >Resend Verification Email</b-button
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="columns">
                    <div class="column">
                      <div class="divider">OR</div>
                    </div>
                  </div>

                  <div class="columns is-desktop">
                    <div class="column">
                      <b-button
                        id="btnLoginGoogle"
                        ref="btnLoginGoogle"
                        icon-left="google"
                        pack="fab"
                        class="btn-google"
                        @click.prevent="LoginGoogle"
                        >Sign in with Google</b-button
                      >
                    </div>
                    <div class="column">
                      <b-button
                        id="btnLoginMicrosoft"
                        ref="btnLoginMicrosoft"
                        type="is-dark"
                        @click.prevent="LoginMicrosoft"
                        ><div style="display: flex; justify-content: center">
                          <div>
                            <img
                              src="../assets/img/ms.png"
                              width="24px"
                              style="
                                margin-left: -7px;
                                margin-top: 5px;
                                margin-right: 5px;
                              "
                            />
                          </div>
                          <div style="margin-top: 5px">
                            Sign in with Microsoft
                          </div>
                        </div></b-button
                      >
                    </div>
                  </div>
                </div>
                <div class="column"></div>
              </div>
            </div>
          </div>
        </div>

        <div class="card" v-if="this.state == 3">
          <header class="card-header">
            <p class="card-header-title">
              <span v-if="this.activationResult == 1"
                >Step 3: Browser Activated</span
              >
              <span v-else>Step 3: Fail to activate your Email</span>
            </p>
          </header>

          <div class="card-content">
            <div class="content">
              <div
                class="field is-horizontal"
                v-if="this.activationResult == 1"
              >
                <label>
                  Congratulations! Your browser has been activated and you may
                  now start using it. Click the link below to start:
                </label>
                <br />
                <br />
                <label>
                  <a v-bind:href="''">{{ this.cleansingURL }}</a>
                </label>
              </div>

              <div class="field is-horizontal" v-else>
                <label>
                  The process of email activation has been done unsuccessfully.
                  Please contact administrator.
                </label>
              </div>
            </div>
          </div>
        </div>

        <div v-if="this.state == -1">
          <NotAuthorizeEditor></NotAuthorizeEditor>
        </div>
      </div>
      <Footer
        v-if="isLoading == false"
        :eulaurl="eulaurl"
        :contactusurl="contactusurl"
        :companyurl="companyurl"
      ></Footer>
    </div>
  </div>
</template>

<script>
import Footer from "../layout/Footer.vue";
import NotAuthorizeEditor from "../pages/NotAuthorizeEditor.vue";

export default {
  name: "verifyEmailPage",
  components: {
    Footer,
    NotAuthorizeEditor,
  },
  props: {},
  data() {
    return {
      eulaurl: "",
      companyurl: "",
      contactusurl: "",
      waitingText: "",
      isLoading: true,
      isFullPage: true,

      disabled: true,
      labelPosition: "on-border",

      state: 1,
      activationResult: false,
      step: 0,
      email: "",
      isSending: false,
      cookieEnabled: true,
      cleansingURL: "",
      timer: "",

      oProfile: {},
      oTopics: {},
      oPeriod: {},
    };
  },

  async created() {
    this.waitingText = "Preparing data, please wait...";
    this.state = 0;
    await this.getServerConfig().then((config) => {
      this.eulaurl = config.EULAURL;
      this.companyurl = config.CompanyURL;
      this.contactusurl = config.ContactUsURL;
    });

    var params = this.getURLParams();
    if (await this.isValidDatabase()) {
      this.isLoading = false;
      this.isLogin = false;

      await this._requestUUID();
      this.timer = setInterval(() => {
        this._requestUUID();
      }, 10000);

      await this._getInitialData();
    } else {
      this.redirectHost(params);
    }
  },

  beforeDestroy: function () {
    clearInterval(this.timer);
  },
  watch: {},
  methods: {
    async _getInitialData() {
      const params = this.getURLParams();
      params["isCleansing"] = 0;
      params["iquuid"] = this.readLocalStorage("iquuid2", params.dbName);
      params["logType"] = "report";
      params["start"] = this.start;
      params["end"] = this.end;

      await this.httpRequest("Dashboard/getInitialData", params).then((res) => {
        if (
          this.$result.data.periods !== null &&
          this.$result.data.periods.data !== undefined &&
          this.$result.data.periods.data.length > 0
        ) {
          res = this.$result.data;
          if (res.result == 200) {
            this.$store.commit("setInitialData", res);
            this.oPeriod = [];
            for (var i in res.periods.data) {
              var a = res.periods.data[i];
              a.PeriodName = a.PeriodName.split("/")[1];
              this.oPeriod.push(a);
            }
            this.oTopics = res.topics.data[0].Query;
            this.oProfile = res.profile;
            this.oProfileName = res.profile.data[0].ProfileName;

            this.selectedTopicID =
              this.$store.state.pageBack[params.dbName] !== undefined &&
              this.$store.state.pageBack[params.dbName].TopicID !== null
                ? this.$store.state.pageBack[params.dbName].TopicID
                : Object.keys(this.oTopics)[0];
            this.selectedTopicName = this.oTopics[this.selectedTopicID]
              ? this.oTopics[this.selectedTopicID].QueryName
              : "";
          } else {
            this.waitingText = res.message;
          }
        }
      });
    },

    async _getInitialSummaryData() {
      this.params = this.getURLParams();

      this.params["isCleansing"] = 0;
      this.params["iquuid"] = this.readLocalStorage(
        "iquuid2",
        this.params.dbName
      );
      this.params["logType"] = "summary";

      await this.httpRequest(
        "Dashboard/getInitialSummaryData",
        this.params
      ).then(() => {
        if (
          this.$result.data.periods !== null &&
          this.$result.data.periods.data !== undefined &&
          this.$result.data.periods.data.length > 0
        ) {
          var res = this.$result.data;

          if (parseInt(res.result, 10) == 200) {
            this.oProfile = res.profile;
          } else {
            this.waitingText = res.message;
          }
        }
      });
    },

    _gotoSummaryPage() {
      var b = this.getURLParams();
      var item = {
        row: { DatabaseName: b.dbName, ProfileID: b.TopicID },
      };

      this.gotoSummary(item);
    },

    resetInterval() {
      clearInterval(this.timer);
      this.timer = setInterval(() => {
        this._requestUUID();
      }, 10000);
    },

    async LoginGoogle() {
      this.email = "";
      this.disabled = true;
      var params = this.getURLParams();

      params.page =
        this.$store.state.pageBack[params.dbName] !== undefined &&
        this.$store.state.pageBack[params.dbName] !== null
          ? this.$store.state.pageBack[params.dbName].page
          : "summary";

      params.pageBack = params.page;

      if (params.page.toLowerCase() == "summary") {
        params.TopicID = params.TopicID.substr(0, 6);
        params.reportID = params.reportID.substr(0, 6);
      }

      clearInterval(this.timer);
      this.gotoGOAuth("verifyemail", params);
    },

    async LoginMicrosoft() {
      this.email = "";
      this.disabled = true;
      var params = this.getURLParams();

      params.page =
        this.$store.state.pageBack[params.dbName] !== undefined &&
        this.$store.state.pageBack[params.dbName] !== null
          ? this.$store.state.pageBack[params.dbName].page
          : "summary";
      params.pageBack = params.page;
      params.authType = "ms";

      if (params.page.toLowerCase() == "summary") {
        params.TopicID = params.TopicID.substr(0, 6);
        params.reportID = params.reportID.substr(0, 6);
      }

      clearInterval(this.timer);
      this.gotoGOAuth("verifyemail", params);
    },

    async SendEmail() {
      this.params = this.getURLParams();
      var iquuid = this.readLocalStorage("iquuid2", this.params.dbName);

      if (iquuid == null) {
        this.gotoVerifyEmail();
      } else {
        var BrowserID = this.getBrowserID();

        this.params["BrowserID"] = BrowserID;
        this.params["iquuid"] = iquuid;
        this.params["email"] = this.email;
        this.params["pageBack"] =
          this.$store.state.pageBack[this.params.dbName] !== undefined &&
          this.$store.state.pageBack[this.params.dbName] !== null
            ? this.$store.state.pageBack[this.params.dbName].page
            : "summary";
        this.params["logType"] = "verifyemail";
        await this.httpRequest(
          "Dashboard/isValidEmailGoAuth",
          this.params
        ).then(() => {
          if (this.$result.data.result == 200) {
            this.httpRequest("Dashboard/sendConfirmEmail", this.params).then(
              () => {
                if (this.$result.data.result == 200) {
                  this.toast("success", this.$result.data.message);
                } else {
                  this.toast("danger", this.$result.data.message);
                }
              }
            );
          } else {
            this.toast("danger", this.$result.data.message);
            this.gotoVerifyEmail();
          }
        });
      }
    },

    async _requestUUID() {
      var resActivate = await this.requestUUID().then((res) => {
        if (res.state == 2) {
          this.email = res.email;
        }
        return res;
      });

      if (resActivate && resActivate.state !== 2) {
        this.activationResult = resActivate.activationResult;
        this.params = this.getURLParams();
        var iquuid = this.readLocalStorage("iquuid2", this.params.dbName);

        var BrowserID = this.getBrowserID();

        var res = await this.checkPermission(
          iquuid,
          BrowserID,
          this.$store.state.pageBack[this.params.dbName] !== undefined &&
            this.$store.state.pageBack[this.params.dbName] !== null
            ? this.$store.state.pageBack[this.params.dbName].page
            : "summary"
        );

        var _continue = res.data.continue;
        var _goto = res.data.goto;
        var _clearUUID = res.data.clearUUID;

        if (this.params.TopicID.length == 6) {
          _goto = "summary";
        }
 
        var p = "";
        if (_continue) {
          p =
            this.params.periodID !== null
              ? [
                  _goto,
                  this.params.dbName,
                  this.params.TopicID,
                  this.params.periodName,
                  this.params.periodID,
                ]
              : [
                  _goto,
                  this.params.dbName,
                  this.params.TopicID,
                  this.params.periodName,
                ];
          p = "/" + p.join("/");
          this.goto(p);
        } else {
          this.state = 1;
          if (_goto == 401 && _clearUUID == true) {
            this.deleteLocalStorage("iquuid2", this.params.dbName);
          }
        }
      } else {
        this.state = resActivate.state;
      }
    },

    isValidEmail: function () {
      if (this.disabled) return true;
      else return false;
    },

    isEmailValid: function () {
      if (typeof this.email == "undefined") return false;

      if (this.email.length == 0) {
        this.disabled = true;
        return false;
      } else {
        var reg =
          /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;
        if (this.email.indexOf(",") > 0) {
          var e = this.email.split(",");
          var r = [];

          e.map(function (v) {
            v = v.trim();
            r.push(reg.test(v));
          });

          if (r.indexOf(false) == -1) {
            this.disabled = false;
            return "has-success";
          } else {
            this.disabled = true;
            return "has-error";
          }
        } else {
          if (reg.test(this.email)) {
            this.disabled = false;
            return "has-success";
          } else {
            this.disabled = true;
            return "has-error";
          }
        }
      }
    },
  },
};
</script>

<style scoped>
.card-header-title {
  font-size: 1.5em !important;
}

.card-content {
  min-height: 75vh;
}
</style>

<style>
.divider {
  background: white;
  text-align: center;
  /* font-family: "Montserrat", sans-serif; */
  font-weight: 600;
  color: #333;
  /* font-size: 40px; */
  /* padding: 50px; */
  max-width: 100%;
  margin: 10px auto 0;
}
</style>