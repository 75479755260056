import Vue from "vue";
import App from "./App.vue";
import Buefy from "buefy";
import "buefy/dist/buefy.css";

import "./assets/app.css";
import "./assets/app.scss";

import config from "./config.js";
import routes from "./route.js";
import store from "./store";
import axios from "axios";
import VueRouter from "vue-router";
import moment from "moment";
import CryptoJS from "crypto-js";
var _ = require("lodash");
import VueCookies from "vue-cookies-ts";
// import VueAnalytics from "vue-analytics";
//import VueGtag from 'vue-gtag'

import htmlToPdfMake from "html-to-pdfmake";

import vueFilterPrettyBytes from "vue-filter-pretty-bytes";
import VueWorker from "vue-worker";
import aes from "crypto-js/aes";
import encHex from "crypto-js/enc-hex";
import padZeroPadding from "crypto-js/pad-zeropadding";
import VCalendar from "v-calendar";
// import { cookies } from "vue/types/umd";

Vue.use(VCalendar, {
    componentPrefix: "vc" // Use <vc-calendar /> instead of <v-calendar />
});

Vue.use(VueCookies);
Vue.use(Buefy);
Vue.use(VueRouter);
Vue.use(vueFilterPrettyBytes);
Vue.filter("formatDate", function(value) {
    if (value) {
        return moment(String(value)).format("YYYY-MM-DD hh:mm:ss");
    }
});
Vue.use(VueWorker);

const router = new VueRouter({
    routes,
    linkActiveClass: "active",
    mode: "history"
});

router.beforeEach((to, from, next) => {
    var from1 = from.path.split("/")[1];

    if (from1 == "cleansingv2") {
        if (
            parseInt(Vue.prototype.$sumIncomplete.value, 10) > 0 ||
            Vue.prototype.$sumIncomplete.value.length > 0
        ) {
            var ret = window.confirm(
                "You still have " +
                    Vue.prototype.$sumIncomplete.value +
                    " items unreviewed. Proceed?"
            );

            if (ret) {
                next();
            } else {
                next(false);
            }
        } else {
            next();
        }
    } else {
        next();
    }
});

// Vue.use(VueAnalytics, {
//     id: "UA-115382316-3",
//     router
// });

// Vue.use(VueAnalytics, {
//     id: "G-4RBSZSKXQQ",
//     router
// });

// Vue.use(VueGtag, {
//     config: {
//         id: 'G-4RBSZSKXQQ' 
//     },
// }, router);

// Vue.use(VueGtag, {
//     config: {id: 'G-4RBSZSKXQQ' }
// });


Vue.prototype.$prefixAuthKey = "iq360";
Vue.prototype.$websiteName = config.config.$websiteName;
Vue.prototype.$websiteDescription = config.config.$websiteDescription;
Vue.prototype.$version = config.config.$version;
Vue.prototype.$latest_update = config.config.$latest_update;
Vue.prototype.$speedVerifyCleansing = config.config.$speedVerifyCleansing;

Vue.prototype.$databasePath =
    config.config.$is_production == true
        ? window.location.protocol +
          "//" +
          window.location.hostname +
          "/" +
          config.config.$api_url
        : window.location.protocol +
          "//" +
          window.location.hostname +
          ":8080/" +
          config.config.$dev_api_url;

Vue.prototype.$dbName = "";
Vue.prototype.$colorScheme = config.config.$colorScheme;
Vue.prototype.$colorScheme1 = config.config.$colorScheme1;
Vue.prototype.$tableIcon = {
    printmedia: {
        icon: "far fa-newspaper",
        color: "#FFE049"
    },
    print: {
        icon: "far fa-newspaper",
        color: "#FFE049"
    },
    newspaper: {
        icon: "far fa-newspaper",
        color: "#FFE049"
    },
    magazine: {
        icon: "fa fa-book-open",
        color: "#D2AE00"
    },

    online: {
        icon: "fa fa-globe",
        color: "#FF9B49"
    },
    onlinemedia: {
        icon: "fa fa-globe",
        color: "#FF9B49"
    },
    site: {
        icon: "fa fa-globe",
        color: "#FF9B49"
    },
    website: {
        icon: "fa fa-globe",
        color: "#FF9B49"
    },
    webclip: {
        icon: "fa fa-globe",
        color: "#FF9B49"
    },

    social: {
        icon: "fa fa-users",
        color: "#D25F00"
    },
    socialmedia: {
        icon: "fa fa-users",
        color: "#D25F00"
    },
    voice: {
        icon: "mdi mdi-voice",
        color: "grey"
    },
    facebook: {
        icon: "fab fa-facebook-square",
        color: "#3b5998"
    },
    twitter: {
        icon: "fab fa-twitter-square",
        color: "#00acee"
    },
    youtube: {
        icon: "fab fa-youtube-square",
        color: "#c4302b"
    },
    instagram: {
        icon: "fab fa-instagram",
        color: "#3f729b"
    },
    line: {
        icon: "fab fa-line",
        color: "#00BD00"
    },
    tiktok: {
        icon: "fab fa-tiktok",
        color: "#495057"
    },
    other: {
        icon: "fas fa-compact-disc",
        color: "#f51f"
    }
};

Vue.prototype.$tableHeader = [
    "QueryName",
    "Newspaper",
    "Magazine",
    "OnlineNews",
    "SocialMedia"
];

Vue.prototype.$pdfMakeFonts = {
    THSarabunNew: {
        normal: "THSarabunNew.ttf",
        bold: "THSarabunNew-Bold.ttf",
        italics: "THSarabunNew-Italic.ttf",
        bolditalics: "THSarabunNew-BoldItalic.ttf"
    },
    FontAwesome: {
        normal: "FontAwesome.ttf",
        bold: "FontAwesome.ttf",
        italics: "FontAwesome.ttf",
        bolditalics: "FontAwesome.ttf"
    },
    Roboto: {
        normal: "Roboto-Regular.ttf",
        bold: "Roboto-Medium.ttf",
        italics: "Roboto-Italic.ttf",
        bolditalics: "Roboto-MediumItalic.ttf"
    }
};

Vue.prototype.$cleansingEmail = "";
Vue.prototype.$sumIncomplete = {
    value: ""
};

Vue.mixin({
    data: function() {
        return {
            $sic: 0
        };
    },
    methods: {
        async redirectHost(params = {}) {
            var message = "";
            if (Object.keys(params).length == 0) {
                message = "Invalid parameters to proceed";
                this.toast("danger", message);
                this.writeLog("action", [
                    {
                        key: "dbName",
                        value: params.dbName
                    },
                    {
                        key: "email",
                        value: params.email
                    },
                    {
                        key: "remark",
                        value: message + " [" + JSON.stringify(params) + "]"
                    },
                    {
                        key: "originalURL",
                        value: location.href
                    }
                ]);
            }

            if (Object.keys(params).length > 0) {
                return await this.httpRequest(
                    "Dashboard/getRedirect",
                    params
                ).then(() => {
                    var res = this.$result.data;
                    var p = this.getURLParams();
                    if (res.success) {
                        this.toast("success", res.message);
                        var a = [
                            res.Server,
                            p.page,
                            p.dbName,
                            p.reportID.toLowerCase(),
                            p.periodName,
                            p.periodID
                        ];
                        setTimeout(() => {
                            window.location.href = a.join("/");
                        }, 3000);
                    } else {
                        this.toast("danger", res.message);
                        this.gotoNotFound(p.dbName);
                    }
                });
            }
        },

        intersect(a, b) {
            var setA = new Set(a);
            var setB = new Set(b);
            var intersection = new Set([...setA].filter(x => setB.has(x)));
            return Array.from(intersection);
        },

        alert(a) {
            alert(JSON.stringify(a));
        },

        isValidPeriod(params) {
            var start = "";
            var end = "";
            var b = false;

            var se = this.getStartEndInterval(
                params.periodName.toLowerCase(),
                params.periodID
            );

            switch (params.periodName.toLowerCase()) {
                case "monthly":
                    if (params.periodID.toString().toLowerCase() == "latest") {
                        b = true;
                    }
                    break;

                case "lastmonth":
                case "past7days":
                case "past30days":
                case "yesterday":
                case "today":
                    end = se.end;
                    start = se.start;
                    b = true;

                    break;

                case "custom":
                    if (
                        params.periodID !== null &&
                        params.periodID.length > 0
                    ) {
                        var start_time = params.periodID.split("-");
                        if (start_time.length == 2) {
                            var _start = start_time[0];
                            var _end = start_time[1];
                            if (
                                moment(_start.substr(0, 8))
                                    .format("D")
                                    .toLowerCase() !== "invalid date" &&
                                moment(_end.substr(0, 8))
                                    .format("D")
                                    .toLowerCase() !== "invalid date"
                            ) {
                                b = true;
                                start = _start;
                                end = _end;
                            }
                        } else {
                            if (
                                params.periodID !== null &&
                                params.periodID.toLowerCase() == "latest"
                            ) {
                                b = true;
                                start = moment()
                                    .startOf("month")
                                    .startOf("day")
                                    .format("YYYYMMDDHHmmss");
                                end = moment()
                                    .endOf("month")
                                    .endOf("day")
                                    .format("YYYYMMDDHHmmss");
                            }
                        }
                    }
                    break;
            }

            return {
                result: b,
                start: start,
                end: end
            };
        },
        isMobile() {
            if (
                /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                    navigator.userAgent
                )
            ) {
                return true;
            } else {
                return false;
            }
        },
        async isValidDatabase() {
            var params = this.getURLParams();
            return await this.httpRequest(
                "Dashboard/isValidDatabase",
                params
            ).then(() => {
                var res = this.$result.data;

                if (res.result == 200) {
                    return true;
                } else {
                    return false;
                }
            });
        },

        makeCamelCase(items) {
            var a = [];
            _.forEach(items, function(v) {
                a.push(
                    v
                        .replace(/([A-Z])/g, " $1")
                        // uppercase the first character
                        .replace(/^./, function(str) {
                            return str.toUpperCase();
                        })
                );
            });
            return a;
        },

        cleanText(input) {
            var result = "";
            if (input !== null && input !== undefined) {
                if (input.length == 0) result = input;
                for (
                    var indexOfInput = 0, lengthOfInput = input.length;
                    indexOfInput < lengthOfInput;
                    indexOfInput++
                ) {
                    var charAtSpecificIndex = input[indexOfInput].charCodeAt(0);
                    // if ((32 <= charAtSpecificIndex) && (charAtSpecificIndex <= 126)) {
                    if (32 <= charAtSpecificIndex) {
                        result += input[indexOfInput];
                    }
                }
                var regex = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;
                return result.replace(regex, "");
            } else {
                return result;
            }
        },
        toTop() {
            scrollTo(0, 0);
        },
        timeStamp2Time(UNIX_timestamp) {
            var a = new Date(UNIX_timestamp);
            var months = [
                "Jan",
                "Feb",
                "Mar",
                "Apr",
                "May",
                "Jun",
                "Jul",
                "Aug",
                "Sep",
                "Oct",
                "Nov",
                "Dec"
            ];
            var year = a.getFullYear();
            var month = months[a.getMonth()];
            month = a.getMonth() + 1;
            var date = a.getDate();
            var hour = a.getHours();
            var min = a.getMinutes();
            var sec = a.getSeconds();
            var time =
                year +
                "-" +
                month +
                "-" +
                date +
                " " +
                hour +
                ":" +
                min +
                ":" +
                sec;
            return moment(time).format("YYYY-MM-DD hh:mm");
        },
        isYearMonthDate(d) {
            var m = d.match(/^(\d{4})-(\d{1,2})-(\d{1,2})$/);
            return m ? new Date(m[1], m[2] - 1, m[3]) : null;
        },

        isYearMonth(d) {
            var m = d.match(/^(\d{4})-(\d{1,2})$/);
            return m ? new Date(m[1], m[2] - 1, 1) : null;
        },

        async openLink(RowID, url) {
            let email = await this.getUserEmail();
            let urlParams = new URLSearchParams();
            var elements = this.$route.path.split("/");
            var iquuid = this.readLocalStorage("iquuid2", elements[2]);
            urlParams.append("RowID", RowID);
            urlParams.append("dbName", this.dbName);
            urlParams.append("TopicID", elements[3]);
            urlParams.append("iquuid", iquuid);

            var newURL = "";

            if (
                this.$databasePath.indexOf("ttp:/") > 0 ||
                this.$databasePath.indexOf("ttps:/") > 0
            ) {
                newURL =
                    this.$databasePath +
                    "/Go/Now/" +
                    window.btoa(encodeURIComponent(url));
            } else {
                newURL =
                    document.location.protocol +
                    "//" +
                    document.location.hostname +
                    this.$databasePath +
                    "/Go/Now/" +
                    window.btoa(encodeURIComponent(url));
            }

            this.writeLog("action", [
                {
                    key: "newID",
                    value: RowID
                },
                {
                    key: "originalURL",
                    value: newURL
                },
                {
                    key: "dbName",
                    value: elements[2]
                },
                {
                    key: "email",
                    value: email
                },
                {
                    key: "remark",
                    value: url
                }
            ]);

            //window.open(newURL, "_blank", "noopener noreferrer");

            let a = document.createElement("a");
            a.target = "_blank";
            a.href = newURL;
            a.setAttribute("rel", "noopener noreferrer");
            a.click();

        },
        midnight() {
            var d = new Date();
            d.setHours(24, 0, 0, 0);
            return d.getTime();
        },
        async clearStatus(row) {
            var params = this.getURLParams();
            var iquuid = this.readLocalStorage("iquuid2", params.dbName);

            if (iquuid == null) {
                this.gotoVerifyEmail();
            } else {
                let key = encHex.parse(this.hexkey);
                let iv = encHex.parse(this.hexiv);

                var encrypted = aes
                    .encrypt(this.$prefixAuthKey + "-" + Date.now(), key, {
                        iv: iv,
                        padding: padZeroPadding
                    })
                    .toString();

                const params = this.getURLParams();
                params["AuthKey"] = encrypted;
                params["iquuid"] = this.readLocalStorage(
                    "iquuid2",
                    params.dbName
                );
                params["topicID"] = params["TopicID"];
                params["RowID"] = row.RowID;
                params["DisplayDate"] = row.DisplayDate;

                this.loading = true;
                if (iquuid == null) {
                    this.gotoVerifyEmail();
                } else {
                    await this.httpRequest("Dashboard/clearStatus", params)
                        .then(() => {
                            var res = this.$result.data;

                            if (res.result == 200) {
                                this.toast("success", res.message);
                            } else {
                                this.toast("danger", res.message);
                            }

                            this.loading = false;
                        })
                        .catch(error => {
                            this.loading = false;
                            throw error;
                        });
                }
            }
        },
        getCopyrightYear() {
            var d = new Date();
            return d.getFullYear();
        },

        async cancelCleansing() {
            const params = this.getURLParams();
            var iquuid = this.readLocalStorage("iquuid2", params.dbName);

            if (iquuid == null) {
                this.gotoVerifyEmail();
            } else {
                let key = encHex.parse(this.hexkey);
                let iv = encHex.parse(this.hexiv);

                var encrypted = aes
                    .encrypt(this.$prefixAuthKey + "-" + Date.now(), key, {
                        iv: iv,
                        padding: padZeroPadding
                    })
                    .toString();

                params["AuthKey"] = encrypted;
                params["iquuid"] = this.readLocalStorage(
                    "iquuid2",
                    params.dbName
                );
                params["topicID"] = params["TopicID"];
                this.loading = true;
                if (iquuid == null) {
                    this.gotoVerifyEmail();
                } else {
                    await this.httpRequest(
                        "Dashboard/cancelCleansingItems",
                        params
                    )
                        .then(() => {
                            var res = this.$result.data;

                            if (res.result.result == 200) {
                                this.requestUUID().then(() => {
                                    var res = this.$result.data;
                                    if (res.IsActivate) {
                                        this.gotoCleansingIndex();
                                    } else {
                                        this.gotoVerifyEmail();
                                    }
                                });
                            } else {
                                this.toast("danger", res.result.message);
                            }

                            this.loading = false;
                        })
                        .catch(error => {
                            this.loading = false;
                            throw error;
                        });
                }
            }
        },

        async confirmEndCleansing() {
            const params = this.getURLParams();
            var iquuid = this.readLocalStorage("iquuid2", params.dbName);

            if (iquuid == null) {
                this.gotoVerifyEmail();
            } else {
                let key = encHex.parse(this.hexkey);
                let iv = encHex.parse(this.hexiv);

                var encrypted = aes
                    .encrypt(this.$prefixAuthKey + "-" + Date.now(), key, {
                        iv: iv,
                        padding: padZeroPadding
                    })
                    .toString();

                params["AuthKey"] = encrypted;
                params["iquuid"] = this.readLocalStorage(
                    "iquuid2",
                    params.dbName
                );
                params["topicID"] = params["TopicID"];
                this.loading = true;
                if (iquuid == null) {
                    this.gotoVerifyEmail();
                } else {
                    await this.httpRequest(
                        "Dashboard/finishCleansingItems",
                        params
                    )
                        .then(() => {
                            var res = this.$result.data;

                            if (res.result.result == 200) {
                                this.requestUUID().then(() => {
                                    var res = this.$result.data;
                                    if (res.IsActivate) {
                                        this.gotoCleansingIndex();
                                    } else {
                                        this.gotoVerifyEmail();
                                    }
                                });
                            } else {
                                this.toast("danger", res.result.message);
                            }

                            this.loading = false;
                        })
                        .catch(error => {
                            this.loading = false;
                            throw error;
                        });
                }
            }
        },

        async updateLabelData(row, hexkey, hexiv, labelData) {
            const params = this.getURLParams();
            var iquuid = this.readLocalStorage("iquuid2", params.dbName);

            if (iquuid == null) {
                this.gotoVerifyEmail();
            } else {
                let key = encHex.parse(hexkey);
                let iv = encHex.parse(hexiv);

                var encrypted = aes
                    .encrypt(this.$prefixAuthKey + "-" + Date.now(), key, {
                        iv: iv,
                        padding: padZeroPadding
                    })
                    .toString();

                params["AuthKey"] = encrypted;
                params["RowID"] = row.RowID;
                params["iquuid"] = iquuid;
                params["LabelData"] = JSON.stringify(labelData);

                this.loading = true;
                return this.httpRequest("Dashboard/updateLabelData", params)
                    .then(() => {
                        var res = this.$result.data;
                        this.loading = false;
                        return res.code;
                    })
                    .catch(error => {
                        this.loading = false;
                        throw error;
                    });
            }
        },

        async toggleFinish(row, MentionID, hexkey, hexiv, labelValue) {
            const params = this.getURLParams();
            var iquuid = this.readLocalStorage("iquuid2", params.dbName);

            if (iquuid == null) {
                this.gotoVerifyEmail();
            } else {
                let key = encHex.parse(hexkey);
                let iv = encHex.parse(hexiv);

                var encrypted = aes
                    .encrypt(this.$prefixAuthKey + "-" + Date.now(), key, {
                        iv: iv,
                        padding: padZeroPadding
                    })
                    .toString();

                params["AuthKey"] = encrypted;
                params["RowID"] = row.RowID;
                params["MentionID"] = MentionID;
                params["iquuid"] = this.readLocalStorage(
                    "iquuid2",
                    params.dbName
                );
                params["LabelData"] = JSON.stringify(labelValue);
                params["email"] = await this.getUserEmail();
                params["BrowserID"] = this.getBrowserID();
                params["start"] = this.start;
                params["end"] = this.end;
                this.loading = true;
                return await this.httpRequest("Dashboard/confirmItem", params)
                    .then(() => {
                        var res = this.$result.data;

                        if (res.result == 200) {
                            this.toast("success", res.message);
                        } else if (res.result == 600) {
                            this.toast("danger", res.message);
                        }
                        this.loading = false;
                        return res;
                    })
                    .catch(error => {
                        this.loading = false;
                        throw error;
                    });
            }
        },

        async toggleDelete(
            a,
            RowID,
            MentionID,
            hexkey,
            hexiv,
            cleansingItem,
            cleansingTrash
        ) {
            const params = this.getURLParams();
            var iquuid = this.readLocalStorage("iquuid2", params.dbName);

            if (iquuid == null) {
                this.gotoVerifyEmail();
            } else {
                let key = encHex.parse(hexkey);
                let iv = encHex.parse(hexiv);

                var encrypted = aes
                    .encrypt(this.$prefixAuthKey + "-" + Date.now(), key, {
                        iv: iv,
                        padding: padZeroPadding
                    })
                    .toString();

                params["AuthKey"] = encrypted;
                params["RowID"] = RowID;
                params["MentionID"] = MentionID;
                params["iquuid"] = iquuid;
                this.loading = true;

                await this.httpRequest("Dashboard/deleteItem", params)
                    .then(() => {
                        var res = this.$result.data;

                        if (res.code == 200) {
                            var index = 0;
                            switch (MentionID) {
                                case 2:
                                    if (cleansingTrash.length > 0) {
                                        index = cleansingItem.items.indexOf(a);
                                        setTimeout(() => {
                                            cleansingItem.items.splice(
                                                index,
                                                1
                                            );
                                            this.toast("success", res.message);
                                            this.$parent.getCleansingItem();
                                        }, 200);
                                    }
                                    break;

                                case 4:
                                    if (cleansingTrash.length > 0) {
                                        index = cleansingTrash.items.indexOf(a);
                                        setTimeout(() => {
                                            cleansingTrash.items.splice(
                                                index,
                                                1
                                            );
                                            this.toast("success", res.message);
                                            this.$parent.getCleansingItem();
                                        }, 200);
                                    }
                                    break;
                            }
                        } else {
                            this.toast("danger", res.message);
                        }
                        this.loading = false;
                    })
                    .catch(error => {
                        this.loading = false;
                        throw error;
                    });
            }
        },

        async writeLog(logType, data) {
            var p = this.getURLParams();
            p["logType"] = logType;
            _.forEach(data, function(v) {
                p[v.key] = v.value;
            });

            await this.httpRequest("Dashboard/writeLog", p);
        },

        getServerConfig() {
            var p = this.getURLParams();
            return this.httpRequest("Dashboard/readConfig", p).then(() => {
                if (
                    this.$result.data !== undefined &&
                    this.$result.data !== null &&
                    this.$result.data.constructor == Object
                ) {
                    return this.$result.data;
                } else {
                    try {
                        var data = JSON.parse(this.$result.data);
                        return data;
                    } catch (ex) {
                        console.log(ex);
                    }
                }
            });
        },

        async getUserEmail() {
            var params = this.getURLParams();
            var iquuid = this.readLocalStorage("iquuid2", params.dbName);

            var BrowserID = this.getBrowserID();

            if (iquuid == null) {
                return "";
            }

            return await this.returnUUID(BrowserID, iquuid).then(() => {
                if (parseInt(this.$result.data.IsActivate, 10) == 1) {
                    return this.$result.data.Email;
                } else {
                    return "";
                }
            });
        },

        dtFormatter(date) {
            if (date) {
                return (
                    date
                        .getDate()
                        .toString()
                        .padStart(2, "0") +
                    "/" +
                    (date.getMonth() + 1).toString().padStart(2, "0") +
                    "/" +
                    date.getFullYear() +
                    " " +
                    date.getHours() +
                    ":" +
                    date.getMinutes() +
                    ":" +
                    date.getSeconds()
                );
            }
            return "";
        },

        stripHtml(html) {
            html = html.replace(/&#[0-9]{1,6};|&[a-z]{2,10};/g, "").trim();
            html = html.replace(
                /class="hl hl-1"/g,
                'class="hl hl-1" style="background-color:#a0ecdf"'
            );
            html = html.replace(
                /class="hl hl-2"/g,
                'class="hl hl-2" style="background-color:#ff9e7b"'
            );
            html = html.replace(
                /class="hl hl-3"/g,
                'class="hl hl-3" style="background-color:#e4cdff"'
            );
            html = html.replace(
                /class="hl hl-4"/g,
                'class="hl hl-4" style="background-color:#ebff89"'
            );
            html = html.replace(
                /class="hl hl-5"/g,
                'class="hl hl-5" style="background-color:#d1eaf8"'
            );
            html = html.replace(
                /class="hl hl-6"/g,
                'class="hl hl-6" style="background-color:#cddeae"'
            );
            html = html.replace(
                /class="hl hl-7"/g,
                'class="hl hl-7" style="background-color:#ffe183"'
            );
            html = html.replace(
                /class="hl hl-8"/g,
                'class="hl hl-8" style="background-color:#e9a3a8"'
            );
            //html = html.replace(/([เแโไใ])/g, "\u00AD$1");
            var a = htmlToPdfMake(html);
            return a;
        },


        gaPage() {
            // console.log("gaPage");
            window.gtag('page', location.pathname);

        },

        gaEvent(category, fileName) {
            // console.log("gaEvent");
            window.gtag('event', {
                'eventCategory' : category,
                'eventAction' :'export',
                'eventLabel' : fileName,
                'eventValue' :fileName
              });
        },


        // gaPage() {
        //     this.$ga.page(location.pathname);
        // },

        // gaEvent(category, fileName) {
        //     this.$ga.event({
        //         eventCategory: category,
        //         eventAction: "export",
        //         eventLabel: fileName,
        //         eventValue: fileName
        //     });
        // },


        async validateToken(token) {
            var p = {};
            p["token"] = token;
            var res = await this.httpRequest("Dashboard/validateToken", p).then(
                () => {
                    return this.$result.data.data;
                }
            );

            return res;
        },

        async validateTokenGoAuth(token) {
            var p = {};
            p["token"] = token;
            var res = await this.httpRequest(
                "Dashboard/validateTokenGoAuth",
                p
            ).then(() => {
                return this.$result.data.data;
            });

            return res;
        },

        getOriginalURL(r, hyperlinkname) {
            var pos = r[hyperlinkname].indexOf("?");
            var key = r[hyperlinkname].substring(pos);
            var hyperlink = "";

            switch (r["NewsType"]) {
                case "printmedia":
                    hyperlink =
                        "https://www.iqnewsclip.com/reportdl/printdl.aspx" +
                        key;
                    break;
                case "onlinemedia":
                    hyperlink =
                        "https://www.iqnewsclip.com/reportdl/onlinedl.aspx" +
                        key;
                    break;
                default:
                    hyperlink = r[hyperlinkname];
                    break;
            }
            return hyperlink;
        },
        async getNotification() {
            var params = this.getURLParams();
            let p = {};
            p["dbName"] = params.dbName;
            p["TopicID"] = params.TopicID;
            var rtn = await this.httpRequest("Dashboard/getNotification", p)
                .then(res => {
                    res = this.$result.data;
                    let alert_count = res.data.filter(x => x.status === "0");
                    for (let index = 0; index < res.data.length; index++) {
                        var element = res.data[index];
                        var time_element = this.create_human_friendly_date(
                            element.createtime,
                            "Yesterday",
                            "Today",
                            "Tomorrow",
                            "en"
                        );
                        element.timehuman = time_element;
                    }
                    const unique = [
                        ...new Set(res.data.map(item => item.timehuman))
                    ]; // [ 'A', 'B']

                    var rtnVal = {
                        alert: alert_count,
                        data: res.data,
                        group: unique
                    };
                    return rtnVal;
                })
                .catch(error => {
                    throw error;
                });
            return rtn;
        },
        // async monitorNotification(){
        //     setTimeout(() => {
        //         try {
        //             let res = await this.getNotification();
        //             this.items = res.data;
        //             this.group = res.group;
        //             this.itemsnotification = res;
        //             this.monitorNotification();
        //         } catch (error) {
        //             this.items = [];
        //         }                
        //     }, 1000);
        // },
        async updateReadNotification(item) {
            var params = this.getURLParams();
            let p = {};
            p["dbName"] = params.dbName;
            p["updates"] = JSON.stringify(item);
            var rtn = await this.httpRequest(
                "Dashboard/updateExportHistory",
                p
                )
                .then(res => {                  
                    res = this.$result.data;
                    return res;
                })
                .catch(error => {
                    throw error;
                });
            return rtn;
        },
        time_ago(date) {
            if (typeof date !== "object") {
                date = new Date(date);
            }

            var seconds = Math.floor((new Date() - date) / 1000);
            var intervalType;

            var interval = Math.floor(seconds / 31536000);
            if (interval >= 1) {
                intervalType = "year";
            } else {
                interval = Math.floor(seconds / 2592000);
                if (interval >= 1) {
                    intervalType = "month";
                } else {
                    interval = Math.floor(seconds / 86400);
                    if (interval >= 1) {
                        intervalType = "day";
                    } else {
                        interval = Math.floor(seconds / 3600);
                        if (interval >= 1) {
                            intervalType = "hour";
                        } else {
                            interval = Math.floor(seconds / 60);
                            if (interval >= 1) {
                                intervalType = "minute";
                            } else {
                                interval = seconds;
                                intervalType = "second";
                            }
                        }
                    }
                }
            }

            if (interval > 1 || interval === 0) {
                intervalType += "s";
            }

            return interval + " " + intervalType + " ago";
        },
        create_human_friendly_date(
            timestamp,
            yesterday_text,
            today_text,
            tomorrow_text,
            language
        ) {
            var in_the_last_7days_date_options = { weekday: "long" };
            var in_the_next_7days_date_options = {
                month: "short",
                day: "numeric"
            };
            var same_year_date_options = { month: "short", day: "numeric" };
            var far_date_options = {
                year: "numeric",
                month: "short",
                day: "numeric"
            };

            var dt = new Date(timestamp);
            var date = dt.getDate();
            //var time_diff = timestamp - Date.now();
            var diff_days = new Date().getDate() - date;
            var diff_months = new Date().getMonth() - dt.getMonth();
            var diff_years = new Date().getFullYear() - dt.getFullYear();

            var is_today =
                diff_years === 0 && diff_months === 0 && diff_days === 0;
            var is_yesterday =
                diff_years === 0 && diff_months === 0 && diff_days === 1;
            var is_tomorrow =
                diff_years === 0 && diff_months === 0 && diff_days === -1;
            var is_in_the_last_7days =
                diff_years === 0 &&
                diff_months === 0 &&
                diff_days > 1 &&
                diff_days < 7;
            var is_in_the_next_7days =
                diff_years === 0 &&
                diff_months === 0 &&
                diff_days < -1 &&
                diff_days > -7;
            var is_same_year = diff_years === 0;

            if (is_today) {
                return today_text;
            } else if (is_yesterday) {
                return yesterday_text;
            } else if (is_tomorrow) {
                return tomorrow_text;
            } else if (is_in_the_last_7days) {
                return dt.toLocaleString(
                    language,
                    in_the_last_7days_date_options
                );
            } else if (is_in_the_next_7days) {
                return dt.toLocaleString(
                    language,
                    in_the_next_7days_date_options
                );
            } else if (is_same_year) {
                return dt.toLocaleString(language, same_year_date_options);
            } else {
                return dt.toLocaleString(language, far_date_options);
            }
        },
        getItems(data) {
            var a = [];
            var s = [];
            var i = 0;
            _.forEach(data.datasets, function(v) {
                var label = v.label.replace(/ /g, "");
                var sum = _.sum(v.data);
                s[label] = sum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            });
            s["QueryName"] = "Total";
            _.forEach(data.labels, function(v) {
                var b = {
                    QueryName: v.join(" ")
                };

                _.forEach(data.datasets, function(vv) {
                    b[vv.label.replace(/ /g, "")] = vv.data[i]
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                });

                a.push(b);
                i++;
            });

            a.push(s);
            return a;
        },

        genTablePDF(header = [], data, width = []) {
            var body = [];
            var row = [];
            var widths = [];
            if (header.length > 0) {
                for (var i = 0; i < header.length; i++) {
                    var a = {
                        text: header[i],
                        bold: true
                    };
                    row.push(a);
                    if (width.length > 0) {
                        widths.push(width[i]);
                    } else {
                        widths.push("*");
                    }
                }

                body.push(row);
            } else {
                widths = width;
            }

            _.forEach(data, function(v) {
                body.push(v);
            });

            a = {
                style: "tableExample",
                table: {
                    widths: widths,
                    body: body
                },

                // unbreakable: true,
                layout: {
                    hLineWidth: function() {
                        return 1;
                    },
                    vLineWidth: function() {
                        return 0;
                    },
                    hLineColor: function(i, node) {
                        return i === 0 || i === node.table.body.length
                            ? "black"
                            : "#aaa";
                    },
                    vLineColor: function(i, node) {
                        return i === 0 || i === node.table.widths.length
                            ? "black"
                            : "#ccc";
                    }
                }
            };

            return a;
        },

        million(labelValue) {
            // Nine Zeroes for Billions
            return Math.abs(Number(labelValue)) >= 1.0e9
                ? Math.abs(Number(labelValue)) / 1.0e9 + "B"
                : // Six Zeroes for Millions
                Math.abs(Number(labelValue)) >= 1.0e6
                ? Math.abs(Number(labelValue)) / 1.0e6 + "M"
                : // Three Zeroes for Thousands
                  // : Math.abs(Number(labelValue)) >= 1.0e+3

                  //   ? Math.abs(Number(labelValue)) / 1.0e+3 + "K"

                  Math.abs(Number(labelValue));
        },
        number2Commas(x) {
            if (x != null)
                return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            else return x;
        },

        FormatDate(d, mask = "D MMM YY[\r\n] HH:mm:ss") {
            return moment(d).format(mask);
        },

        BurgerEvent() {
            document.addEventListener("DOMContentLoaded", () => {
                // Get all "navbar-burger" elements
                const $navbarBurgers = Array.prototype.slice.call(
                    document.querySelectorAll(".navbar-burger"),
                    0
                );

                // Check if there are any navbar burgers
                if ($navbarBurgers.length > 0) {
                    // Add a click event on each of them
                    $navbarBurgers.forEach(el => {
                        el.addEventListener("click", () => {
                            // Get the target from the "data-target" attribute
                            const target = el.dataset.target;
                            const $target = document.getElementById(target);

                            // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
                            el.classList.toggle("is-active");
                            $target.classList.toggle("is-active");
                        });
                    });
                }
            });
        },

        GetLink(RowID, DisplayTime, OriginalURL) {
            var p = this.getURLParams();

            p["RowID"] = RowID;

            var newURL = "";
            if (this.$databasePath.indexOf("ttp:/") > 0) {
                newURL =
                    this.$databasePath +
                    "/Go/Now/" +
                    window.btoa(encodeURIComponent(OriginalURL));
                this.writeLog("action", [
                    {
                        key: "newsID",
                        value: RowID
                    },
                    {
                        key: "originalURL",
                        value: OriginalURL
                    }
                ]).then(() => {
                    //window.open(newURL, "_blank", "noopener noreferer");
                    let a = document.createElement("a");
                    a.target = "_blank";
                    a.href = newURL;
                    a.setAttribute("rel", "noopener noreferrer");
                    a.click();
                });
            } else {
                newURL =
                    document.location.protocol +
                    "//" +
                    document.location.hostname +
                    this.$databasePath +
                    "/Go/Now/" +
                    window.btoa(encodeURIComponent(OriginalURL));

                this.writeLog("action", [
                    {
                        key: "newsID",
                        value: RowID
                    },
                    {
                        key: "originalURL",
                        value: OriginalURL
                    }
                ]).then(() => {
                    //window.open(newURL, "_blank", "noopener noreferer");
                    let a = document.createElement("a");
                    a.target = "_blank";
                    a.href = newURL;
                    a.setAttribute("rel", "noopener noreferrer");
                    a.click();
                });
            }
        },

        GetExternalLink(
            RowID,
            NewsType,
            OriginalURL,
            IsShowPDFIcon,
            DisplayTime
        ) {
            if (IsShowPDFIcon) {
                if (OriginalURL !== null) {
                    var p = this.getURLParams();
                    p["RowID"] = RowID;

                    this.writeLog("action", [
                        {
                            key: "newsID",
                            value: RowID
                        },
                        {
                            key: "originalURL",
                            value: OriginalURL
                        }
                    ]).then(() => {
                        // window.open(
                        //     OriginalURL,
                        //     "_blank",
                        //     "noopener noreferer"
                        // );

                        let a = document.createElement("a");
                        a.target = "_blank";
                        a.href = OriginalURL;
                        a.setAttribute("rel", "noopener noreferrer");
                        a.click();

                    });
                } else {
                    this.GetPDF(RowID, DisplayTime);
                }
            } else {
                if (OriginalURL !== null) {
                    this.GetLink(RowID, DisplayTime, OriginalURL);
                }
            }
        },

        async GetPDF(RowID, DisplayTime) {
            var p = this.getURLParams();
            p["RowID"] = RowID;
            p["DisplayTime"] = DisplayTime;
            await this.httpRequest("Dashboard/GetPDF", p).then(() => {
                var res = this.$result.data;
                var link = res.link + res.key;
                this.writeLog("action", [
                    {
                        key: "newsID",
                        value: RowID
                    },
                    {
                        key: "originalURL",
                        value: link
                    }
                ]);
                //window.open(link, "_blank", "noopener noreferer");
                let a = document.createElement("a");
                a.target = "_blank";
                a.href = link;
                a.setAttribute("rel", "noopener noreferrer");
                a.click();
            });
        },

        getIconInfo(c) {
            if (this.$tableIcon[c]) {
                return {
                    icon: this.$tableIcon[c]["icon"],
                    color: this.$tableIcon[c]["color"]
                };
            } else {
                return {
                    icon: this.$tableIcon["other"]["icon"],
                    color: this.$tableIcon["other"]["color"]
                };
            }
        },

        getIcon(c, url, subtype) {
            url = url || "";

            if (c !== null) {
                var a = "";
                if (c == "voice") {
                    a = this.$tableIcon[c]["icon"];
                } else {
                    subtype = subtype.toLowerCase();

                    switch (subtype) {
                        case "facebook":
                            a = this.$tableIcon["facebook"]["icon"];
                            break;
                        case "twitter":
                            a = this.$tableIcon["twitter"]["icon"];
                            break;
                        case "youtube":
                            a = this.$tableIcon["youtube"]["icon"];
                            break;
                        case "instagram":
                            a = this.$tableIcon["instagram"]["icon"];
                            break;
                        case "line":
                            a = this.$tableIcon["line"]["icon"];
                            break;
                        case "tiktok":
                            a = this.$tableIcon["tiktok"]["icon"];
                            break;
                        default:
                            if (this.$tableIcon[c]) {
                                a = this.$tableIcon[c]["icon"];
                            } else {
                                a = this.$tableIcon["other"]["icon"];
                            }
                            break;
                    }
                }
                return a;
            } else {
                return "";
            }
        },

        getColor(c, url, subtype) {
            url = url || "";

            if (c !== null) {
                var a = "";
                if (c == "voice") {
                    a = this.$tableIcon[c]["color"];
                } else {
                    subtype = subtype.toLowerCase();

                    switch (subtype) {
                        case "facebook":
                            a = this.$tableIcon["facebook"]["color"];
                            break;
                        case "twitter":
                            a = this.$tableIcon["twitter"]["color"];
                            break;
                        case "youtube":
                            a = this.$tableIcon["youtube"]["color"];
                            break;
                        case "instagram":
                            a = this.$tableIcon["instagram"]["color"];
                            break;
                        case "line":
                            a = this.$tableIcon["line"]["color"];
                            break;
                        case "tiktok":
                            a = this.$tableIcon["tiktok"]["color"];
                            break;
                        default:
                            if (this.$tableIcon[c]) {
                                a = this.$tableIcon[c]["color"];
                            } else {
                                a = this.$tableIcon["other"]["color"];
                            }
                            break;
                    }
                }
                return a;
            } else {
                return "";
            }
        },

        getChartAddonOption(chart) {
            var barPercentage = 0.5;
            if (chart.datasets.length > 3) barPercentage = 0.8;
            if (chart.datasets) {
                for (var i in chart.datasets) {
                    chart.datasets[i].backgroundColor = this.$colorScheme[i];
                    chart.datasets[i].barPercentage = barPercentage;
                    chart.datasets[i].maxBarThickness = 80;
                }
            }
            return chart;
        },

        getIconColor(index) {
            return "icon-color-" + index;
        },

        getStartEndInterval(periodName, periodID = "") {
            var start = "";
            var end = "";
            var _start, _end;

            var dateFormat = "YYYYMMDDHHmmss";

            switch (periodName.toLowerCase()) {
                case "daily":
                    if (periodID !== null && periodID.length > 0) {
                        if (
                            periodID.indexOf("-") > 0 &&
                            periodID.split("-").length == 3
                        ) {
                            start = moment(periodID)
                                .startOf("day")
                                .format(dateFormat);
                            end = moment(periodID)
                                .endOf("day")
                                .format(dateFormat);
                        }
                    }
                    break;
                case "monthly/latest":
                    start = moment()
                        .startOf("month")
                        .startOf("day")
                        .format(dateFormat);
                    end = moment()
                        .endOf("month")
                        .endOf("day")
                        .format(dateFormat);
                    break;
                case "monthly":
                    switch (periodID) {
                        case "latest":
                            start = moment()
                                .startOf("month")
                                .startOf("day")
                                .format(dateFormat);
                            end = moment()
                                .endOf("month")
                                .endOf("day")
                                .format(dateFormat);
                            break;
                        default:
                            if (periodID !== null && periodID.length > 0) {
                                if (periodID.split("-").length == 2) {
                                    start = moment(periodID)
                                        .startOf("month")
                                        .startOf("day")
                                        .format(dateFormat);
                                    end = moment(periodID)
                                        .endOf("month")
                                        .endOf("day")
                                        .format(dateFormat);
                                } else {
                                    start = "";
                                    end = "";
                                }
                            }
                            break;
                    }
                    break;
                case "lastmonth":
                    start = moment()
                        .subtract(1, "month")
                        .startOf("month")
                        .startOf("day")
                        .format(dateFormat);
                    end = moment(
                        moment()
                            .subtract(1, "month")
                            .endOf("month")
                    )
                        .endOf("day")
                        .format(dateFormat);

                    break;
                case "past7days":
                    end = moment()
                        .subtract(1, "days")
                        .endOf("day")
                        .format(dateFormat);
                    start = moment()
                        .subtract(7, "days")
                        .startOf("day")
                        .format(dateFormat);

                    break;
                case "past30days":
                    end = moment()
                        .subtract(1, "days")
                        .endOf("day")
                        .format(dateFormat);
                    start = moment()
                        .subtract(30, "days")
                        .startOf("day")
                        .format(dateFormat);

                    break;
                case "yesterday":
                    start = moment()
                        .subtract(1, "days")
                        .startOf("day")
                        .format(dateFormat);
                    end = moment()
                        .subtract(1, "days")
                        .endOf("day")
                        .format(dateFormat);

                    break;
                case "today":
                    start = moment()
                        .startOf("day")
                        .format(dateFormat);
                    end = moment()
                        .endOf("day")
                        .format(dateFormat);

                    break;
                case "custom":
                    if (
                        periodID !== null &&
                        periodID.length > 0 &&
                        periodID.indexOf("-") >= 0
                    ) {
                        var start_time = periodID.split("-");
                        if (start_time.length == 2) {
                            _start = start_time[0];
                            _end = start_time[1];

                            if (
                                moment(_start.substr(0, 8))
                                    .format("D")
                                    .toLowerCase() !== "invalid date" &&
                                moment(_end.substr(0, 8))
                                    .format("D")
                                    .toLowerCase() !== "invalid date"
                            ) {
                                start = moment(_start.substr(0, 8))
                                    .startOf("day")
                                    .format(dateFormat);
                                end = moment(_end.substr(0, 8))
                                    .endOf("day")
                                    .format(dateFormat);
                            }
                        }
                    }
                    break;
            }

            var p = {
                start: start,
                end: end
            };

            return p;
        },

        getStartEnd(_start = "", _end = "") {
            var a;
            var start = moment(_start.substr(0, 8)).format("D");
            var end = moment(_end.substr(0, 8)).format("D");

            var monthYearStart = moment(_start.substr(0, 8)).format("MMM Y");
            var monthYearEnd = moment(_end.substr(0, 8)).format("MMM Y");

            var monthStart = moment(_start.substr(0, 8)).format("MMM");
            var monthEnd = moment(_end.substr(0, 8)).format("MMM");

            var yearStart = moment(_start.substr(0, 8)).format("YYYY");
            var yearEnd = moment(_end.substr(0, 8)).format("YYYY");
            if (
                monthYearStart.toLowerCase() !== "invalid date" &&
                monthYearStart.toLowerCase() !== "invalid date"
            ) {
                if (yearStart == yearEnd) {
                    if (monthYearStart == monthYearEnd) {
                        if (start == end) {
                            a = start + " " + monthYearStart;
                        } else {
                            a = start + " - " + end + " " + monthYearStart;
                        }
                    } else {
                        a =
                            start +
                            " " +
                            monthStart +
                            " - " +
                            end +
                            " " +
                            monthEnd +
                            " " +
                            yearStart;
                    }
                } else {
                    a =
                        start +
                        " " +
                        monthYearStart +
                        " - " +
                        end +
                        " " +
                        monthYearEnd;
                }
            } else {
                a = "Invalid date format.";
            }

            return a;
        },

        toast(type, message) {
            this.$buefy.toast.open({
                duration: 5000,
                message: message,
                position: "is-top",
                type: "is-" + type,
                queue: false
            });
        },

        getBrowserID() {
            return window.navigator.userAgent
                .replace(/\D+/g, "")
                .substring(0, 32);
        },

        isCookieEnable() {
            return (this.cookieEnabled = navigator.cookieEnabled);
        },

        decryptToken(base64) {
            var parsedWordArray = CryptoJS.enc.Base64.parse(base64);
            var parsedStr = parsedWordArray.toString(CryptoJS.enc.Utf8);
            return parsedStr;
        },

        writeLocalStorage(key, value, ttl, dbName = "") {
            const item = {
                value: value,
                expiry: ttl
            };

            if (dbName !== null && dbName.length > 0) key = dbName + "_" + key;
            localStorage.setItem(
                key,
                CryptoJS.AES.encrypt(JSON.stringify(item), this.getBrowserID())
            );
            
            //set token authen to cookie
            //document.cookie = "js_var_jwt = " + f + ";path=/";
        },

        readLocalStorage(key, dbName = "") {
            if (dbName !== null && dbName.length > 0) key = dbName + "_" + key;

            const itemStr = localStorage.getItem(key);

            // if the item doesn't exist, return null
            if (!itemStr) {
                return null;
            }
            var bytes = CryptoJS.AES.decrypt(itemStr, this.getBrowserID());
            try {
                bytes = bytes.toString(CryptoJS.enc.Utf8);
                const item = JSON.parse(bytes);
                const now = new Date();
                // compare the expiry time of the item with the current time
                if (now.getTime() > item.expiry) {
                    // If the item is expired, delete the item from storage
                    // and return null
                    localStorage.removeItem(key);
                    return null;
                }

                return item.value;
            } catch (ex) {
                console.log(ex);
            }

            return null;
        },

        deleteLocalStorage(key, dbName = "") {
            if (dbName !== null && dbName.length > 0) key = dbName + "_" + key;
            //remove token authen cookie
            //document.cookie = "js_var_jwt=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
            return window.localStorage.removeItem(key);
        },

        async gotoGOAuth(logType = "customer", params = {}) {
            params["BrowserID"] = this.getBrowserID();
            params["iquuid"] = this.readLocalStorage("iquuid2", params.dbName);
            await this.httpRequest("Dashboard/getOAuthToken", params).then(
                () => {
                    if (this.$result.data.url !== undefined) {
                        var url = this.$result.data.url;

                        this.writeLog(logType, [
                            {
                                key: "originalURL",
                                value: url
                            },
                            {
                                key: "remark",
                                value: "login"
                            }
                        ]);
                        window.location.href = url;
                    }
                }
            );
        },

        generateAuthenKey() {
            var now = new Date();
            var key = [
                [
                    now.getFullYear(),
                    this.AddZero(now.getMonth() + 1),
                    this.AddZero(now.getDate())
                ].join(""),
                [
                    this.AddZero(now.getHours()),
                    this.AddZero(now.getMinutes())
                ].join(":")
            ].join(" ");
            key = "IQIO" + key;
            key = CryptoJS.SHA256(key).toString(CryptoJS.enc.Hex);
            return key;
        },

        AddZero(num) {
            return num >= 0 && num < 10 ? "0" + num : num + "";
        },

        async gotoInputData() {
            var params = this.getURLParams();
            var iquuid = this.readLocalStorage("iquuid2", params.dbName);

            if (iquuid == null) {
                this.gotoVerifyEmail();
            } else {
                var a = [];
                if (params.periodID !== null) {
                    a = [
                        "/inputdata",
                        params.dbName,
                        params.reportID.toLowerCase(),
                        params.periodName,
                        params.periodID
                    ];
                } else {
                    a = [
                        "/inputdata",
                        params.dbName,
                        params.reportID.toLowerCase(),
                        params.periodName
                    ];
                }
                router.push(a.join("/")).catch(err => {
                    throw err;
                });
            }
        },

        async gotoUploadData() {
            var params = this.getURLParams();
            var iquuid = this.readLocalStorage("iquuid2", params.dbName);

            if (iquuid == null) {
                this.gotoVerifyEmail();
            } else {
                var a = [];

                a =
                    params.periodID !== null
                        ? [
                              "/uploaddata",
                              params.dbName,
                              params.reportID.toLowerCase(),
                              params.periodName,
                              params.periodID
                          ]
                        : [
                              "/uploaddata",
                              params.dbName,
                              params.reportID.toLowerCase(),
                              params.periodName
                          ];
                router.push(a.join("/")).catch(err => {
                    throw err;
                });
            }
        },

        gotoVerifyEmail() {
            var b = this.getURLParams();
            try {
                var a = [];
                if (b.periodID !== undefined) {
                    a = [
                        "/verifyemail",
                        b.dbName,
                        b.reportID.toLowerCase(),
                        b.periodName,
                        b.periodID
                    ];
                } else {
                    a = [
                        "/verifyemail",
                        b.dbName,
                        b.reportID.toLowerCase(),
                        b.periodName
                    ];
                }
                this.goto(a.join("/"));
            } catch (e) {
                console.log(e);
            }
        },

        async deActivate() {
            //delete activation due to email in tbreport_proifle is missing;
            const params = this.getURLParams();
            var iquuid = this.readLocalStorage("iquuid2", params.dbName);

            params["iquuid"] = iquuid;
            params["BrowserID"] = this.getBrowserID();
            var res = false;
            if (iquuid !== null) {
                res = await this.httpRequest(
                    "Dashboard/deActivate",
                    params
                ).then(() => {});
            }
            return res;
        },
        async requestUUID() {
            if (this.isCookieEnable()) {
                const params = this.getURLParams();
                var iquuid = await this.readLocalStorage(
                    "iquuid2",
                    params.dbName
                );
                var BrowserID = this.getBrowserID();

                if (iquuid == null) {
                    await this.httpRequest(
                        "Dashboard/requestUUID",
                        params
                    ).then(() => {
                        this.$speedVerifyCleansing = 250;
                        var expiry = moment()
                            .add(this.$result.data.cld, "day")
                            .endOf("day");
                        this.writeLocalStorage(
                            "iquuid2",
                            this.$result.data.iquuid,
                            expiry,
                            params.dbName
                        );
                    });
                    return await this.returnUUID(
                        BrowserID,
                        this.$result.data.iquuid
                    );
                } else {
                    return await this.returnUUID(BrowserID, iquuid);
                }
            } else {
                return {
                    state: 0,
                    activationResult: false
                };
            }
        },

        async checkPermission(iquuid, BrowserID, page) {
            const params = this.getURLParams();
            params["BrowserID"] = BrowserID;
            params["iquuid"] = iquuid;
            params["page"] = page;
            params["logType"] = "verifyemail";
            return await this.httpRequest(
                "Dashboard/checkPermission",
                params
            ).then(() => {
                return this.$result.data;
            });
        },

        async returnUUID(BrowserID, iquuid) {
            const params = this.getURLParams();
            params["BrowserID"] = BrowserID;
            params["iquuid"] = iquuid;
            params["logType"] = "verifyemail";
            return await this.httpRequest(
                "Dashboard/getUUIDDetail",
                params
            ).then(res => {
                res = this.$result.data;

                if (parseInt(res.result, 10) == 200) {
                    var IsActivate = parseInt(res.IsActivate, 10);

                    switch (IsActivate) {
                        case 0:
                            return {
                                state: 2,
                                activationResult: false,
                                email: res.Email
                            };

                        case 1:
                            return {
                                state: 3,
                                activationResult: true,
                                email: res.Email
                            };

                        case 2:
                            return {
                                state: 3,
                                activationResult: false,
                                email: res.Email
                            };
                    }
                } else {
                    return {
                        state: 1,
                        activationResult: false,
                        email: res.Email
                    };
                }
            });
        },

        gotoPreparingPage() {
            let params = this.getURLParams();
            var path = "/preparing";
            var a =
                params.periodID !== null
                    ? [
                          path,
                          params.dbName,
                          params.TopicID,
                          params.periodName,
                          params.periodID
                      ]
                    : [path, params.dbName, params.TopicID, params.periodName];

            router.push(a.join("/")).catch(err => {
                throw err;
            });
        },

        async gotoCleansingV2(item = {}) {
            let params = this.getURLParams();
            var iquuid = this.readLocalStorage("iquuid2", params.dbName);

            if (iquuid == null) {
                this.gotoVerifyEmail();
            } else {
                await this.getServerConfig().then(config => {
                    params = {
                        dbName: params.dbName,
                        reportID: item.row.TopicID.substr(0, 6),
                        topicID: item.row.TopicID,
                        DisplayDate: item.row.DisplayDate,
                        iquuid: iquuid,
                        idleTimeout: config.$idleTimeout
                    };
                    params["isCleansing"] = 1;

                    this.httpRequest(
                        "Dashboard/reserveRowCleansing",
                        params
                    ).then(res => {
                        res = this.$result.data;

                        if (res.result == 200) {
                            var periodID = moment(item.row.DisplayDate)
                                .startOf("day")
                                .format("YYYY-MM-DD");
                            var periodName = "daily";
                            var a = [
                                "/cleansingv2",
                                params.dbName,
                                params.topicID,
                                periodName,
                                periodID
                            ];

                            router.push(a.join("/")).catch(err => {
                                throw err;
                            });
                        } else {
                            this.toast("danger", res.message);
                        }
                    });
                });
            }
        },

        async gotoCleansing(item = {}) {
            let params = this.getURLParams();
            var iquuid = this.readLocalStorage("iquuid2", params.dbName);

            if (iquuid == null) {
                this.gotoVerifyEmail();
            } else {
                await this.getServerConfig().then(config => {
                    params = {
                        dbName: params.dbName,
                        reportID: item.row.TopicID.substr(0, 6),
                        topicID: item.row.TopicID,
                        PeriodID: item.row.DisplayDate,
                        DisplayDate: item.row.DisplayDate,
                        iquuid: iquuid,
                        idleTimeout: config.$idleTimeout
                    };
                    params["isCleansing"] = 1;
                    this.httpRequest(
                        "Dashboard/reserveRowCleansing",
                        params
                    ).then(res => {
                        res = this.$result.data;

                        if (res.result == 200) {
                            var a =
                                params.periodID !== null
                                    ? [
                                          "/cleansing",
                                          params.dbName,
                                          params.TopicID,
                                          params.periodName,
                                          params.periodID
                                      ]
                                    : [
                                          "/cleansing",
                                          params.dbName,
                                          params.TopicID,
                                          params.periodName,
                                          params.periodID
                                      ];
                            router.push(a.join("/")).catch(err => {
                                throw err;
                            });
                        } else {
                            this.toast("danger", res.message);
                        }
                    });
                });
            }
        },

        async gotoDetailReport(item) {
            let p = this.getURLParams();
            var a = [
                "/report",
                p.dbName,
                item.row.TopicID,
                p.periodName,
                p.periodID
            ];
            router.push(a.join("/")).catch(err => {
                throw err;
            });
        },

        async gotoCleansingIndex(item = {}, target = "_self") {
            var Server = item.row.Server !== undefined ? item.row.Server : "";
            var DatabaseName =
                item.row.DatabaseName !== undefined
                    ? item.row.DatabaseName
                    : "";
            var ProfileID =
                item.row.ProfileID !== undefined
                    ? item.row.ProfileID.toLowerCase()
                    : "";

            var Page = "cleansingindex";
            var periodName = "monthly";
            var periodID = "latest";

            var p = this.getURLParams();
            if (p.reportID !== null) {
                ProfileID = p.reportID;
                periodName = p.periodName;
                periodID = p.periodID;
            }

            var a = [];
            if (target == "_blank") {
                a = [
                    Server,
                    Page,
                    DatabaseName,
                    ProfileID.substr(0, 6),
                    periodName,
                    periodID
                ];
                window.open(a.join("/"), target);
            } else {
                a = [
                    "/",
                    Page,
                    DatabaseName,
                    ProfileID.substr(0, 6),
                    periodName,
                    periodID
                ];
                this.goto(a.join("/"));
            }
        },

        async gotoUnAuthorize() {
            var page = "unauthorize";
            var a = ["/", page];
            this.goto(a.join("/"));
        },

        gotoNotFound(dbName) {
            var page = "notfound";
            var a = ["/", page];
            if (dbName !== null) a.push(dbName);
            this.goto(a.join("/"), true);
        },

        async gotoDefaultPage(isRefresh = false, oTopics) {
            //summary
            var b = this.getURLParams();
            var c = false;

            for (var i in oTopics) {
                if (
                    b.reportID
                        .toLowerCase()
                        .substr(0, 6)
                        .toLowerCase() == oTopics[i].substr(0, 6).toLowerCase()
                ) {
                    c = true;
                    break;
                }
            }

            if (c == true) {
                var a = [
                    "/summary",
                    b.dbName,
                    b.reportID.toLowerCase().substr(0, 6),
                    "monthly",
                    "latest"
                ];

                if (isRefresh == false) {
                    this.goto(a.join("/"));
                } else {
                    setTimeout(() => {
                        a = [
                            window.location.origin,
                            "summary",
                            b.dbName,
                            b.reportID.toLowerCase().substr(0, 6),
                            "monthly",
                            "latest"
                        ];
                        window.location.href = a.join("/");
                    }, 1000);
                }
            } else {
                this.gotoNotFound(b.dbName);
            }
        },

        async gotoSummary(item, target = "_self") {

            var Server = item.row.Server !== undefined ? item.row.Server : "";
            var DatabaseName =
                item.row.DatabaseName !== undefined
                    ? item.row.DatabaseName
                    : "";
            var ProfileID =
                item.row.ProfileID !== undefined
                    ? item.row.ProfileID.toLowerCase()
                    : "";

            var Page = "summary";
            var periodName = "monthly";
            var periodID = "latest";

            var a = [
                Server,
                Page,
                DatabaseName,
                ProfileID.substr(0, 6),
                periodName,
                periodID
            ];
            if (target == "_blank") {
                window.open(a.join("/"), target);
            } else {
                a = [
                    "/",
                    Page,
                    DatabaseName,
                    ProfileID.substr(0, 6),
                    periodName,
                    periodID
                ];
                this.goto(a.join("/"));
            }
        },

        goto(url, replace = false) {
            url = url.replace("//", "/");

            if (replace == false) {
                router.push(url).catch(err => {
                    throw err;
                });
            } else {
                router.replace(url).catch(err => {
                    throw err;
                });
            }
        },

        getURLParams() {
            var elements = this.$route.path.split("/");

            return {
                host: window.location.origin,
                page: elements[1],
                dbName: elements[2],
                TopicID: elements[3] || null,
                reportID: elements[3] || null,
                periodName: elements[4] || null,
                periodID: elements[5] || null,
                csv: elements[6] || null
            };
        },

        async httpRequestBLOB(url, params = []) {
            const p = new URLSearchParams();
            _.forEach(params, function(value, key) {
                p.append(key, value);
            });

            p.append(
                "environment",
                process.env.NODE_ENV == "development" ? "dev" : "prod"
            );
            await axios({
                method: "post",
                url: this.$databasePath + "/" + url,
                responseType: "blob",
                params: p,
                timeout: 10000
            }).then(res => {
                Vue.prototype.$result = res;
                Vue.prototype.$result_url = this.$databasePath + "/" + url;
            });
        },

        async httpRequest(url, params, progressMessage = "") {
            if (progressMessage.length > 0) {
                this.toast("success", progressMessage);
            }
            const p = new URLSearchParams();
            var server = "";
            _.forEach(params, function(value, key) {
                if (key !== "server") p.append(key, value);
                else server = typeof value == "undefined" ? "" : value;
            });
            p.append(
                "environment",
                process.env.NODE_ENV == "development" ? "dev" : "prod"
            );
            if (server.indexOf("://localhost") > 0) server = "";

            if (server.length > 0) {
                await axios
                    .post(
                        server + "/" + config.config.$api_url + "/" + url,
                        p,
                        {
                            timeout: 10000
                        }
                    )
                    .then(res => {
                        Vue.prototype.$result = res;
                        Vue.prototype.$result_url =
                            server + "/" + config.config.$api_url + "/" + url;
                    });
            } else {
                await axios
                    .post(this.$databasePath + "/" + url, p)
                    .then(res => {
                        Vue.prototype.$result = res;
                        Vue.prototype.$result_url =
                            this.$databasePath + "/" + url;
                    });
            }
        }
    }
});

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount("#app");
