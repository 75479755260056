import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";
const ls = new SecureLS({
    isCompression: false
});

Vue.use(Vuex);

export default new Vuex.Store({
    plugins: [createPersistedState({
        storage: {
            getItem: key => {
                try {
                    return ls.get(key);
                } catch (error) {
                    return '';
                }
            },
            setItem: (key, value) => {
                ls.set(key, value)
            },
            removeItem: key => ls.remove(key)
        }
    })],
    state: {

        selectedDate: '',
        email: '',
        pageBack: {},

        initialData: {},
        serverConfig: {},
        urlParams: {},

        scrollDirection: 'top',
        dateRangeOption: ''
    },
    getters: {

        email: state => {
            return state.email;
        },
        initialData: state => {
            return state.initialData;
        },

        serverConfig: state => {
            return state.serverConfig
        },

        pageBack: state => {
            return state.pageBack;
        },

        urlParams: state => {
            return state.urlParams;
        },

        scrollDirection: state => {
            return state.scrollDirection;
        },

        dateRangeOption: state => {
            return state.dateRangeOption;
        }
    },
    mutations: {

        setAState(state, data) {
            state.astate = data;
        },

        changeSelectedDate(state, data) {
            state.selectedDate = data;
        },

        setEmail(state, data) {
            state.email = data;
        },

        setInitialData(state, data) {
            state.initialData = data;
        },

        setServerConfig(state, data) {
            state.serverConfig = data;
        },

        setPageBack(state, data) {
            state.pageBack[data.dbName] = data;
        },

        setURLParams(state, data) {
            state.urlParams = data
        },

        setScrollDirection(state, data) {
            state.scrollDirection = data
        },

        setDateRangeOption(state, data) {
            state.dateRangeOption = data;
        }
    },
    actions: {}
});