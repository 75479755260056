<template>
  <section>
    <b-field>
      <div class="control">
        <b-dropdown
          position="is-bottom-left"
          append-to-body
          aria-role="menu"
          trap-focus
          ref="mydd"
          v-model="selectedPeriod"
          expanded
        >
          <b-button slot="trigger" slot-scope="{ active }" expanded>
            <div class="columns is-mobile">
              <div class="column is-9">
                <span style="width: 220px">{{ selectedPeriodText }}</span>
              </div>
              <div class="column">
                <b-icon :icon="active ? 'menu-up' : 'menu-down'"></b-icon>
              </div>
            </div>
          </b-button>
          <div class="p-a-10">
            <b-field label="Ranges" class="has-text-left"></b-field>
            <b-dropdown-item
              aria-role="menu-item"
              value="past7days"
              :focus="false"
            >
              Past 7 Days
            </b-dropdown-item>
            <b-dropdown-item
              aria-role="menu-item"
              value="past30days"
              :focus="false"
            >
              Past 30 Days
            </b-dropdown-item>
            <b-dropdown-item aria-role="menu-item" value="today">
              Today
            </b-dropdown-item>
            <b-dropdown-item aria-role="menu-item" value="yesterday">
              Yesterday
            </b-dropdown-item>
            <b-dropdown-item aria-role="menu-item" value="lastmonth">
              Last Month
            </b-dropdown-item>
            <b-dropdown-item aria-role="menu-item" value="monthly/latest">
              This Month
            </b-dropdown-item>
            <hr class="dropdown-divider" aria-role="menuitem" />
          </div>

          <b-dropdown-item aria-role="menu-item" custom value="custom">
            <div class="columns is-mobile is-multiline">
              <div class="column" v-bind:class="{ active: isActive }">
                <b-field label="From" class="has-text-left">
                  <vc-date-picker
                    ref="start_tb"
                    v-model="start"
                    :masks="masks"
                    title-position="left"
                  >
                    <template #default="{ inputValue, togglePopover }">
                      <div class="control has-icons-left has-icons-right">
                        <input
                          class="date-input"
                          :value="inputValue"
                          placeholder="Now"
                          @click.stop="dateSelected($event, togglePopover)"
                          :readonly="true"
                        />
                        <span class="icon is-small is-right">
                          <i class="fas fa-calendar"></i>
                        </span>
                      </div>
                    </template>
                  </vc-date-picker>
                </b-field>

                <b-field label="To" class="has-text-left">
                  <vc-date-picker
                    ref="end_tb"
                    v-model="end"
                    :masks="masks"
                    title-position="left"
                  >
                    <template #default="{ inputValue, togglePopover }">
                      <div class="control has-icons-left has-icons-right">
                        <input
                          class="date-input"
                          :value="inputValue"
                          placeholder="01/01/1970"
                          @click.stop="dateSelected($event, togglePopover)"
                          :readonly="true"
                        />
                        <span class="icon is-small is-right">
                          <i class="fas fa-calendar"></i>
                        </span>
                      </div>
                    </template>
                  </vc-date-picker>
                </b-field>
              </div>
            </div>

            <div class="columns is-mobile">
              <div class="column is-half">
                <b-button type="is-primary" @click.prevent="_toResetPeriod()"
                  >Reset</b-button
                >
              </div>
              <div class="column is-half has-text-right">
                <b-button type="is-primary" @click.prevent="_toSubmitPeriod()"
                  >Apply</b-button
                >
              </div>
            </div>
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </b-field>
  </section>
</template>
<script>
import moment from "moment";

export default {
  name: "DateRangePicker",
  props: {
    daysBack: Number,
  },
  data() {
    return {
      dateFormat: "YYYY-MM-DD",
      dateFormat_: "YYYYMMDDHHmmss",
      start: null,
      end: null,
      params: {},
      masks: {
        input: "DD/MM/YYYY",
      },
      selected: {},
      selectedPeriod: "",
      selectedPeriodText: "",
      isActive: false,

      showWeekNumber: true,
      dates: {},
      minDateStart: "",
      maxDateStart: "",
      minDateEnd: "",
      maxDateEnd: "",
    };
  },
  created() {
    this.maxDateEnd = new Date(moment());
    this.maxDateStart = new Date(moment());

    this.minDateStart = new Date(moment().subtract(this.daysBack, "days"));
    this.minDateEnd = new Date(moment().subtract(this.daysBack, "days"));

    this.params = this.getURLParams();
    var se = this.getStartEndInterval(
      this.params.periodName,
      this.params.periodID
    );
    this.start = new Date(
      moment(se.start.substr(0, 8)).startOf("day").format("YYYY-MM-DD")
    );
    this.end = new Date(
      moment(se.end.substr(0, 8)).endOf("day").format("YYYY-MM-DD")
    );

    this.selectedPeriod = this._whichPeriod(se.start, se.end);
    this.selectedPeriodText = this.getStartEnd(se.start, se.end);
  },
  watch: {
    selectedPeriod() {
      this._selectedPeriod();
    },
  },
  methods: {
    _whichPeriod(_start, _end) {
      this.params = this.getURLParams();

      _start = moment(_start.substr(0, 8)).format(this.dateFormat);
      _end = moment(_end.substr(0, 8)).format(this.dateFormat);

      var start = "";
      var end = "";

      if (_start !== undefined && _end !== undefined) {
        start = moment()
          .startOf("month")
          .startOf("day")
          .format(this.dateFormat);
        end = moment(start).endOf("month").endOf("day").format(this.dateFormat);

        if (start == _start && end == _end) {
          if (this.params.periodID.toLowerCase() == "latest")
            return "monthly/latest";
        }

        //today
        start = moment().startOf("day").format(this.dateFormat);
        end = moment().endOf("day").format(this.dateFormat);

        if (start == _start && end == _end) {
          return "today";
        }

        //yesterday
        start = moment()
          .subtract(1, "days")
          .startOf("day")
          .format(this.dateFormat);
        end = moment().subtract(1, "days").endOf("day").format(this.dateFormat);
        if (start == _start && end == _end) {
          return "yesterday";
        }

        //lastMonth
        start = moment()
          .subtract(1, "month")
          .startOf("month")
          .startOf("day")
          .format(this.dateFormat);
        end = moment(moment().subtract(1, "month").endOf("month"))
          .endOf("day")
          .format(this.dateFormat);
        if (start == _start && end == _end) {
          if (this.params.periodName.toLowerCase() !== "monthly")
            return "lastmonth";
        }

        //past7days
        end = moment().subtract(1, "days").endOf("day").format(this.dateFormat);
        start = moment()
          .subtract(7, "days")
          .startOf("day")
          .format(this.dateFormat);
        if (start == _start && end == _end) {
          return "past7days";
        }

        //past30days
        end = moment().subtract(1, "days").endOf("day").format(this.dateFormat);
        start = moment()
          .subtract(30, "days")
          .startOf("day")
          .format(this.dateFormat);
        if (start == _start && end == _end) {
          return "past30days";
        }

        this.start = _start;
        this.end = _end;

        if (this.params.periodName.toLowerCase() == "monthly") {
          if (this.params.periodID.toLowerCase() !== "latest") {
            return "";
          }
        } else {
          return "custom";
        }
      } else {
        return "monthly/latest";
      }
    },

    _selectedPeriod() {
      if (this.selectedPeriod !== undefined) {
        this.params = this.getURLParams();
        var periodID = "";
        var sp = this.selectedPeriod.toLowerCase();
        this.isActive = false;

        switch (sp) {
          default:
            break;
          case "custom":
            this.isActive = true;

            if (moment(this.start) > moment(this.end)) {
              var temp = this.start;
              this.start = this.end;
              this.end = temp;
            }

            periodID =
              moment(this.start).startOf("day").format("YYYYMMDDHHmmss") +
              "-" +
              moment(this.end).endOf("day").format("YYYYMMDDHHmmss");
            break;
        }

        if (
          this.params.periodID !== null &&
          this.params.periodID !== undefined &&
          this.params.periodID.toLowerCase() == "csv"
        ) {
          this.$emit("setSelectPeriod", {
            periodName: sp,
            periodID: periodID,
            csv: "csv",
          });
        } else {
          if (
            this.params.csv !== null &&
            this.params.csv !== undefined &&
            this.params.csv.toLowerCase() == "csv"
          ) {
            this.$emit("setSelectPeriod", {
              periodName: sp,
              periodID: periodID,
              csv: "csv",
            });
          } else {
            this.$emit("setSelectPeriod", {
              periodName: sp,
              periodID: periodID,
            });
          }
        }
        var se = this.getStartEndInterval(sp, periodID);

        this.selectedPeriodText = this.getStartEnd(se.start, se.end);
      }
    },

    getStartEnd(_start, _end) {
      var a = "";

      var start = moment(_start.substr(0, 8)).format("D");
      var end = moment(_end.substr(0, 8)).format("D");

      var monthYearStart = moment(_start.substr(0, 8)).format("MMM Y");
      var monthYearEnd = moment(_end.substr(0, 8)).format("MMM Y");

      var monthStart = moment(_start.substr(0, 8)).format("MMM");
      var monthEnd = moment(_end.substr(0, 8)).format("MMM");

      var yearStart = moment(_start.substr(0, 8)).format("YYYY");
      var yearEnd = moment(_end.substr(0, 8)).format("YYYY");
      if (
        monthYearStart.toLowerCase() !== "invalid date" &&
        monthYearEnd.toLowerCase() !== "invalid date"
      ) {
        if (yearStart == yearEnd) {
          if (monthYearStart == monthYearEnd) {
            if (start == end) {
              a = start + " " + monthYearStart;
            } else {
              a = start + " - " + end + " " + monthYearStart;
            }
          } else {
            a =
              start +
              " " +
              monthStart +
              " - " +
              end +
              " " +
              monthEnd +
              " " +
              yearStart;
          }
        } else {
          a = start + " " + monthYearStart + " - " + end + " " + monthYearEnd;
        }
      } else {
        a = "Invalid date format.";
      }

      return a;
    },

    dateSelected(e, toggle) {
      toggle({ ref: e.target });
    },

    _toSubmitPeriod() {
      if (
        this.$refs.start_tb.value == null ||
        this.$refs.end_tb.value == null
      ) {
        this.toast("danger", "Either start or end date could not be empty");
      } else {
        this.selectedPeriod = "custom";
        this._selectedPeriod();
        this.$refs.mydd.toggle();
      }
    },

    _toResetPeriod() {
      this.selectedPeriod = "monthly/latest";
      this._selectedPeriod();

      this.$refs.mydd.toggle();
    },
  },
};
</script>
<style>
.date-range-picker-button {
  min-width: 40px;
  max-width: 70px;
  white-space: normal;
}

button.active:hover {
  color: #fff;
}

.p-a-10 {
  padding: 10px;
}

.date-input,
.date-input:focus {
  width: 100%;
  outline: 0;
  border-width: 0 0 1px;
  height: 36px;
}

::placeholder {
  color: #ccc;
}

.dropdown-item,
.dropdown-item .date-input,
.vc-container {
  font-family: Bai Jamjuree !important;
}

.vc-header .vc-title {
  font-size: 1.1em !important;
}

.dropdown-menu .active,
a.dropdown-item.is-active,
.dropdown .dropdown-menu .has-link a.is-active,
button.dropdown-item.is-active {
  background-color: #ff9 !important;
  color: #000;
  font-weight: bold;
}
</style>