<template>
  <div>
    <b-loading
      :is-full-page="isFullPage"
      :active.sync="isLoading"
      :can-cancel="true"
    ></b-loading>

    <div class="_wrapper">
      <div class="card">
        <div class="card-content">
          <div class="content" style="margin-top: 10vh">
            <h1 style="color: red">INVALID URL!</h1>

            <div class="columns" style="margin-top: 50px">
              <div class="column"></div>
              <div class="column is-half has-text-left">
                The URL is not recognized by our Server. It is either expired,
                or you have mistyped the URL. Please retry your activation or
                check if the URL is entered correctly.
                <br />
                <br />
                If you are clicking the link from an email, please make sure
                that you are clicking from the latest email.
              </div>
              <div class="column"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "PreparingUnSuccessContent",
  components: {},
  props: {
    waitingText: {
      type: String,
    },
  },

  data() {
    return {
      isFullPage: true,
      isLoading: false,
    };
  },
  watch: {
    waitingText() {},
  },
  created() {},
};
</script>
<style scoped>
.card-header-title {
  font-size: 1.5em !important;
}

.card-content {
  min-height: 75vh;
}
</style>
