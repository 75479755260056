<template>
  <footer class="_footer">
    <div class="columns">
      <div class="column" style="font-size: 1.2em">
        Call Center 02-651-4700 ext. 444
      </div>
    </div>
    <div class="columns">
      <div class="column" style="margin-top: -10px">
        {{ $websiteName }} V.{{ $version }}, Copyright ©{{
          this.getCopyrightYear()
        }},
        <a :href="companyurl" target="_blank">Dataxet Limited.</a>
        All Rights Reserved. |
        <a :href="eulaurl" target="_blank">Term of Service</a> |
        <a :href="contactusurl" target="_blank">Contact Us</a>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "footerLayout",
  props: {
    msg: String,
    eulaurl: String,
    companyurl: String,
    contactusurl: String,
  },
};
</script>

<style>
._footer {
  width: 100%;
  overflow: hidden;
}

._footer_color {
  background-color: #f1f1f1 !important;
}
</style>
