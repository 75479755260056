<script>
import { HorizontalBar, mixins } from "vue-chartjs";

export default {
  name: "Bar",
  mixins: [mixins.reactiveProp],
  extends: HorizontalBar,
  props: {
    chartData: {
      type: Object,
      default: null,
    },
    options: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      timoutHandle: null,
    };
  },
  watch: {
    chartData() {
      try {
        if (this.chartData !== null)
          this.renderChart(this.chartData, this.options);
      } catch (ex) {
        console.log(ex);
      }
    },
  },
  mounted() {
    try {
      if (this.chartData !== null) {
        this.renderChart(this.chartData, this.options);
      }
    } catch (ex) {
      console.log(ex);
    }
  },
  methods: {},
};
</script>
