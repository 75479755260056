<template>
  <div>
    <b-navbar class="is-fixed-top" v-if="astate !== 0">
      <template slot="brand">
        <b-navbar-item v-if="astate == 1" tag="router-link" :to="{ path: '' }">
          <a class="navbar-item" @click.prevent="_gotoSummaryPage()">
            <img src="../assets/img/io_logo.png" :alt="$websiteDescription" />
          </a>
        </b-navbar-item>
        <b-navbar-item v-if="astate == 2">
          <a class="navbar-item no-cursor">
            <img src="../assets/img/io_logo.png" :alt="$websiteDescription" />
          </a>
        </b-navbar-item>
      </template>
      <!-- <template slot="end">
        <b-navbar-dropdown :label="email" v-if="astate == 1">
          <b-navbar-item
            v-if="email !== undefined && email.length > 0"
            @click="_logout()"
            ><template
              ><b-icon icon="logout"></b-icon><span>Logout</span></template
            ></b-navbar-item
          >
          <b-navbar-item v-else @click="_login()"> Login </b-navbar-item>
        </b-navbar-dropdown>
      </template> -->
      <template slot="end">
          <div class="top-right-container">
            <div v-if="email !== undefined && email.length > 0" class="top-item">
              <div class="top-item">
                  <b-icon icon="person"></b-icon>
              </div>
              <div  class="top-item">{{email}}</div>
              <!-- <div class="bell-notification">
                <span class="bell-notification-child">
                  <Notification></Notification>
                  </span>
              </div> -->
              <div class="top-item icon-hover" @click="_logout()">
                <b-icon icon="logout" ></b-icon>
              </div>
            </div>
            <div v-else @click="_login()"> Login </div>
          </div>
      </template> 
    </b-navbar>
    <b-loading
      :is-full-page="isFullPage"
      :active.sync="isLoading"
      :can-cancel="false"
    ></b-loading>
    <div class="_wrapper" v-if="astate == 1">
      <div class="" v-if="isFoundData == true && isLoading == false">
        <div v-if="state == 0">
          <div slot="trigger" class="card-header" role="button">
            <p class="card-header-title m-l-15">Data Import</p>
            <div class="buttons is-pulled-right">
              <b-button
                type="is-primary"
                class=""
                @click="_gotoCleansingIndex()"
              >
                <span>Back</span>
              </b-button>
            </div>
          </div>

          <div class="columns">
            <div class="column">
              <div class="card">
                <div class="card-content">
                  <div class="content">
                    <div class="columns">
                      <div
                        class="
                          column
                          is-4-desktop is-full-mobile
                          has-text-left has-text-weight-bold
                        "
                      >
                        Importing file extension
                      </div>
                      <div
                        class="column is-8-desktop is-full-mobile has-text-left"
                      >
                        {{ this.validExtension[0] }}
                        <b-button
                          tag="a"
                          type="is-primary"
                          :href="csvPath"
                          size="is-small"
                          >Download sample</b-button
                        >
                      </div>
                    </div>
                    <div class="columns">
                      <div
                        class="
                          column
                          is-4-desktop
                          has-text-left has-text-weight-bold
                        "
                      >
                        Column format
                      </div>
                      <div class="column is-8-desktop has-text-left">
                        {{ this.validColumnFormat[0] }}
                      </div>
                    </div>
                    <div class="columns">
                      <div
                        class="
                          column
                          is-4-desktop
                          has-text-left has-text-weight-bold
                        "
                      >
                        Maximum File Size
                      </div>
                      <div class="column is-8-desktop has-text-left">
                        {{ this.config.$uploadMaxFileSize }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column is-3-desktop">
              <div class="card card-overflow" style="min-height: 55vh">
                <div class="card-content">
                  <div class="content">
                    <b-field>
                      <div class="has-text-left">
                        <b-icon
                          icon="numeric-1-circle-outline"
                          size="is-medium"
                        ></b-icon>
                        <b-button
                          id="browseButton"
                          type="is-primary"
                          :disabled="this.pendingData.length > 0"
                        >
                          <template>
                            <span>Browse ...</span>
                          </template>
                        </b-button>
                      </div>
                    </b-field>

                    <div class="columns">
                      <div class="column is-full" v-if="file_">
                        <div class="divider">File Info</div>
                        <div class="columns">
                          <div class="column has-text-left">
                            <div class="has-text-weight-bold">
                              File Name:
                              <b-icon
                                pack="fas"
                                icon="file-excel"
                                v-if="this.extension == 'xlsx'"
                              ></b-icon>
                              <b-icon
                                pack="fa"
                                icon="file-csv"
                                v-if="this.extension == 'csv'"
                              ></b-icon>
                            </div>
                            <div
                              class="filename has-text-left is-text-overflow"
                              style="width: 90%"
                              v-if="this.file_.fileName !== undefined"
                              :title="file_.fileName"
                            >
                              {{ this.file_.fileName }}
                            </div>
                          </div>
                        </div>

                        <div class="columns">
                          <div
                            class="
                              column
                              is-3-desktop
                              has-text-left has-text-weight-bold
                            "
                          >
                            Size:
                          </div>
                          <div class="column has-text-left">
                            {{ this.file_.size | prettyBytes(1) }}
                          </div>
                        </div>

                        <div class="columns">
                          <div class="column has-text-left">
                            <div class="has-text-weight-bold">
                              Last modified date:
                            </div>
                            <div class="has-text-left">
                              {{
                                this.file_.file.lastModified !== undefined
                                  ? timeStamp2Time(this.file_.file.lastModified)
                                  : timeStamp2Time(
                                      this.file_.file.lastModifiedDate
                                    )
                              }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="column is-9" v-if="state == 0">
              <div class="card card-overflow" style="min-height: 55vh">
                <div class="card-content">
                  <div class="content">
                    <form v-if="this.file_">
                      <div class="columns">
                        <div class="column is-4">
                          <h5 class="is-5 has-text-left">
                            <b-icon
                              icon="numeric-2-circle-outline"
                              size="is-medium"
                            ></b-icon
                            >Select topic
                          </h5>
                        </div>
                        <div class="column">
                          <b-field>
                            <b-select
                              placeholder="Select topic"
                              ref="topicid"
                              expanded
                              required
                              icon="cube"
                              iconPack="fas"
                              v-model="selectedTopicID"
                              @input="onChangeTopic"
                            >
                              <option
                                v-for="item in oTopics"
                                :value="item['TopicID']"
                                :key="item['QueryName']"
                              >
                                {{ item["QueryName"] }}
                              </option>
                            </b-select>

                            <b-button
                              type="is-primary"
                              @click.prevent="getRecordsInfo()"
                              :disabled="this.pendingData.length !== 0"
                            >
                              <span>Submit</span>
                            </b-button>
                          </b-field>
                        </div>
                      </div>
                      <hr />
                    </form>

                    <div v-if="this.pendingData.length > 0">
                      <div class="columns">
                        <div class="column is-4">
                          <h5 class="has-text-left">{{ textUploading }}</h5>
                        </div>
                        <div class="column is-6">
                          <b-progress
                            :type="type"
                            :size="size"
                            :value="progressBarValue || 0"
                            :show-value="showValue"
                            :format="format"
                            :precision="precision"
                            :keep-trailing-zeroes="keepTrailingZeroes"
                          ></b-progress>
                          [{{ number2Commas(processingRows) }} out of
                          {{ number2Commas(totalRows) }}]
                        </div>
                        <div class="column has-text-right">
                          <b-button
                            type="is-danger"
                            size="is-small"
                            :disabled="this.pendingData.length == 0"
                            @click.prevent="_deleteJob()"
                          >
                            <span>Delete Job</span>
                          </b-button>
                        </div>
                      </div>
                      <div class="columns">
                        <div class="column">
                          <b-table
                            sticky-header
                            :height="150"
                            :data="pendingData"
                            :bordered="isBordered"
                            :striped="isStriped"
                            :narrowed="isNarrowed"
                            :hoverable="isHoverable"
                            :is-row-checkable="(row) => row.rows > 0"
                            aria-next-label="Next page"
                            aria-previous-label="Previous page"
                            aria-page-label="Page"
                            aria-current-label="Current page"
                            aria-controls="__content"
                          >
                            <template>
                              <b-table-column label="#" v-slot="props">{{
                                props.row.no
                              }}</b-table-column>
                              <b-table-column label="File" v-slot="props">
                                <p class="table-filename">
                                  {{ props.row.fileName }}
                                </p>
                              </b-table-column>

                              <b-table-column label="Sheet" v-slot="props">{{
                                props.row.sheetName
                              }}</b-table-column>
                              <b-table-column label="Start" v-slot="props">{{
                                number2Commas(props.row.start)
                              }}</b-table-column>
                              <b-table-column label="End" v-slot="props">{{
                                number2Commas(props.row.end)
                              }}</b-table-column>
                              <b-table-column label="Status" v-slot="props">{{
                                props.row.status
                              }}</b-table-column>
                              <b-table-column
                                label="# Insert rows"
                                v-slot="props"
                                >{{
                                  number2Commas(props.row.numInsert)
                                }}</b-table-column
                              >
                              <b-table-column
                                label="# Overwrite rows"
                                v-slot="props"
                                >{{
                                  number2Commas(props.row.numOverwrite)
                                }}</b-table-column
                              >

                              <!-- <b-table-column
                                v-for="(column, index) in columnsPending"
                                :key="index"
                                :label="column.label"
                            >{{ props.row[column.field] }}</b-table-column>-->
                            </template>
                            <template slot="empty" v-if="isLoading == false">
                              <section class="section">
                                <div class="content has-text-grey">
                                  <p class="has-text-centered">
                                    <b-icon
                                      icon="emoticon-sad"
                                      size="is-large"
                                    ></b-icon>
                                  </p>
                                  <p class="has-text-centered">
                                    No record found here.
                                  </p>
                                </div>
                              </section>
                            </template>
                          </b-table>
                        </div>
                      </div>
                    </div>

                    <div
                      class="columns"
                      v-if="file_ !== null && summaryData.length > 0"
                    >
                      <div class="column">
                        <h5 class="is-5 has-text-left">
                          <b-icon
                            icon="numeric-3-circle-outline"
                            size="is-medium"
                          ></b-icon
                          >Select any sheets to upload
                        </h5>

                        <b-table
                          sticky-header
                          :data="summaryData == undefined ? [] : summaryData"
                          :height="150"
                          :bordered="isBordered"
                          :striped="isStriped"
                          :narrowed="isNarrowed"
                          :hoverable="isHoverable"
                          :is-row-checkable="(row) => row.rows > 0"
                          aria-next-label="Next page"
                          aria-previous-label="Previous page"
                          aria-page-label="Page"
                          aria-current-label="Current page"
                          aria-controls="__content"
                          @check="onCheck"
                          :checked-rows.sync="checkedRows"
                          checkable
                          :checkbox-position="checkboxPosition"
                        >
                          <template>
                            <b-table-column
                              field="sheetName"
                              label="Sheet Name"
                              :sortable="false"
                              v-slot="props"
                              ><div class="has-text-left">
                                {{ props.row.sheetName }}
                              </div></b-table-column
                            >

                            <b-table-column
                              field="rows"
                              label="# Rows"
                              class="has-text-right"
                              :numeric="true"
                              :sortable="false"
                              v-slot="props"
                              >{{
                                number2Commas(props.row.rows)
                              }}</b-table-column
                            >
                          </template>
                          <template slot="empty" v-if="isLoading == false">
                            <section class="section">
                              <div class="content has-text-grey">
                                <p class="has-text-centered">
                                  <b-icon
                                    icon="emoticon-sad"
                                    size="is-large"
                                  ></b-icon>
                                </p>
                                <p class="has-text-centered">
                                  No record found here.
                                </p>
                              </div>
                            </section>
                          </template>
                        </b-table>

                        <div class="columns">
                          <div class="column is-4"></div>
                          <div class="column is-4">
                            <b-field>
                              <b-icon
                                icon="numeric-4-circle-outline"
                                size="is-medium"
                              ></b-icon>
                              <b-button
                                id="assignButon"
                                type="is-primary"
                                :disabled="checkedRows.length == 0"
                                @click.prevent="assignJob"
                              >
                                <span>Start importing data</span>
                              </b-button>
                            </b-field>
                          </div>
                          <div class="column is-4"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="column" v-if="state == 1">
          <div class="card card-overflow" style="height: 83vh">
            <div class="card-header">
              <p class="card-header-title">Upload Results</p>
            </div>
            <div class="card-content">
              <div class="content">
                <div class="columns">
                  <div class="column">
                    <b-message type="is-success" has-icon
                      ># Inserted rows ({{
                        number2Commas(this.results_insert)
                      }}
                      records)</b-message
                    >
                  </div>
                  <div class="column">
                    <b-message type="is-success" has-icon>
                      # Overwritten rows ({{
                        number2Commas(this.results_overwrite)
                      }}
                      records)
                    </b-message>
                  </div>
                </div>
                <div class="columns">
                  <div class="column">
                    <b-table
                      :data="results == undefined ? [] : results"
                      :bordered="isBordered"
                      :striped="isStriped"
                      :narrowed="isNarrowed"
                      :hoverable="isHoverable"
                      :paginated="success.length > perPage"
                      :total="success.length"
                      :per-page="this.config.$uploadDisplayPerPage"
                    >
                      <template>
                        <!-- { field: "sheetName", label: "Sheet Name" },
        { field: "status", label: "Status" },
        { field: "numInsert", label: "# Inserted rows", numeric: true },
        { field: "numOverwrite", label: "# Overwrite rows", numeric: true }, -->
                        <b-table-column
                          field="sheetName"
                          label="Sheet Name"
                          :sortable="false"
                          v-slot="props"
                          ><div class="has-text-left">
                            {{ props.row.sheetName }}
                          </div></b-table-column
                        >

                        <b-table-column
                          field="status"
                          label="Status"
                          :sortable="false"
                          v-slot="props"
                          ><div class="has-text-left">
                            {{ props.row.status }}
                          </div></b-table-column
                        >

                        <b-table-column
                          field="numInsert"
                          label="# Inserted Rows"
                          :sortable="false"
                          v-slot="props"
                          :width="200"
                          ><div class="has-text-right">
                            {{ props.row.numInsert }}
                          </div></b-table-column
                        >

                        <b-table-column
                          field="numOverwrite"
                          label="# Overwrite Rows"
                          :sortable="false"
                          v-slot="props"
                          :width="200"
                          ><div class="has-text-right">
                            {{ props.row.numOverwrite }}
                          </div></b-table-column
                        >
                      </template>
                      <template slot="empty" v-if="isLoading == false">
                        <section class="section">
                          <div class="content has-text-grey">
                            <p class="has-text-centered">
                              <b-icon
                                icon="emoticon-sad"
                                size="is-large"
                              ></b-icon>
                            </p>
                            <p class="has-text-centered">
                              No record found here.
                            </p>
                          </div>
                        </section>
                      </template>
                    </b-table>
                    <hr />
                    <div class="level">
                      <div class="level-left">
                        <div class="level-item">
                          <b-field>
                            <b-button
                              type="is-primary"
                              @click.prevent="gotoDetailReportPage()"
                            >
                              <span>View Report</span>
                            </b-button>
                          </b-field>
                        </div>
                      </div>

                      <div class="level-right">
                        <div class="level-item">
                          <b-button
                            type="is-primary"
                            @click.prevent="_gotoUploadPage()"
                          >
                            <span>Back</span>
                          </b-button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <NotFound v-if="isFoundData == false && isLoading == false"></NotFound>
      <Preparing
        :waitingText="this.waitingText"
        v-if="this.isLoading == true"
      ></Preparing>
    </div>
    <NotAuthorizeEditor v-if="astate == 2"></NotAuthorizeEditor>
    <Footer
      v-if="isLoading == false"
      :eulaurl="eulaurl"
      :contactusurl="contactusurl"
      :companyurl="companyurl"
    ></Footer>
  </div>
</template>

<script>
import Footer from "../layout/Footer.vue";
import XLSX from "xlsx";
import Resumable from "resumablejs";
import NotFound from "../contents/notfound";
import aes from "crypto-js/aes";
import encHex from "crypto-js/enc-hex";
import padZeroPadding from "crypto-js/pad-zeropadding";
import Preparing from "../contents/preparing";
var _ = require("lodash");
import NotAuthorizeEditor from "../pages/NotAuthorizeEditor";

export default {
  name: "uploadDataPage",
  components: {
    Footer,
    NotFound,
    Preparing,
    NotAuthorizeEditor,
  },
  props: {},
  data() {
    return {
      eulaurl: "",
      contactusurl: "",
      companyurl: "",

      astate: 0,
      isDeleteJob: false,
      textUploading: "Uploading in progress, please wait...",
      csvPath: "",
      countComplete: 0,
      perPage: 50,
      indeterminate: false,
      progressBarValue: 0,
      type: "is-success",
      size: "is-medium",
      showValue: true,
      format: "percent",
      precision: 2,
      keepTrailingZeroes: false,

      percent_load: 0,
      config: {},
      r: {},
      fileValidation: [],
      errors_: [],
      total: "",
      email: "",
      state: 0,

      oTopics: {},
      labelTopicName: "QueryName",
      valueTopicName: "TopicID",
      selectedTopicID: "",
      selectedTopicName: "",
      iconTopic: "cube",
      iconPackTopic: "fas",

      isLoading: true,
      isFoundData: true,
      isFullPage: true,
      isImporting: false,

      file_: null,
      polling: null,

      json_object: [],
      summaryData: [],
      pendingData: [],
      ws: [],
      sheetsName: [],

      checkboxPosition: "left",
      checkedRows: [],

      processingRows: 0,
      totalRows: 0,
      tabCount: 0,

      isBordered: false,
      isStriped: true,
      isNarrowed: true,
      isHoverable: true,

      hasMobileCards: true,

      success: [],
      overwrite: [],
      results: [],
      results_insert: 0,
      results_overwrite: 0,
      columns: [
        { field: "Account", label: "Account" },
        { field: "Message", label: "Message" },
        { field: "Source", label: "Source" },
        { field: "Post time", label: "Post Time" },
        { field: "Sentiment", label: "Sentiment" },
      ],

      validExtension: [],
      validColumnFormat: [],
      oValidExtension: [],
      extension: "",
      bDisabledImport: false,
      gap: 33,
      waitingText: "Preparing data, please wait...",
    };
  },

  async created() {
    await this.getServerConfig().then((config) => {
      this.config = config;
      this.hexkey = this.config.$HexKey;
      this.hexiv = this.config.$HexIV;
      this.eulaurl = this.config.EULAURL;
      this.companyurl = this.config.CompanyURL;
      this.contactusurl = this.config.ContactUsURL;

      this.oValidExtension = this.config.$uploadValidExtension;

      this.config.$uploadValidExtension = this.parseExtension(
        this.config.$uploadValidExtension
      );
    });
    this.params = this.getURLParams();

    this.selectedTopicID = this.params.TopicID;

    var iquuid = this.readLocalStorage("iquuid2", this.params.dbName);
    var BrowserID = this.getBrowserID();
    this.$store.commit("setPageBack", this.params);
    if (iquuid == null) {
      this.gotoVerifyEmail();
    } else {
      if (await this.isValidDatabase()) {
        await this.getInitialData();
        if (await this._isValidURL(this.params)) {
          this.params.pageBack = "uploaddata";
          this.params.page = this.params.pageBack;
          var res = await this.checkPermission(
            iquuid,
            BrowserID,
            this.params.page
          );

          var _continue = res.data.continue;
          var _goto = res.data.goto;
          var _clearUUID = res.data.clearUUID;

          if (_continue) {
            if (_goto == 401) {
              this.astate = 2;
              this.isLoading = false;
              this.toast(
                "danger",
                "Restricted section. Only valid email can visit this page."
              );
            } else {
              this.gaPage();
              this.astate = 1;

              this.csvPath = this.$databasePath + "/Dashboard/downloadCSV";

              this.errors_ = [];

              this.email = await this.getUserEmail();

              await this._getProcessingJob();

              await this.setRefreshPage();

              this.isLoading = false;
              setTimeout(() => {
                this.uploadEvents();
              }, 200);
            }
          } else {
            if (_goto == 401 && _clearUUID == true) {
              this.deleteLocalStorage("iquuid2", this.params.dbName);
            }

            if (_goto == "verifyemail") {
              clearInterval(this.polling);
              this.gotoVerifyEmail();
            } else {
              this.astate = 2;
              this.isLoading = false;
              this.toast(
                "danger",
                "Restricted section. Only valid email can visit this page."
              );
            }
          }
        } else {
          this.gotoNotFound(this.params.dbName);
        }
      } else {
        this.redirectHost(this.params);
      }
    }
  },

  watch: {
    summaryData() {},
  },

  beforeDestroy() {
    clearInterval(this.polling);
  },

  methods: {
    async _isValidURL(params) {
      var a =
        this.oTopics !== undefined && Object.keys(this.oTopics).length > 0
          ? true
          : false;

      var b = this.isValidPeriod(params);
      this.start = b.start;
      this.end = b.end;
      b = b.result;

      var c = false;

      if (params.TopicID.length == 10) c = false;
      var cutLength = params.TopicID.length;

      for (var i in this.oTopics) {
        if (this.oTopics[i].TopicID.substr(0, cutLength) == params.TopicID) {
          c = true;
          break;
        }
      }
      return a && b && c;
    },
    _login() {
      this.gotoVerifyEmail();
    },
    _logout() {
      const params = this.getURLParams();
      this.deleteLocalStorage("iquuid2", params.dbName);
      this.deleteLocalStorage("iqgoauth2");
      this.$store.commit("setPageBack", params);
      this.writeLog("verifyemail", [
        {
          key: "originalURL",
          value: location.href,
        },
        { key: "remark", value: "logout" },
        { key: "email", value: this.email },
      ]);
      this.email = "";
      this.gotoVerifyEmail();
    },
    _gotoSummaryPage() {
      this.params = this.getURLParams();
      var item = {
        row: {
          DatabaseName: this.params.dbName,
          ProfileID: this.params.TopicID.toLowerCase().substr(0, 6),
        },
      };

      this.gotoSummary(item);
    },

    async getInitialData() {
      const params = this.getURLParams();
      params["isCleansing"] = 0;
      params["iquuid"] = this.readLocalStorage("iquuid2", params.dbName);
      params["logType"] = "uploaddata";

      await this.httpRequest("Dashboard/getInitialData", params).then(() => {
        if (
          this.$result.data.periods !== null &&
          this.$result.data.periods.data !== undefined &&
          this.$result.data.periods.data.length > 0
        ) {
          this.oTopics =
            Object.keys(this.$result.data.topics).length > 0
              ? this.$result.data.topics.data[0].Query
              : {};
        }
      });
    },
    _gotoCleansingIndex() {
      this._deleteJob();
      this.params = this.getURLParams();
      var p = [];
      p =
        this.params.periodID !== null
          ? [
              "cleansingindex",
              this.params.dbName,
              this.params.TopicID,
              this.params.periodName,
              this.params.periodID,
            ]
          : [
              "cleansingindex",
              this.params.dbName,
              this.params.TopicID,
              this.params.periodName,
            ];

      p = "/" + p.join("/");
      this.goto(p);
    },

    parseExtension(ext) {
      let valid_extension = [];
      this.validExtension = [];
      let self = this;
      Object.keys(ext).forEach(function (value) {
        if (ext[value]["show"] == true) {
          var label = "*." + value + " (" + ext[value]["description"] + ")";
          self.validExtension.push(label);
          self.validColumnFormat.push(ext[value]["label"]);
          valid_extension.push(value);
        }
      });
      return valid_extension;
    },
    async _getProcessingJob() {
      const params = this.getURLParams();
      params["email"] = this.email;

      await this.httpRequest("Dashboard/getProcessingJob", params)
        .then((res) => {
          res = this.$result.data;
          if (res.result == 200) {
            this.state = 0;
            this.file_ = res.message;

            this.selectedTopicID = res.message.selectedTopicID;
            this.progressBarValue = res.message.progressBarValue * 1;
            return res;
          } else {
            //no processing file left in queue
            this.state = 0;
            this.file_ = null;
            this.summaryData = [];
            this.pendingData = [];
            this.processingRows = 0;

            this.progressBarValue = 0;

            this.results = [];
            this.results_insert = 0;
            this.results_overwrite = 0;
            clearInterval(this.polling);
          }
        })
        .then((res) => {
          if (res) {
            if (res.result == 200) {
              this.getRecordsInfo();
            } else {
              if (res.message) this.toast("danger", res.message);
            }
          }
        });
    },

    async _deleteJob() {
      this.errors_ = [];
      const params = this.getURLParams();
      var iquuid = this.readLocalStorage("iquuid2", params.dbName);
      params["email"] = this.email;
      params["TopicID"] = this.selectedTopicID;
      params["iquuid"] = iquuid;
      this.$store.commit("setPageBack", params);
      if (iquuid == null) {
        this.gotoVerifyEmail();
      } else {
        await this.httpRequest("Dashboard/deleteJob", params).then((res) => {
          res = this.$result.data;
          this.writeLog("uploaddata", [
            { key: "dbName", value: params.dbName },
            { key: "email", value: this.$result.data.email },
            { key: "originalURL", value: location.href },
            {
              key: "remark",
              value:
                "Delete Job [" +
                params["dbName"] +
                "-" +
                this.selectedTopicID +
                "]",
            },
          ]);
          if (res.result == 200) {
            if (this.file_ !== null)
              if (res.message.length) this.toast("success", res.message);
          } else {
            if (res.message.length) this.toast("danger", res.message);
          }
          window.location.reload();
        });

        await this._getProcessingJob();
      }
    },
    async _manualProcessInsert() {
      const params = this.getURLParams();
      var iquuid = this.readLocalStorage("iquuid2", params.dbName);
      this.$store.commit("setPageBack", params);
      if (iquuid == null) {
        this.gotoVerifyEmail();
      } else {
        try {
          if (this.errors_.length == 0) {
            params["isCleansing"] = 0;
            params["email"] = this.email;
            params["iquuid"] = iquuid;
            params["TopicID"] = this.selectedTopicID;
            let self = this;

            _.forEach(self.pendingData, function (v) {
              if (
                v.status !== undefined &&
                v.status.trim().toLowerCase() == "pending"
              ) {
                v.status = "starting";
                return false;
              }
            });
            await this.httpRequest(
              "Dashboard/manualProcessInsert",
              params
            ).then((res) => {
              res = this.$result.data;
              if (res.fileName !== undefined) {
                this.writeLog("uploaddata", [
                  { key: "dbName", value: params.dbName },
                  { key: "email", value: this.email },
                  { key: "originalURL", value: location.href },
                  {
                    key: "remark",
                    value:
                      "Batch Insert [" +
                      res.fileName +
                      " - " +
                      params["dbName"] +
                      " - " +
                      this.selectedTopicID +
                      " - " +
                      res.sheetName +
                      "]",
                  },
                ]);
              }
              var countComplete = 1;
              this.results = [];
              _.forEach(self.pendingData, function (v) {
                if (
                  v.status !== undefined &&
                  v.status.trim().toLowerCase() == "starting"
                ) {
                  v.numInsert = res.num_insert * 1;
                  v.numOverwrite = res.num_overwrite * 1;
                  self.processingRows += v.numInsert + v.numOverwrite;
                  v.status = res.message;
                  return false;
                } else {
                  if (res.result == 200) {
                    countComplete++;
                    document.querySelector(".has-sticky-header").scrollTo({
                      top: countComplete * self.gap,
                      behavior: "smooth",
                    });
                  }
                }
              });

              if (res.result && res.result == 200) {
                clearInterval(this.polling);

                if (parseInt(self.pendingData.length) > 0) {
                  this.progressBarValue =
                    (countComplete / self.pendingData.length) * 100;
                } else {
                  this.progressBarValue = 0;
                }

                if (countComplete == 1) {
                  document
                    .querySelector(".has-sticky-header")
                    .scrollTo({ top: 0, behavior: "smooth" });
                }

                setTimeout(() => {
                  this._manualProcessInsert();
                }, 3000);
              } else if (
                countComplete >= this.pendingData.length ||
                this.progressBarValue >= 100
              ) {
                _.forEach(self.pendingData, function (v) {
                  if (!self.results[v.sheetName]) {
                    self.results[v.sheetName] = {
                      insert: v.numInsert * 1,
                      overwrite: v.numOverwrite * 1,
                      status: v.status,
                    };
                  } else {
                    var sumInsert =
                      self.results[v.sheetName]["insert"] * 1 + v.numInsert * 1;
                    self.results[v.sheetName]["insert"] = sumInsert;

                    var sumOverwrite =
                      self.results[v.sheetName]["overwrite"] * 1 +
                      v.numOverwrite * 1;
                    self.results[v.sheetName]["overwrite"] = sumOverwrite;
                  }
                });

                var rr = [];
                for (var key in self.results) {
                  var e = {
                    sheetName: key,
                    numInsert: self.results[key].insert * 1,
                    numOverwrite: self.results[key].overwrite * 1,
                    status: self.results[key].status,
                  };
                  this.results_insert += self.results[key].insert * 1;
                  this.results_overwrite += self.results[key].overwrite * 1;

                  rr.push(e);
                }
                this.results = rr;

                if (res.result == 401) {
                  this.isDeleteJob = true;
                  this.toast(
                    "danger",
                    "Importing data fail, please see their error detail."
                  );
                  this.bDisabledImport = true;
                  this.isLoading = false;
                } else {
                  this.toast("success", "Importing data finished.");
                  this.pendingData = [];
                  this.summaryData = [];
                  setTimeout(() => {
                    this.getRecordsInfo();
                  }, 2000);
                  this.setRefreshPage();

                  this.state = 1;
                  this.isLoading = false;
                }
              } else {
                this.isDeleteJob = true;
                this.toast(
                  "danger",
                  "Importing data fail, please see their error detail."
                );

                this.bDisabledImport = true;
                this.isLoading = false;
                clearInterval(this.polling);
              }
            });
          } else {
            this.toast("danger", this.errors_[0]);
          }
        } catch (e) {
          this.isLoading = false;
        }
      }
    },
    async setRefreshPage() {
      let self = this;
      this.polling = setInterval(() => {
        self.summaryData = [];
        self.pendingData = [];
        if (self.file_ !== null) {
          if (self.selectedTopicID) self.getRecordsInfo();
        }
      }, this.config.$uploadRefreshDuration * 60 * 1000);
    },
    async assignJob() {
      const params = this.getURLParams();
      var iquuid = this.readLocalStorage("iquuid2", params.dbName);
      this.$store.commit("setPageBack", params);
      if (iquuid == null) {
        this.gotoVerifyEmail();
      } else {
        params["isCleansing"] = 0;
        params["TopicID"] = this.selectedTopicID;
        params["iquuid"] = iquuid;
        params["fileName"] = this.file_.fileName;
        params["sheets"] = JSON.stringify(this.checkedRows);
        params["email"] = this.email;
        params["uploadBatchAmount"] = this.config.$uploadBatchAmount;
        params["TopicName"] = this.selectedTopicName;
        params["totalRows"] = this.totalRows;
        let key = encHex.parse(this.hexkey);
        let iv = encHex.parse(this.hexiv);
        var encrypted = aes
          .encrypt(this.$prefixAuthKey + "-" + Date.now(), key, {
            iv: iv,
            padding: padZeroPadding,
          })
          .toString();
        params["AuthKey"] = encrypted;

        await this.httpRequest("Dashboard/assignJob", params)
          .then((res) => {
            res = this.$result.data;
            if (res.result == 200) {
              if (res.message) this.toast("success", res.message);
              this.progressBarValue = 0;
              setTimeout(() => {
                this.getRecordsInfo();
              }, 2000);
            } else {
              if (res.message) this.toast("danger", res.message);
            }
          })
          .then(() => {
            setTimeout(() => {
              this._manualProcessInsert();
            }, 3000);
          });
      }
    },
    bytesToSize(bytes) {
      var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
      if (bytes == 0) return 0;

      for (var i = 0; i < sizes.length; i++) {
        if (bytes.indexOf(sizes[i]) > 0) {
          var b = bytes.replace(sizes[i], "") * Math.pow(1024, i);
          return b;
        }
      }
    },

    isValidFile(file) {
      this.fileValidation = [];
      var size = file.file.size;
      var maxFileSize = this.config.$uploadMaxFileSize || "10MB";
      maxFileSize = this.bytesToSize(maxFileSize);
      this.extension = file.file.name.split(".");
      this.extension = this.extension[this.extension.length - 1].toLowerCase();

      var message = "";

      if (_.indexOf(_.keys(this.oValidExtension), this.extension) < 0) {
        message = "Invalid file extension of [" + this.extension + "].";
        this.fileValidation.push(message);
      }

      if (size > maxFileSize) {
        message =
          "File size of [" +
          this.number2Commas(size) +
          "] is exceed the max file upload size [" +
          this.number2Commas(maxFileSize) +
          "]";
        this.fileValidation.push(message);
      }
      var self = this;
      if (this.fileValidation.length > 0) {
        _.forEach(this.fileValidation, function (v) {
          self.toast("danger", v);
        });

        return false;
      } else {
        self.uploadFile();
        return true;
      }
    },
    uploadFile() {
      this.isLoading = true;
      this.r.upload();
    },
    onCheck(row) {
      this.totalRows = 0;
      let self = this;
      _.forEach(row, function (v) {
        self.totalRows += v.rows * 1;
      });
    },

    getChunk(data) {
      return _.slice(data, 0, this.config.$uploadDisplayPerPage);
    },

    gotoDetailReportPage() {
      this.params = this.getURLParams();
      var item = {
        row: { TopicID: this.selectedTopicID, start: this.start },
      };

      this.gotoDetailReport(item);
    },

    async _gotoUploadPage() {
      var params = this.getURLParams();
      var iquuid = this.readLocalStorage("iquuid2", params.dbName);
      if (iquuid == null) {
        this.$store.commit("setPageBack", params);
        this.gotoVerifyEmail();
      } else {
        this.file_ = null;
        this.summaryData = [];
        this.pendingData = [];
        this.processingRows = 0;

        this.progressBarValue = 0;

        this.results = [];
        this.results_insert = 0;
        this.results_overwrite = 0;
        this.state = 0;
        this.astate = 1;
        setTimeout(() => {
          this.uploadEvents();
        }, 200);
      }
    },
    async getTotalRows() {
      if (this.pendingData.length == 0) {
        return 0;
      } else {
        var sum = 0;
        this.processingRows = 0;

        var numComplete = 0;
        var _processingRows = 0;
        _.forEach(this.pendingData, function (v) {
          var start = v.start;
          var end = v.end;
          sum += end - start + 1;
          if (v.status.toLowerCase() == "complete") {
            numComplete++;
            _processingRows += v.numInsert * 1 + v.numOverwrite * 1;
          }
        });

        this.processingRows = _processingRows;
        setTimeout(() => {
          document
            .querySelector(".has-sticky-header")
            .scrollTo({ top: numComplete * 32, behavior: "smooth" });
        }, 1000);

        return sum;
      }
    },
    async getRecordsInfo() {
      try {
        const params = this.getURLParams();
        var iquuid = this.readLocalStorage("iquuid2", params.dbName);
        this.$store.commit("setPageBack", params);
        if (iquuid == null) {
          this.gotoVerifyEmail();
        } else {
          this.pendingData = [];
          this.summaryData = [];

          params["isCleansing"] = 0;
          params["TopicID"] = this.selectedTopicID;
          params["iquuid"] = iquuid;
          params["fileName"] = this.file_.fileName;
          params["email"] = this.email;

          await this.httpRequest("Dashboard/uploadExternalData", params).then(
            (res) => {
              res = this.$result.data;

              if (parseInt(res.code, 10) == 200) {
                this.processingRows = 0;

                if (res.status.toLowerCase() == "pending") {
                  this.summaryData = res.message;
                  this.pendingData = [];
                } else if (res.status.toLowerCase() == "processing") {
                  this.summaryData = [];
                  this.pendingData = res.message;

                  this.totalRows =
                    this.pendingData[0] !== undefined
                      ? this.pendingData[0].totalRows
                      : 0;
                }

                for (var i in res.message) {
                  if (res.message[i]["status"]) {
                    if (
                      res.message[i].status.trim().toLowerCase() == "complete"
                    ) {
                      this.processingRows +=
                        res.message[i].numInsert * 1 +
                        res.message[i].numOverwrite * 1;
                    }
                  }
                }

                this.checkedRows = [];
              } else {
                this.toast("danger", res.message);
              }
            }
          );
        }
      } catch (error) {
        console.log(error);
      }
    },

    onChangeTopic(v) {
      if (this.oTopics[v]) {
        this.selectedTopicName = this.oTopics[v].QueryName;
      }

      this.selectedTopicID = v;
      this.getRecordsInfo();
    },

    async uploadEvents() {
      if (this.astate == 1) {
        var params = this.getURLParams();
        var iquuid = this.readLocalStorage("iquuid2", params.dbName);

        var self = this;
        var url = "Dashboard/uploadFile";
        this.r = new Resumable({
          target: this.$databasePath + "/" + url,
          query: { upload_token: iquuid },
          testChunks: true,
          maxFiles: 1,
          throttleProgressCallbacks: 1,
          resumableChunkSize: 1 * 1024 * 1024,
        });

        this.r.assignBrowse(document.getElementById("browseButton"));

        this.r.on("fileSuccess", function (file) {
          self.r.removeFile(file);
        });

        this.r.on("fileProgress", function (file) {
          this.percent_load = 100 * file.progress();
          this.percent_load = this.percent_load.toFixed(2) + "%";
        });

        this.r.on("fileAdded", function (file) {
          self.file_ = file;
          self.isValidFile(file);
          self.summaryData = [];
          self.pendingData = [];
          self.totalRows = 0;
          self.checkedRows = [];
          self.state = 0;
        });

        this.r.on("fileError", function (file, message) {
          this.toast("danger", message + " on " + file);
        });

        this.r.on("complete", function () {
          self.isLoading = false;
        });

        this.r.on("error", function (message, file) {
          this.toast("danger", message + " on " + file);
        });
      }
    },
    readExcelFile() {
      var file = this.file_;
      const reader = new FileReader();
      if (file.size < 3000000) {
        reader.onload = (e) => {
          // pre-process data
          var binary = "";
          var bytes = new Uint8Array(e.target.result);
          var length = bytes.byteLength;
          for (var i = 0; i < length; i++) {
            binary += String.fromCharCode(bytes[i]);
          }
          // call 'xlsx' to read the file
          var wb = XLSX.read(binary, {
            type: "binary",
            cellDates: true,
            cellStyles: true,
          });
          this.sheetsName = wb.SheetNames;
          this.tabCount = this.sheetsName.length;

          let self = this;
          i = 0;
          _.forEach(this.sheetsName, (v) => {
            if (i > 0) {
              self.ws[v] = wb.Sheets[v];

              var rows = self.ws[v]["!ref"]
                .replace(/([a-zA-Z])/g, "")
                .split(":");
              rows = rows[1] - rows[0];
              self.totalRows += rows;
              self.summaryData.push({
                sheetName: v,
                rows: self.number2Commas(rows),
              });
              self.checkedRows = self.summaryData;
            }
            i++;
          });
        };

        reader.readAsArrayBuffer(file);
      }
    },
  },
};
</script>
<style scoped>
.card-header-title {
  font-size: 1.5em !important;
}
</style>
