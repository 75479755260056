import PageCustomer from "./pages/Customers";
import PageSummary from "./pages/Summary";
import PageReport from "./pages/Report";
import PageCleansingIndex from "./pages/CleansingIndex";
import PageCleansingV2 from "./pages/CleansingV2";
import PageVerifyEmail from "./pages/VerifyEmail";
import PageUploadData from "./pages/UploadData";
import PageInputData from "./pages/InputData";
import PageNotFound from "./pages/NotFound";
import PagePreparing from "./pages/Preparing";
import PagePreparingSuccess from "./pages/PreparingSuccess.vue";
import PagePreparingUnSuccess from "./pages/PreparingUnSuccess.vue";
import PageGoAuthPostBack from "./pages/GoAuthPostBack";
import PageUnAuthorize from './pages/NotAuthorize.vue';

import config from "./config.js";

let route = [];

var routePageCustomer = {
  path: "/",
  component: PageCustomer
};
var routePageCustomerV1 = {
  path: "/main/choosecustomer",
  component: PageCustomer
};

var routePageDefault = {
  path: "*",
  component: PageNotFound
};

var routePageNotFound = {
  path: "/notfound/:dbname",
  component: PageNotFound
};

var routePageSummary = {
  path: "/summary/:dbname/:profileid/:periodName/:periodID?/:csv?",
  component: PageSummary
};


var routePageReport = {
  path: "/report/:dbname/:profileid/:periodName/:periodID?/:csv?",
  component: PageReport
};

var routePageCleansingIndex = {
  path: "/cleansingindex/:dbname/:profileid/:periodName/:periodID",
  component: PageCleansingIndex,
};


var routePageCleansingV2 = {
  name: "routePageCleansingV2",
  path: "/cleansingv2/:dbname/:profileid/:periodName/:periodID",
  component: PageCleansingV2,
};

var routePageVerifyEmail = {
  path: "/verifyemail/:dbname/:profileid/:periodName/:periodID?",
  component: PageVerifyEmail
};

var routePageUploadData = {
  path: "/uploaddata/:dbname/:profileid/:periodName/:periodID",
  component: PageUploadData
};

var routePageInputData = {
  path: "/inputdata/:dbname/:profileid/:periodName/:periodID",
  component: PageInputData
};

var routePagePreparing = {
  path: "/preparing/:dbname/:profileid/:periodName/:periodID?",
  component: PagePreparing
};

var routePagePostBack = {
  path: "/postback",
  component: PageGoAuthPostBack
};

var routePagePreparingSuccess = {
  path: "/verifyemailsuccess/:dbname/:profileid/:periodName/:periodID?",
  component: PagePreparingSuccess
};

var routePagePreparingUnSuccess = {
  path: "/verifyemailunsuccess",
  component: PagePreparingUnSuccess
};

var routePageUnAuthorize = {
  path: '/unauthorize',
  component: PageUnAuthorize,
}

if (config.config.$is_production == false) {
  route = [
    routePageCustomer,
    routePageCustomerV1,
    routePageDefault,
    routePageNotFound,
    routePageSummary,
    routePageReport,
    routePageCleansingIndex,
    routePageCleansingV2,
    routePageVerifyEmail,
    routePageUploadData,
    routePageInputData,
    routePagePreparing,
    routePagePostBack,
    routePagePreparingSuccess,
    routePagePreparingUnSuccess,
    routePageUnAuthorize,
  ];
} else {
  switch (config.config.$is_demo) {
    case 0:
      route = [
        routePageCleansingIndex,
        routePageDefault,
        routePageNotFound,
        routePageSummary,
        routePageReport,
        routePageCleansingV2,
        routePageVerifyEmail,
        routePageUploadData,
        routePageInputData,
        routePagePreparing,
        routePagePostBack,
        routePagePreparingSuccess,
        routePagePreparingUnSuccess,
        routePageUnAuthorize,
      ];
      break;
    case 1:
      //dev, demo
      route = [
        routePageCustomer,
        routePageCustomerV1,
        routePageDefault,
        routePageNotFound,
        routePageSummary,
        routePageReport,
        routePageCleansingIndex,
        routePageCleansingV2,
        routePageVerifyEmail,
        routePageUploadData,
        routePageInputData,
        routePagePreparing,
        routePagePostBack,
        routePagePreparingSuccess,
        routePagePreparingUnSuccess,
        routePageUnAuthorize,
      ];
      break;
    case 2:
      route = [
        routePageCustomer,
        routePageCustomerV1,
        routePageDefault,
        routePageNotFound,
        routePagePreparing,
        routePageVerifyEmail,
        routePagePostBack
      ];
      break;
  }
}

export default route;