<template>
  <div>
    <b-navbar class="is-fixed-top">
      <template slot="brand">
        <b-navbar-item>
          <a class="navbar-item no-pointer">
            <img src="../assets/img/io_logo.png" :alt="$websiteDescription" />
          </a>
        </b-navbar-item>
      </template>
      <template slot="end"> </template>
    </b-navbar>
    <div style="margin-top: 50px">
      <Preparing :waitingText="this.waitingText" v-if="state == 1"></Preparing>
      <NotAuthorize v-if="state == 2"></NotAuthorize>
    </div>
    <Footer
      :eulaurl="eulaurl"
      :contactusurl="contactusurl"
      :companyurl="companyurl"
    ></Footer>
  </div>
</template>
<script>
import Footer from "../layout/Footer.vue";
import Preparing from "../contents/preparing";
import NotAuthorize from "../pages/NotAuthorize";
import moment from "moment";
export default {
  name: "goAuthPostBackPage",
  components: {
    Footer,
    Preparing,
    NotAuthorize,
  },
  data() {
    return {
      timeout: Number,
      waitingText: "",
      timer: "",
      email: "",
      state: 0,
      eulaurl: "",
      contactusurl: "",
      companyurl: "",
      oTopics: {},
      selectedTopicID: "",
    };
  },

  async created() {
    this.state = 1;
    await this.getServerConfig().then((config) => {
      this.eulaurl = config.EULAURL;
      this.companyurl = config.CompanyURL;
      this.contactusurl = config.ContactUsURL;
    });
  },

  mounted() {
    this.waitingText = "Preparing data, please wait...";
    this.redirectPage();
    this.gaPage();
  },

  methods: {
    async redirectPage() {
      var token = this.$route.query.token;

      if (token !== undefined && token.length > 0) {
        var result = await this.validateToken(token);

        if (result["continue"]) {
          var expire = moment(
            moment().format("YYYY-MM-DD") + " 23:59:59"
          ).toDate();

          this.writeLocalStorage("iqgoauth2", token, expire);
          if (
            result.params.pageBack.length == 0 ||
            result.params.pageBack == undefined
          ) {
            this.goto("/");
          } else {
            result.params.email = result.email;
            if (
              result.params.iquuid.length > 0 &&
              result.params.dbName.length > 0
            ) {
              this.httpRequest(
                "Dashboard/AutoActivateEmail",
                result.params
              ).then(() => {
                if (this.$result.data.result == 200) {
                  this.state = 1;
                  this.writeLog("goauth", [
                    {
                      key: "originalURL",
                      value: location.href,
                    },
                    { key: "dbName", value: result.params.dbName },
                    { key: "email", value: this.email },
                    {
                      key: "remark",
                      value: "Valid Google OAuth [" + this.email + "]",
                    },
                  ]);

                  var TopicID = "";
                  var pageBack = result.params.pageBack.toLowerCase();

                  switch (pageBack) {
                    case "summary":
                      if (result.params.TopicID.length >= 6)
                        TopicID = result.params.TopicID.substr(0, 6);
                      break;
                    default:
                      if (result.params.TopicID.length > 6) {
                        TopicID = result.params.TopicID.substr(0, 10);
                      } else {
                        pageBack = "summary";
                        TopicID = result.params.TopicID.substr(0, 6);
                      }
                      break;
                  }
                  var periodName =
                    result.params.periodName !== undefined
                      ? result.params.periodName
                      : "monthly";
                  var periodID =
                    result.params.periodID !== undefined
                      ? result.params.periodID
                      : "latest";

                  if (
                    result.params.pageBack.toLowerCase() == "cleansingindex"
                  ) {
                    if (periodName.toLowerCase() == "daily") {
                      periodName = "monthly";
                      periodID = "latest";
                    }
                  }

                  var p = [
                    "/",
                    pageBack,
                    result.params.dbName,
                    TopicID,
                    periodName,
                    periodID,
                  ];
                  this.writeLog("goauth", [
                    {
                      key: "originalURL",
                      value: location.href,
                    },
                    {
                      key: "remark",
                      value:
                        result.params.pageBack +
                        "/" +
                        result.params.TopicID +
                        "=>" +
                        JSON.stringify(p),
                    },

                    { key: "dbName", value: result.params.dbName },
                    { key: "email", value: this.email },
                  ]);

                  this.goto(p.join("/"), true);
                } else {
                  this.state = 2;
                  this.waitingText = "Authentication fail";
                  this.toast("danger", this.waitingText);
                }
              });
            } else {
              this.state = 2;
              this.waitingText = "Authentication fail";
              this.toast("danger", this.waitingText);
            }
          }
        } else {
          this.state = 2;
          this.waitingText = "Authentication fail";
          this.toast("danger", this.waitingText);
        }
      } else {
        this.state = 2;
        this.waitingText = "Authentication fail";
        this.toast("danger", this.waitingText);
      }
    },
  },
};
</script>

<style>
.margin-minus {
  margin-left: -15px;
  margin-right: -15px;
}
#area-choose-customer {
  margin-top: 70px;
}
table[id="table-choose-customer"] tr td:hover,
table[id="table-summary"] tr td:hover {
  cursor: pointer !important;
}
.align-center {
  text-align: center;
}

.font-big {
  font-size: 3em;
}
.color-blue {
  color: blue;
}
</style>
<style>
.no-padding {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
</style>
