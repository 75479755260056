<template>
  <div>
    <div class="card" style="margin-top: 10px">
      <div slot="trigger" class="card-header" role="button">
        <p class="card-header-title"></p>
      </div>
      <div class="card-content">
        <div class="content" style="text-align: center">
          <div class="is-size-1">Sorry, data not found</div>
          <div class="mt-5 is-size-3" v-if="startEnd !== undefined">
            in {{ startEnd }}
          </div>
          <div class="mt-5">
            Please select another range or contact Dataxet Limited. at Tel.
            +66 (0) 2651 4700 or +66 (0) 2253 5000 ext. 444.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "notFoundContent",
  components: {},
  props: {
    startEnd: {
      type: String,
      require: true,
    },
  },
  data() {
    return {
      isHide: false,
    };
  },
  created() {
    var params = this.getURLParams();
    if (params.page == "cleansingindex") {
      this.isHide = true;
    }
  },
  mounted() {},
  methods: {
    async gotoCleansingIndexPage() {
      this.params = this.getURLParams();
      this.params.periodName = "monthly";
      this.params.periodID = "latest";
      var p = [];

      p = [
        "cleansingindex",
        this.params.dbName,
        this.params.TopicID,
        this.params.periodName,
        this.params.periodID,
      ];

      p = "/" + p.join("/");

      this.goto(p);
    },
  },
};
</script>

<style scoped>
.card-header-title {
  font-size: 1.5em !important;
}

.card-content {
  min-height: 70vh;
}
</style>