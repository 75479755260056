<template>
  <div>
    <b-navbar class="is-fixed-top">
      <template slot="brand">
        <b-navbar-item>
          <a class="navbar-item no-pointer">
            <img src="../assets/img/io_logo.png" :alt="$websiteDescription" />
          </a>
        </b-navbar-item>
      </template>
      <template slot="end"> </template>
    </b-navbar>
    <div class="_wrapper">
      <div class="card">
        <div slot="trigger" class="card-header" role="button">
          <button
            v-if="isHide == false"
            class="button is-primary"
            type="button"
            slot="trigger"
            @click.prevent="gotoCleansingIndexPage()"
            custom-class="reviewData"
          >
            <template>
              <span>Review Data</span>
            </template>
          </button>
        </div>
        <div class="card-content">
          <div class="content" style="text-align: center">
            <div class="is-size-0">404</div>
            <div class="is-size-1">Page not found</div>
            <div class="mt-5">
              The page you are looking for doesnot exists or an other error
              occured.
            </div>
            <div>
              Please go back or please contact Dataxet Limited. at Tel. +66
              (0) 2651 4700 or +66 (0) 2253 5000 ext. 444.
            </div>
            <div class="mt-5">
              <b-button
                v-if="
                  pageBack !== null &&
                  params.page !== undefined &&
                  params.page.toLowerCase() == 'notfound'
                "
                class="button is-primary"
                @click.prevent="_goBack()"
                >Back</b-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer
      v-if="isLoading == false"
      :eulaurl="eulaurl"
      :contactusurl="contactusurl"
      :companyurl="companyurl"
    ></Footer>
  </div>
</template>

<script>
import Footer from "../layout/Footer.vue";
export default {
  name: "notFoundPage",
  components: {
    Footer,
  },
  data() {
    return {
      isLogin: false,
      isHide: true,
      email: "",
      eulaurl: "",
      contactusurl: "",
      companyurl: "",
      isLoading: true,
      params: {},
      pageBack: {},
    };
  },

  props: {},
  mounted() {
    this.gaPage();
  },
  async created() {
    this.isLogin = false;
    await this.getServerConfig().then((config) => {
      this.eulaurl = config.EULAURL;
      this.companyurl = config.CompanyURL;
      this.contactusurl = config.ContactUsURL;
    });
    this.params = this.getURLParams();

    var iquuid = this.readLocalStorage("iquuid2", this.params.dbName);

    if (iquuid == null) {
      this.pageBack = null;
    } else {
      if (this.params.TopicID == null) {
        this.pageBack = null;
      }
    }
    this.isLoading = false;
  },

  methods: {
    // _checkPageBack(a) {
    //   if (a !== null && a !== undefined) {
    //     if (a.page == null || a.page == undefined) return null;
    //     if (a.dbName == null || a.dbName == undefined) return null;
    //     if (a.reportID == null || a.reportID == null) return null;
    //     if (a.periodName == null || a.periodName == null) return null;
    //     if (a.periodID == null || a.periodID == null) return null;
    //     return a;
    //   }
    //   return null;
    // },
    _goBack() {
      this.params = this.getURLParams();
      var p = this.params;
      p = this.$store.state.pageBack[p.dbName];

      if (p !== undefined && p !== null) {
        var a = [
          "/summary",
          p.dbName,
          p.reportID.toLowerCase(),
          p.periodName,
          p.periodID,
        ];

        this.goto(a.join("/"));
      }
    },

    async gotoCleansingIndexPage() {
      this.requestUUID().then(() => {
        var res = this.$result.data;
        if (res.IsActivate) {
          this.gotoCleansingIndex();
        } else {
          this.toast("danger", "Redirect to authentication page");
          setTimeout(() => {
            this.gotoVerifyEmail();
          }, 2000);
        }
      });
    },
  },
};
</script>

