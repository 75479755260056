<template>
  <div>
    <b-navbar class="is-fixed-top" v-if="astate == 1">
      <template slot="brand">
        <b-navbar-item v-if="astate == 1" tag="router-link" :to="{ path: '' }">
          <a class="navbar-item" @click.prevent="_gotoSummaryPage()">
            <img src="../assets/img/io_logo.png" :alt="$websiteDescription" />
          </a>
        </b-navbar-item>
        <b-navbar-item v-if="astate == 2">
          <a class="navbar-item no-cursor">
            <img src="../assets/img/io_logo.png" :alt="$websiteDescription" />
          </a>
        </b-navbar-item>
      </template>
      <!-- <template slot="end">
        <div v-if="email !== undefined && email.length > 0" @click="_logout()">
          <template><b-icon icon="logout"></b-icon><span>Logout</span></template>
        </div>
        <div v-else @click="_login()"> Login </div>
      </template> -->

      <!-- <template slot="end">
        <b-navbar-dropdown :label="email" v-if="astate == 1">
          <b-navbar-item
            v-if="email !== undefined && email.length > 0"
            @click="_logout()"
            ><template
              ><b-icon icon="logout"></b-icon><span>Logout</span></template
            ></b-navbar-item
          >
          <b-navbar-item v-else @click="_login()"> Login </b-navbar-item>
        </b-navbar-dropdown>
      </template> -->

      <template slot="end">
          <div class="top-right-container">
            <div v-if="email !== undefined && email.length > 0" class="top-item">
              <div class="top-item">
                  <b-icon icon="person"></b-icon>
              </div>
              <div  class="top-item">{{email}}</div>
              <div class="bell-notification">
                <span class="bell-notification-child">
                  <Notification></Notification>
                  </span>
              </div>
              <div class="top-item icon-hover" @click="_logout()">
                <b-icon icon="logout" ></b-icon>
              </div>
            </div>
            <div v-else @click="_login()"> Login </div>
          </div>
      </template> 
      
    </b-navbar>

    <div class="_wrapper" v-if="astate == 1">
      <div
        slot="trigger"
        class="card-header"
        role="button"
        aria-controls="__content"
      >
        <p class="card-header-title has-text-left m-l-15">
          <span>{{ this.selectedTopicName }}</span>
        </p>
        <div class="buttons is-pulled-right">
          <div class="px-1 text-big">{{ getStartEnd(start, end) }}</div>
          <button class="button is-primary" @click="_backWarning">
            <span>Back</span>
          </button>
        </div>
      </div>
      <div v-if="isLoading == false">
        <div class="card">
          <div class="card-content">
            <div class="content">
              <div class="columns is-multiline">
                <div class="column is-3-desktop">
                  <div class="columns">
                    <div class="column">
                      <p class="text-big has-text-left bg-white">
                        Filter Options
                      </p>
                      <b-field class="has-text-left has-text-weight-bold"
                        >Filter</b-field
                      >
                      <b-field>
                        <b-input
                          expanded
                          placeholder="Search..."
                          type="search"
                          v-model="filter.search"
                        ></b-input>
                        <p class="control">
                          <b-button class="button is-primary">
                            <b-icon icon="magnify"></b-icon>
                          </b-button>
                        </p>
                      </b-field>
                    </div>
                  </div>
                  <div class="columns">
                    <div class="column">
                      <b-field class="has-text-left has-text-weight-bold"
                        >Sources</b-field
                      >
                      <div style="height: 500px">
                        <treeselect
                          v-model="filter.source"
                          value-consists-of="ALL_WITH_INDETERMINATE"
                          :multiple="true"
                          :options="DataSource"
                          :flatten-search-results="true"
                          :show-count="false"
                          :append-to-body="true"
                          :always-open="true"
                          :max-height="470"
                          :default-expand-level="1"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="columns">
                    <div class="column">
                      <b-field class="has-text-left has-text-weight-bold"
                        >Sort Order</b-field
                      >
                      <!-- <div class="block"> -->
                      <!-- <b-field> -->
                      <!-- <b-select
                          placeholder="After update status, scroll to"
                          expanded
                          v-model="scrollDirection"
                        >
                          <option value="top">Top</option>
                          <option value="bottom">Bottom</option>
                        </b-select> -->
                      <!-- </b-field> -->
                      <!-- </div> -->
                      <div class="buttons">
                        <b-radio
                          v-model="sortOrder"
                          name="sortOrder"
                          native-value="asc"
                        >
                          ASC
                        </b-radio>
                        <b-radio
                          v-model="sortOrder"
                          name="sortOrder"
                          native-value="desc"
                        >
                          DESC
                        </b-radio>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="column cleansingv2">
                  <div v-if="data">
                    <b-tabs
                      type="is-boxed"
                      expanded
                      v-model="activeTab"
                      @input="_onTabChange"
                    >
                      <template v-for="(tab, index) in status">
                        <b-tab-item :key="tab.name">
                          <template slot="header">
                            <b-icon :icon="tab.icon"></b-icon>
                            <span>
                              {{ tab.label }}
                              <b-tag :class="_getTabColor(tab.label)" rounded>{{
                                data[tab.name] !== undefined
                                  ? number2Commas(
                                      data[tab.name]["items"].length
                                    )
                                  : 0
                              }}</b-tag>
                            </span>
                          </template>

                          <div
                            v-if="
                              data[tab.name] !== undefined &&
                              data[tab.name]['items'].length > 0
                            "
                            class="legend-icon"
                            style="text-align: right; margin-bottom: 30px"
                          >
                            <div class="legend-item">
                              <div class="iconBlackCircle">V</div>
                              PR Value (Baht)
                            </div>
                            <div class="legend-item">
                              <div class="iconBlackCircle">R</div>
                              Potential Reach
                            </div>
                          </div>
                          <div style="border-bottom: none">
                            <b-table
                              id="table-cleansing-v2"
                              :data="
                                data[tab.name] !== undefined
                                  ? _filterItems(data[tab.name]['items'])
                                  : []
                              "
                              :total="
                                data[tab.name] !== undefined
                                  ? _filterItems(data[tab.name]['items']).length
                                  : 0
                              "
                              :paginated="paginated[tab.name]"
                              :per-page="perPage"
                              :current-page.sync="currentPage[index]"
                              @page-change="_onPageChange"
                              pagination-position="both"
                              aria-next-label="Next page"
                              aria-previous-label="Previous page"
                              aria-page-label="Page"
                              aria-current-label="Current page"
                              aria-controls="__content"
                            >
                              <template>
                                <b-table-column field="HeadLine" v-slot="props">
                                  <div :class="_getTagColor(props.row)">
                                    <!-- line 0 -->
                                    <div class="columns mb-1">
                                      <div class="column has-text-left">
                                        <div>
                                          {{
                                            [
                                              (currentPage[index] - 1) *
                                                perPage +
                                                props.index +
                                                1,
                                            ]
                                          }}
                                        </div>
                                        <div>
                                          <span
                                            class="datasource-icon"
                                            :style="{
                                              color: _getColor(
                                                props.row.DataSource,
                                                props.row.OriginalURL,
                                                props.row.SubType
                                              ),
                                            }"
                                          >
                                            <i
                                              :class="
                                                _getIcon(
                                                  props.row.DataSource,
                                                  props.row.OriginalURL,
                                                  props.row.SubType
                                                )
                                              "
                                            ></i>
                                          </span>

                                          <span
                                            class="source-name"
                                            :title="props.row.Location"
                                            >{{ props.row.Location }}</span
                                          >
                                        </div>
                                        <div class="display-time">
                                          {{ props.row.DisplayTime }}
                                        </div>
                                      </div>

                                      <div
                                        class="is-3-tablet is-6-mobile column"
                                      >
                                        <div class="pr-value-right text-nowrap">
                                          <div class="columns">
                                            <div class="column is-5">
                                              <div class="iconBlackCircle">
                                                V
                                              </div>
                                            </div>
                                            <div class="column">
                                              {{ props.row.PRValue }}
                                            </div>
                                          </div>
                                          <div class="columns">
                                            <div
                                              class="column is-5"
                                              style="margin-top: -16px"
                                            >
                                              <div class="iconBlackCircle">
                                                R
                                              </div>
                                            </div>
                                            <div
                                              class="column"
                                              style="margin-top: -8px"
                                              v-if="
                                                props.row.PotentialReach
                                                  .length == 0
                                              "
                                            >
                                              N/A
                                            </div>
                                            <div
                                              class="column"
                                              style="margin-top: -16px"
                                              v-else
                                            >
                                              {{ props.row.PotentialReach }}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="text-inline has-text-left">
                                      <div class="headline-1">
                                        <a
                                          @click="
                                            openLink(
                                              props.row.RowID,
                                              props.row.OriginalURL
                                            )
                                          "
                                          rel="noreferrer noopener nofollow"
                                          v-html="props.row.HeadLine"></a
                                        >

                                        <span
                                          v-if="
                                            props.row.NewsType.toLowerCase() ==
                                              'printmedia' ||
                                            props.row.NewsType.toLowerCase() ==
                                              'onlinemedia' ||
                                            props.row.NewsType.toLowerCase() ==
                                              'webclip'
                                          "
                                        >
                                          <a
                                            @click="
                                              openLink(
                                                props.row.RowID,
                                                props.row.OriginalURLIconPDF
                                              )
                                            "
                                            rel="noreferrer noopener nofollow"
                                          >
                                            <i
                                              class="fa fa-file-pdf icon-danger"
                                            ></i>
                                          </a>
                                        </span>
                                        <span v-else>
                                          <span
                                            v-if="
                                              props.row.NewsIdentifier !== null
                                            "
                                          >
                                            <a
                                              @click="
                                                openLink(
                                                  props.row.RowID,
                                                  props.row.OriginalURLIconPDF
                                                )
                                              "
                                              rel="noreferrer noopener nofollow"
                                            >
                                              <i
                                                class="
                                                  fa fa-file-pdf
                                                  icon-danger
                                                "
                                              ></i>
                                            </a>
                                          </span>
                                          <!-- <a
                                        @click="openLink(props.row.RowID, props.row.OriginalURL)"
                                        rel="noreferrer noopener nofollow"
                                      >
                                        <i class="fa fa-external-link-alt btn-link"></i>
                                      </a>-->
                                        </span>
                                      </div>
                                    </div>
                                    <div class="hit-sentence mb-4">
                                      <div
                                        v-if="props.row.HitSentence.length > 0"
                                      >
                                        <a
                                          @click="
                                            openLink(
                                              props.row.RowID,
                                              props.row.OriginalURL
                                            )
                                          "
                                          v-html="props.row.HitSentence"
                                        ></a>
                                      </div>
                                      <div
                                        v-else-if="props.row.Story.length > 0"
                                      >
                                        <a
                                          @click="
                                            openLink(
                                              props.row.RowID,
                                              props.row.OriginalURL
                                            )
                                          "
                                          v-html="props.row.Story"
                                        ></a>
                                      </div>
                                      <div v-else>[-]</div>
                                    </div>

                                    <!-- render controls -->
                                    <div
                                      class="columns is-multiline my-controls"
                                    >
                                      <div
                                        :class="_getColumnClass(item)"
                                        v-for="item in labelFields"
                                        :key="item.name"
                                      >
                                        <div
                                          v-if="
                                            data[tab.name] !== undefined &&
                                            data[tab.name].items.length > 0 &&
                                            data[tab.name].items[
                                              data[tab.name].items.indexOf(
                                                props.row
                                              )
                                            ].Status !== 4
                                          "
                                        >
                                          <div v-if="item.controlType == 'sb'">
                                            <b-field>
                                              <p class="control">
                                                <b-button
                                                  :size="_getSize()"
                                                  class="
                                                    button
                                                    is-info
                                                    no-cursor
                                                  "
                                                  >{{ item.label }}</b-button
                                                >
                                              </p>

                                              <b-select
                                                placeholder="select at least one item"
                                                expanded
                                                type="is-dark"
                                                :size="_getSize()"
                                                @input="
                                                  _validateFinish(
                                                    tab.name,
                                                    props.row
                                                  )
                                                "
                                                v-model="
                                                  data[tab.name].items[
                                                    data[
                                                      tab.name
                                                    ].items.indexOf(props.row)
                                                  ]['zzzz_' + item.name]
                                                "
                                              >
                                                <option
                                                  v-for="option in item.options"
                                                  :value="option.label"
                                                  :key="option.value"
                                                >
                                                  {{ option.label }}
                                                </option>
                                              </b-select>
                                            </b-field>
                                          </div>

                                          <!-- <div
                                            v-else-if="item.controlType == 'cb'"
                                          >
                                            <b-field>
                                              <p class="control">
                                                <button class="button is-dark">
                                                  {{ item.label }}
                                                </button>
                                              </p>

                                              <div
                                                v-for="option in item.options"
                                                :key="option.value"
                                              >
                                                <b-checkbox
                                                  :name="item.name"
                                                  type="is-dark"
                                                  :native-value="option.value"
                                                  >{{
                                                    option.label
                                                  }}</b-checkbox
                                                >
                                              </div>
                                            </b-field>
                                          </div>

                                          <div
                                            v-else-if="item.controlType == 'rb'"
                                          >
                                            <b-field>
                                              <p class="control">
                                                <button class="button is-dark">
                                                  {{ item.label }}
                                                </button>
                                              </p>

                                              <div
                                                class="block"
                                                v-for="option in item.options"
                                                :key="option.value"
                                              >
                                                <b-radio
                                                  :name="item.name"
                                                  type="is-dark"
                                                  :native-value="option.value"
                                                  >{{ option.label }}</b-radio
                                                >
                                              </div>
                                            </b-field>
                                          </div> -->

                                          <div
                                            v-else-if="item.controlType == 'tb'"
                                          >
                                            <b-field>
                                              <p class="control">
                                                <b-button
                                                  type="is-info"
                                                  class="no-cursor"
                                                  :size="_getSize()"
                                                  >{{ item.label }}</b-button
                                                >
                                              </p>
                                              <b-input
                                                expanded
                                                custom-class="label-tb-bgcolor"
                                                :maxlength="_getMaxLength(item)"
                                                :has-counter="false"
                                                :size="_getSize()"
                                                @input="
                                                  _validateFinish(
                                                    tab.name,
                                                    props.row
                                                  )
                                                "
                                                v-model="
                                                  data[tab.name].items[
                                                    data[
                                                      tab.name
                                                    ].items.indexOf(props.row)
                                                  ]['zzzz_' + item.name]
                                                "
                                                placeholder="Enter text here..."
                                              ></b-input>
                                            </b-field>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <!-- render buttons -->
                                    <div class="columns is-multiline">
                                      <div
                                        class="column is-12"
                                        style="margin-top: -12px"
                                      >
                                        <b-field
                                          grouped
                                          style="
                                            position: relative;
                                            float: right;
                                          "
                                        >
                                          <p class="control">
                                            <b-button
                                              v-if="props.row.Status !== '0'"
                                              outlined
                                              type="is-danger"
                                              :size="_getSize()"
                                              @click.prevent="
                                                _toggleFinish(
                                                  tab.name,
                                                  props.row,
                                                  0
                                                )
                                              "
                                            >
                                              <span>Remove</span>
                                            </b-button>
                                          </p>

                                          <b-button
                                            v-if="props.row.Status == '0'"
                                            type="is-info"
                                            :size="_getSize()"
                                            @click.prevent="
                                              _toggleFinish(
                                                tab.name,
                                                props.row,
                                                1
                                              )
                                            "
                                          >
                                            <span>Restore</span>
                                          </b-button>

                                          <p class="control">
                                            <b-button
                                              v-if="props.row.Status !== '0'"
                                              :size="_getSize()"
                                              type="is-success"
                                              @click.prevent="
                                                _toggleFinish(
                                                  tab.name,
                                                  props.row,
                                                  2
                                                )
                                              "
                                              :disabled="
                                                labelFields !== undefined &&
                                                data[tab.name].items[
                                                  data[tab.name].items.indexOf(
                                                    props.row
                                                  )
                                                ]['state'] !== 2
                                              "
                                            >
                                              <span>Done</span>
                                            </b-button>
                                          </p>
                                        </b-field>
                                      </div>
                                    </div>
                                  </div>
                                </b-table-column>
                              </template>
                              <template slot="empty" v-if="isLoading == false">
                                <section class="section">
                                  <div class="content has-text-grey">
                                    <p class="has-text-centered">
                                      <b-icon
                                        icon="emoticon-sad"
                                        size="is-large"
                                      ></b-icon>
                                    </p>
                                    <p class="has-text-centered">
                                      No record found here.
                                    </p>
                                  </div>
                                </section>
                              </template>
                            </b-table>
                          </div>
                          <div align="right" class="m-t-10">
                            <b-button
                              class="anchor"
                              type="is-text"
                              @click="toTop()"
                              icon-left="arrow-up-bold-circle-outline"
                              >Go to Top</b-button
                            >
                          </div>
                        </b-tab-item>
                      </template>
                    </b-tabs>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <b-loading
          :is-full-page="isFullPage"
          :active.sync="isLoading"
          :can-cancel="true"
        ></b-loading>
        <Preparing :waitingText="this.waitingText"></Preparing>
      </div>
    </div>
    <div class="_wrapper" v-else-if="astate == 2">
      <NotAuthorizeEditor></NotAuthorizeEditor>
    </div>
    <Footer
      v-if="isLoading == false"
      :eulaurl="eulaurl"
      :contactusurl="contactusurl"
      :companyurl="companyurl"
    ></Footer>
  </div>
</template>

<script>
import Footer from "../layout/Footer.vue";
import Preparing from "../contents/preparing";
import _ from "lodash";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import NotAuthorizeEditor from "../pages/NotAuthorizeEditor";
import Notification from "../controls/Notification";
// import moment from "moment";

export default {
  name: "cleansingV2Page",
  components: {
    Footer,
    Treeselect,
    NotAuthorizeEditor,
    Preparing,
    Notification,
  },
  state: {
    alteredState: "false",
  },

  data() {
    return {
      DisplayTime: null,
      waitingText: "Preparing data, please wait...",
      eulaurl: "",
      contactusURL: "",
      companyURL: "",

      astate: 0,
      labelFields: [],
      labelValue: {},
      data: [],
      searchData: "",
      currentPage: [1, 1, 1, 1],
      perPage: 50,
      paginated: {
        all: false,
        incomplete: false,
        finish: false,
        delete: false,
      },
      sortOrder: "desc",
      activeTab: 0,
      status: [
        {
          name: "all",
          value: -1,
          label: "All",
          group: "All",
          visible: true,
          data: [],
          status: [0, 1, 2],
          icon: "select-all",
        },
        {
          name: "incomplete",
          value: 1,
          label: "Incomplete",
          group: "Incomplete",
          visible: true,
          data: [],
          status: [1],
          icon: "plus-circle",
        },
        {
          name: "finish",
          value: 2,
          label: "Completed",
          group: "Finished",
          visible: true,
          data: [],
          status: [2],
          icon: "check-circle",
        },
        {
          name: "delete",
          value: 0,
          label: "Removed",
          group: "Deleted",
          visible: true,
          data: [],
          status: [0],
          icon: "delete-circle",
        },
      ],
      filter: { search: "", source: [] },
      DataSource: [],
      params: {},
      page: 1,
      email: "",
      isLogin: false,
      isFullPage: true,
      isLoading: true,
      hexkey: "",
      hexiv: "",
      selectedTopicName: "",
      oPeriod: [],
      oTopics: [],
      oProfileID: "",
      oProfile: {},
    };
  },

  async created() {
    this.isLogin = false;

    this.params = this.getURLParams();
    this.DisplayTime = this.params.periodID;

    var iquuid = this.readLocalStorage("iquuid2", this.params.dbName);
    var BrowserID = this.getBrowserID();

    this.$store.commit("setPageBack", this.params);

    if (iquuid == null) {
      this.gotoVerifyEmail();
    } else {
      if (await this.isValidDatabase()) {
        await this._getInitialData();
        if (await this._isValidURL(this.params)) {
          this.isLoading = true;
          this.params.pageBack = "cleansingv2";
          this.params.page = this.params.pageBack;

          var res = await this.checkPermission(
            iquuid,
            BrowserID,
            this.params.page
          );

          var _continue = res.data.continue;
          var _goto = res.data.goto;
          var _clearUUID = res.data.clearUUID;

          if (_continue) {
            if (_goto == 401) {
              this.astate = 2;

              this.toast(
                "danger",
                "Restricted section. Only valid email can visit this page."
              );
            } else {
              this.astate = 1;

              this.email = await this.getUserEmail();

              let self = this;

              await this.getServerConfig().then((res) => {
                this.labelFields = res["$labelFields_" + this.oProfileID];
                this.hexkey = res["$HexKey"];
                this.hexiv = res["$HexIV"];
                this.eulaurl = res["EULAURL"];
                this.contactusurl = res["ContactUsURL"];
                this.companyurl = res["CompanyURL"];
                _.forEach(res.$DataSource, function (v) {
                  var e = { id: v.key, label: v.name };

                  if (v.members !== undefined) {
                    e.children = [];
                    _.forEach(v.members, function (vv) {
                      var ee = { id: vv, label: vv };
                      e.children.push(ee);
                    });
                  }
                  self.DataSource.push(e);
                  scrollTo(0, 100);
                });
              });

              await this._loadAsyncData();
              await this.gaPage();

              this.isLoading = false;
            }
          } else {
            if (_goto == 401 && _clearUUID == true) {
              this.deleteLocalStorage("iquuid2", this.params.dbName);
            }

            if (_goto == "verifyemail") {
              this.gotoVerifyEmail();
            } else {
              this.astate = 2;
              this.isLoading = false;
              this.toast(
                "danger",
                "Restricted section. Only valid email can visit this page."
              );
            }
          }
          this.isLoading = false;
        } else {
          this.gotoNotFound(this.params.dbName);
        }
      } else {
        this.redirectHost(this.params);
      }
    }
  },

  beforeDestroy() {
    this.params = this.getURLParams();
    this.params.sumIncomplete = this.$sumIncomplete.value;
    this.params.iquuid = this.readLocalStorage("iquuid2", this.params.dbName);
    this.params.DisplayDate = this.DisplayTime;

    var se = this.getStartEndInterval(
      this.params.periodName.toLowerCase(),
      this.params.periodID
    );
    this.params.start = se.start;
    this.params.end = se.end;
    this.httpRequest("Dashboard/updateIncomplete", this.params).then(() => {});
  },

  watch: {
    data() {
      for (var i in this.status) {
        this.paginated[this.status[i].name] = true;
      }
    },
    async sortOrder() {
      await this._loadAsyncData();
    },
  },

  methods: {
    _getSize() {
      if (screen.width < 1400) {
        return "is-small";
      } else {
        return "";
      }
    },
    getResolution() {
      alert("Your screen resolution is: " + screen.width + "x" + screen.height);
    },
    _getColumnClass(col) {
      if (col.controlType.toString().toLowerCase() == "tb") {
        return "column is-12 mb-0 mt-0 pb-0 pt-0";
      } else {
        return "column is-4 mb-0 mt-0 pb-0 pt-0";
      }
    },
    async _isValidURL(params) {
      var a =
        this.oTopics !== undefined && Object.keys(this.oTopics).length > 0
          ? true
          : false;

      var b = false;

      var se = this.getStartEndInterval(
        params.periodName.toLowerCase(),
        params.periodID
      );
      switch (params.periodName.toLowerCase()) {
        case "daily":
          if (
            se.start.toLowerCase() !== "invalid date" &&
            se.end.toLowerCase() !== "invalid date" &&
            se.start.length > 0 &&
            se.end.length > 0
          ) {
            this.start = se.start;
            this.end = se.end;
            b = true;
          }
          break;
        default:
          b = false;
          break;
      }

      var c = false;

      if (params.TopicID.length == 10 || params.TopicID.length == 6) {
        var cutLength = params.TopicID.length;

        for (var i in this.oTopics) {
          if (this.oTopics[i].TopicID.substr(0, cutLength) == params.TopicID) {
            c = true;
            break;
          }
        }
      }
      return a && b && c;
    },

    async _validateFinish(tab, row) {
      let result = 0;
      _.forEach(this.labelFields, function (v) {
        var field = "zzzz_" + v.name;
        var value = row[field] || "";

        if (v.required == true) {
          if (value.length == 0) {
            result++;
          }
        }
      });

      if (result == 0) {
        row.state = 2;
      } else {
        row.state = 0;
      }
    },

    _getMaxLength(item) {
      if (item.controlType == "tb") {
        return item.hasOwnProperty("maxLength") ? item.maxLength : 255;
      } else {
        return 255;
      }
    },

    async _toggleFinish(tab, row, MentionID) {
      var params = this.getURLParams();
      var iquuid = this.readLocalStorage("iquuid2", params.dbName);
      this.$store.commit("setPageBack", params);
      if (iquuid == null) {
        this.gotoVerifyEmail();
      } else {
        let self = this;

        _.forEach(this.labelFields, function (v) {
          var field = "zzzz_" + v.name;
          var key = v.name;
          var value = "";
          if (v.controlType == "tb") {
            var text =
              self.data[tab].items[self.data[tab].items.indexOf(row)][field] ==
              undefined
                ? ""
                : self.data[tab].items[self.data[tab].items.indexOf(row)][
                    field
                  ];
            value = self.cleanText(text);
            self.data[tab].items[self.data[tab].items.indexOf(row)][field] =
              value.trim();
          } else {
            value =
              self.data[tab].items[self.data[tab].items.indexOf(row)][field];
          }
          self.labelValue[key] = value;
        });

        var result = await this.toggleFinish(
          row,
          MentionID,
          this.hexkey,
          this.hexiv,
          this.labelValue
        );

        if (result.result == 200) {
          row.Status = MentionID.toString();
          if (result.data[0]) row.UpdateTime = result.data[0].UpdateTime;

          await this._refresh();
        } else {
          setTimeout(() => {
            this.gotoVerifyEmail();
          }, 1500);
        }
      }
    },

    async _onPageChange(page) {
      this.page = page;
      this.currentTabIndex =
        this.currentTabIndex == null || this.currentTabIndex == undefined
          ? 0
          : this.currentTabIndex;

      this.currentPage[this.currentTabIndex] = page;
      await this._loadAsyncData();
    },

    _getTagColor(row) {
      switch (parseInt(row.Status, 10)) {
        case 1:
          return ["div-drop", "editted"];
        case 2:
          return ["div-drop", "finished"];
        case 0:
          return ["div-drop", "deleted"];
      }
    },

    _filterItems: function (presets) {
      const regex = new RegExp(this.filter.search.trim(), "ig");

      const regex_source = new RegExp(
        this.filter.source.join("|").trim(),
        "ig"
      );

      return presets.filter((preset) => {
        var r1 = true;
        var r2 = true;
        var r3 = true;
        var location = preset.Location || "";
        var sourceName = preset.SourceName || "";
        var displayTime = preset.DisplayTime || "";
        var headLine = preset.HeadLine || "";
        var hitsentence = preset.HitSentence || "";
        var story = preset.Story || "";
        var label = preset.LabelData || "";
        var prvalue = preset.PRValue.toString() || "";
        var datasource = preset.DataSource || "";
        var subtype = preset.SubType || "";
        r1 =
          location.match(regex) ||
          sourceName.match(regex) ||
          displayTime.match(regex) ||
          headLine.match(regex) ||
          hitsentence.match(regex) ||
          story.match(regex) ||
          label.match(regex) ||
          prvalue.match(regex);

        r2 = datasource.match(regex_source);
        r3 = subtype.match(regex_source);

        if (preset.DataSource == "voice") {
          return r1 && r2;
        } else {
          return r1 && r2 && r3;
        }
      });
    },

    _getStat() {
      this.$sumIncomplete.value = 0;
      for (var key in this.data["all"].items) {
        if (parseInt(this.data["all"].items[key].Status, 10) == 1) {
          this.$sumIncomplete.value++;
        }
      }
    },

    _refresh() {
      this.data["delete"].items = [];
      this.data["finish"].items = [];
      this.data["incomplete"].items = [];

      for (var i in this.data["all"].items) {
        switch (this.data["all"].items[i].Status) {
          case "1":
            this.data["incomplete"].items.push(this.data["all"].items[i]);
            break;
          case "2":
            this.data["finish"].items.push(this.data["all"].items[i]);
            break;
          case "0":
            this.data["delete"].items.push(this.data["all"].items[i]);
            break;
        }
      }

      this._getStat();
    },

    _onTabChange(index) {
      this.currentTabIndex = index;
    },

    _getTabColor(name) {
      return name.toLowerCase();
    },

    _getIcon(c, url, subtype) {
      return this.getIcon(c, url, subtype);
    },

    _getColor(c, url, subtype) {
      return this.getColor(c, url, subtype);
    },

    async _backWarning() {
      await this._gotoCleansingIndex();
    },

    async _gotoCleansingIndex() {
      this.params = this.getURLParams();
      var periodName = "monthly";
      var periodID = "latest";

      var p = [];

      if (
        periodName.toLowerCase() !== "monthly" &&
        periodName.toLowerCase() !== "custom" &&
        periodName.toLowerCase() !== "daily"
      ) {
        if (periodID.length > 0) {
          periodID = "";
        }
      }
      p =
        periodID.length > 0
          ? [
              "cleansingindex",
              this.params.dbName,
              this.params.TopicID,
              periodName,
              periodID,
            ]
          : [
              "cleansingindex",
              this.params.dbName,
              this.params.TopicID,
              periodName,
            ];

      p = "/" + p.join("/");

      this.goto(p);
    },

    async _loadAsyncData() {
      const params = this.getURLParams();
      params["isCleansing"] = 1;
      params["selectedDataSource"] = this.selectedDataSource;
      params["pages"] = JSON.stringify(this.pages);
      params["status"] = JSON.stringify(this.status);
      params["order_by"] = "DisplayTime " + this.sortOrder;
      params["start"] = this.start;
      params["end"] = this.end;
      params["p"] = this.page;
      params["ProfileID"] = this.oProfileID;

      await this.httpRequest("Dashboard/getDataCleansingV2", params)
        .then(() => {
          this.data = this.$result.data;

          if (this.data["all"]["items"]) {
            var countRequired = 0;
            _.forEach(this.labelFields, function (v) {
              if (v.required !== false) {
                countRequired++;
              }
            });

            _.forEach(this.data["all"]["items"], function (v) {
              if (v.LabelData !== null && v.LabelData !== undefined) {
                try {
                  var o = JSON.parse(v.LabelData);
                  if (!_.isEmpty(o)) {
                    v.state = 2;
                  } else {
                    if (countRequired == 0) {
                      v.state = 2;
                    } else {
                      v.state = 0;
                    }
                  }
                } catch (e) {
                  if (countRequired == 0) {
                    v.state = 2;
                  } else {
                    v.state = 0;
                  }
                }
              } else {
                if (countRequired == 0) {
                  v.state = 2;
                } else {
                  v.state = 0;
                }
              }
            });

            this._refresh();
          }
        })
        .catch((error) => {
          this.data = [];
          this.total = 0;

          console.log(error);
        });
    },

    async _getInitialData() {
      const params = this.getURLParams();

      params["isCleansing"] = 0;
      params["iquuid"] = this.readLocalStorage("iquuid2", params.dbName);
      params["logType"] = "cleansingv2";
      await this.httpRequest("Dashboard/getInitialData", params).then((res) => {
        if (
          this.$result.data.periods !== null &&
          this.$result.data.periods.data !== undefined &&
          this.$result.data.periods.data.length > 0
        ) {
          res = this.$result.data;
          this.oPeriod = res.periods;
          this.oTopics = res.topics.data[0].Query;

          this.selectedTopicName =
            this.oTopics[params.TopicID] !== undefined
              ? this.oTopics[params.TopicID].QueryName
              : "";
          this.oProfileID = res.profile.data[0].ProfileID;
          this.oProfile = res.profile;
        }
      });
    },

    _login() {
      this.gotoVerifyEmail();
    },

    async _logout() {
      const params = this.getURLParams();
      this.deleteLocalStorage("iquuid2", params.dbName);
      this.deleteLocalStorage("iqgoauth2");
      this.$store.commit("setPageBack", params);

      params["sumIncomplete"] = this.$sumIncomplete.value;
      var se = this.getStartEndInterval(
        params.periodName.toLowerCase(),
        params.periodID
      );
      params.start = se.start;
      params.end = se.end;
      await this.httpRequest("Dashboard/updateIncomplete", params).then(
        () => {}
      );

      this.writeLog("verifyemail", [
        {
          key: "originalURL",
          value: location.href,
        },
        { key: "remark", value: "logout" },
        { key: "email", value: this.email },
      ]);
      this.email = "";
      this.gotoVerifyEmail();
    },

    async _gotoSummaryPage() {
      this.params = this.getURLParams();
      this.params["sumIncomplete"] = this.$sumIncomplete.value;

      var se = this.getStartEndInterval(
        this.params.periodName.toLowerCase(),
        this.params.periodID
      );
      this.params.start = se.start;
      this.params.end = se.end;

      await this.httpRequest("Dashboard/updateIncomplete", this.params).then(
        () => {}
      );

      this.params = this.getURLParams();
      var item = {
        row: {
          DatabaseName: this.params.dbName,
          ProfileID: this.params.TopicID.toLowerCase().substr(0, 6),
        },
      };

      this.gotoSummary(item);
    },
  },
};
</script>
